export enum InterfaceVersions {
	Four = 4,
	Five = 5
}

export enum RunStatus {
	Open = 0,
	Closed = 1
}

// Reasons the measurement could return an error
export enum Codes {
	NoCCA = 0,
	ServerError = 1
}

export const enum SamplePlanType {
	Spc = 1,
	SampleAt100 = 2,
	SampleAt99 = 3,
	SampleAt97 = 4,
	SampleAt95 = 5,
	SampleAt92 = 6,
	SampleAt90 = 7,
	FirstAndLast = 20,
	FirstOnly = 21,
	SinglePartInEntry = 23,
	DipCritical = 24,
	DipNonliniear = 25,
	DipMajor = 26,
	DipMinor = 27,
	AS13002Major = 30,
	AS13002Minor = 31,
	AQL = 32,
	AQLModified = 33
}

export const enum MeasurementType {
	Variable,
	Attribute,
	NotReportable
}

export class UIConstants {
	public static readonly disabledSelector =
		".not-editable, .not-reportable, .disabled, .sampled, .sampled-out, .sampled-out-suggested, .sampled-out-disabled, .requires-serial, .requires-tool, .run-closed";
	public static nonMeasurementColumns = 7;
}

export const enum Directions {
	Left,
	Up,
	Right,
	Down
}

export const enum CpkBasis {
	Min = 0,
	CpL = 1,
	CpU = 2
}

export class MeasurementConstants {
	public static readonly Sigma1 = 1.33;
	public static readonly Sigma2 = 1.9;
	public static readonly Sigma3 = 2.5;
	public static readonly LARGE_LOT_SIZE_LIMIT = 5;
	public static readonly CPK_SIZE = 20;
	public static readonly pass = "Pass";
	public static readonly fail = "Fail";
}

export class FrequencyNames {
	public static readonly EveryPart = "(Every part)";
	public static readonly EverySecondPart = "(Every second part)";
	public static readonly EveryFourthPart = "(Every 4th part)";
	public static readonly EveryEighthPart = "(Every 8th part)";
	public static readonly EveryFiftheenthPart = "(Every 15th part)";
	public static readonly EveryTwentiethPart = "(Every 20th part)";
	public static readonly FirstPart = "(First part)";
	public static readonly LastPart = "(Last part)";
	public static readonly OnePartPerSession = "(1 Part Per Session)";
	public static NrandomParts(n: number): string {
		return `(${n} random parts)`;
	}
}
