import * as angular from "angular";
import { IChartSeries, IChartRequest, IChartDataPoint } from "@ni/interfaces/IChart";

export class Constants {
	public static NOT_SET = "(Not Set)";
}

//#region Enums
export const enum POFeedChartTypes {
	Month = 0,
	Program,
	Supplier,
	Customer,
	Completion,
	Platforms
}
//#endregion

export namespace POFeedChart {
	export class Component implements ng.IComponentOptions {
		public bindings: any;
		public controller: any;
		public template: string;
		constructor() {
			this.bindings = {
				// Chart output
				chart: "=?",

				// Inputs
				isSupplier: "<",
				category: "<",
				start: "<",
				end: "<",
				filters: "<",
				config: "<"
			};
			this.controller = Controller;
			this.template = `<am-chart id="chart" config="$ctrl.chartConfig" chart="$ctrl.chart"></am-chart>`;
		}
	}

	export class Controller implements ng.IController {
		// Track configs
		private chart: any;

		private readonly chartConfig: any;
		private readonly config: any; // Input config

		// Input
		private isSupplier: boolean;
		private category: number;
		private filters: any[];
		private start: Date;
		private end: Date;

		static $inject = [
			"$timeout",
			"$filter",
			"ColorService",
			"DateService",
			"FilterCriteriaParser",
			"FAIRequirementsChartsResource"
		];

		constructor(
			private readonly $timeout: ng.ITimeoutService,
			private readonly $filter,
			private readonly ColorService,
			private readonly DateService,
			private readonly FilterCriteriaParser,
			private readonly FAIRequirementsChartsResource
		) {
			if (typeof AmCharts !== "object") {
				throw new Error(
					"Please include AmCharts in this page in order to use a FAIR chart"
				);
			}

			//#region Chart config

			this.chartConfig = angular.merge(
				{
					title: "FAIR Requirement Charts",
					type: "serial",
					chartScrollbar: {
						autoGridCount: true,
						graph: "g1",
						scrollbarHeight: 40
					},
					graphBase: {
						fillAlphas: 1,
						lineAlpha: 0.2,
						labelText: "[[value]]",
						labelPosition: "middle",
						color: "#FFFFFF",
						type: "column",
						valueField: "y",
						id: "g1",
						balloonFunction: (e): string => {
							switch (Number(this.category)) {
								case POFeedChartTypes.Completion:
								case POFeedChartTypes.Month:
									let str = `<small>${moment(e.category, "MMM YYYY").format(
										"MMMM YYYY"
									)}</small><br/>
										<strong class='big'>${this.$filter("number")(e.values.value, 0)}</strong> FAIR Requirement${
										e.values.value !== 1 ? "s" : ""
									}`;

									if (Number(this.category) === POFeedChartTypes.Completion) {
										str += ` ${e.graph.valueField}`;
									}

									return str;
								default:
									return `<small>${e.category}</small>
								<br/><strong class='big'>${this.$filter("number")(e.values.value, 0)}</strong> FAIR Requirement${
										e.values.value !== 1 ? "s" : ""
									}`;
							}
						}
					},
					valueAxes: [
						{
							title: "FAIR Requirement Quantity",
							integersOnly: true,
							minimum: 0
						}
					],
					categoryAxis: {},
					legend: {
						position: "bottom"
					},
					listeners: [
						{
							event: "clickGraphItem",
							method: (event) => {
								let url = "/FirstArticles/POFeed/",
									start = moment(this.start),
									end = moment(this.end),
									filters = [];

								url += this.isSupplier ? "received" : "sent";

								const point = event.item.dataContext;

								switch (Number(this.category)) {
									case POFeedChartTypes.Completion:
									case POFeedChartTypes.Month:
										const date = moment(point.name, "MMM YYYY");
										start = moment(date.startOf("month"));
										end = moment(date.endOf("month"));

										if (Number(this.category) === POFeedChartTypes.Completion) {
											switch (event.graph.name) {
												case "Past Due":
													filters.push({
														field: "firstArticleDueDate",
														operator: "lte",
														value: new Date()
													});
													filters.push({
														field: "isFirstArticleSubmitted",
														operator: "isTrue",
														value: false
													});
													break;
												case "Done":
													filters.push({
														field: "isFirstArticleSubmitted",
														operator: "eq",
														value: true
													});
													break;
												case "Within 2 Months":
													filters.push({
														field: "firstArticleDueDate",
														operator: "lte",
														value: moment().add(2, "months").toDate()
													});
													filters.push({
														field: "firstArticleDueDate",
														operator: "gte",
														value: moment()
															.add(1, "days")
															.startOf("day")
													});
													filters.push({
														field: "isFirstArticleSubmitted",
														operator: "isTrue",
														value: false
													});
													break;
												case "Pending": // Essentially anything else
													filters.push({
														field: "firstArticleDueDate",
														operator: "gte",
														value: moment()
															.add(2, "months")
															.add(1, "days")
															.startOf("day")
															.toDate()
													});
													filters.push({
														field: "isFirstArticleSubmitted",
														operator: "isTrue",
														value: false
													});
													break;
											}
										}

										break;
									default:
										const map = {
											[POFeedChartTypes.Program]: "programName",
											[POFeedChartTypes.Supplier]:
												"purchaseOrderItemSupplierName",
											[POFeedChartTypes.Customer]:
												"purchaseOrderItemCustomerName",
											[POFeedChartTypes.Platforms]: "platforms"
										};

										if (point.name === Constants.NOT_SET) {
											filters.push({
												field: map[this.category],
												value: "isTrue",
												operator: "isNull"
											});
										} else {
											filters.push({
												field: map[this.category],
												value: encodeURIComponent(point.name),
												operator: "eq"
											});
										}

										break;
								}

								const dateKey =
									Number(this.category) === POFeedChartTypes.Completion
										? "firstArticleDueDate"
										: "createdDateUtc";

								// Add in date filters
								filters = filters.concat([
									{
										field: dateKey,
										operator: "gte",
										value: start
									},
									{
										field: dateKey,
										operator: "lte",
										value: end
									}
								]);

								const ds = new kendo.data.DataSource({
									filter: { filters: filters }
								});

								url += `?${decodeURIComponent(
									FilterCriteriaParser.dataSourceToUrl(ds, true)
								)}`;

								console.warn(url);

								window.open(url, "_blank");
							}
						}
					]
				},
				this.config
			);

			//#endregion
		}

		public $onChanges(changesObj) {
			this.loadChart(this.getChartRequestObject(changesObj));
		}

		// Main loading function
		private loadChart(params = {}): void {
			// Track chart for performing actions directly on chart

			const method =
				Number(this.category) === POFeedChartTypes.Completion
					? "getCompletionStatusByMonth"
					: "get";

			// #region Convert known boolean filters (from the filter menu) from Kendo string filters to NIBooleanFilters

			// NOTE: for converting a kendo string filter to a net-inspect boolean filter
			// This is needed to prevent TypeError: operators[(operator || "eq").toLowerCase()] is not a function
			// since the kendo operators object does NOT contain operators for boolean filters.

			// Iterate through the known boolean filter keys
			for (let key of ["isProductSafety"]) {
				// If the request is defined and the request object contains the filter key
				if (params[`filter.${key}.EqualTo`]) {
					// Convert the request entry to a niBooleanFilter (i.e. operator === isTrue) and set the value from the string value passed in
					params[`filter.${key}.isTrue`] = params[`filter.${key}.EqualTo`];

					// Delete the kendo-generated string filter from the request object
					delete params[`filter.${key}.EqualTo`];
				}
			}

			// #endregion

			this.chartConfig.loading = this.FAIRequirementsChartsResource[method](
				params,
				(data) => {
					let seriesArr = [];

					// Remove any guides
					this.chartConfig.categoryAxis.guides = [];

					if (Number(this.category) === POFeedChartTypes.Completion) {
						// Process multi series data

						seriesArr = this.processMultiSeries(data);

						// Get plot band index
						let i = -1,
							currentDate = moment();
						for (let datum of seriesArr[0].data) {
							if (moment(datum.name, "MMM YYYY") >= currentDate) {
								break;
							} else {
								i++;
							}
						}

						this.chartConfig.categoryAxis.guides = [
							{
								fillColor: "#004780",
								fillAlpha: 0.075,
								category: seriesArr[0].data[i].name,
								toCategory: seriesArr[0].data[i].name,
								position: "top",
								label: "Current Month",
								inside: true,
								expand: true
							}
						];
					} else {
						// Process all other data

						// Process each series
						for (let series of data) {
							switch (Number(this.category)) {
								case POFeedChartTypes.Month:
									series.data = this.processTimeSeries(series.data);
									break;
								default:
									series.data = this.processCategorySeries(series.data);
									break;
							}

							seriesArr.push(series);
						}
					}

					// Not sure why this is needed, but AmCharts wants to wait
					this.$timeout(() => {
						this.chartConfig.series = seriesArr;

						// Set Axis bounds
						if (this.chart && this.chart.dataProvider) {
							this.$timeout(() => {
								if (this.isDateRequest(params)) {
									this.chartConfig.categoryAxis.min =
										this.chart.dataProvider.length - 20;
									this.chartConfig.categoryAxis.max = this.chart.dataProvider.length;
								} else {
									this.chartConfig.categoryAxis.min = 0;
									this.chartConfig.categoryAxis.max = 20;
								}
							});
						}
					});
				}
			).$promise;
		}

		//#region Utility Functions

		// Tell if is date request
		private isDateRequest(request): boolean {
			switch (Number(request.category)) {
				case POFeedChartTypes.Month:
				case POFeedChartTypes.Completion:
					return true;
			}
			return false;
		}

		// Clean time series data
		private processTimeSeries(data: IChartDataPoint[]): IChartDataPoint[] {
			for (let point of data) {
				const m = moment(point.name, "MM/YYYY");
				point.name = m.format("MMM YYYY");
			}
			return data;
		}

		// Clean and sort category data
		private processCategorySeries(data: IChartDataPoint[]): IChartDataPoint[] {
			for (let point of data) {
				point.name = point.name || Constants.NOT_SET;
			}
			data.sort((a, b) => {
				return b.y - a.y;
			});
			return data;
		}

		private processMultiSeries(multiSeries: IChartSeries[]): IChartSeries[] {
			// Create a default series object to map into
			const seriesObj = {
				Done: {
					name: "Done",
					id: "done",
					fillColors: chroma(this.ColorService.sigmaColors[1]).brighten(0.2).hex()
				},
				"Past Due": {
					name: "Past Due",
					id: "past",
					fillColors: chroma(this.ColorService.sigmaColors[6]).brighten(0.25).hex()
				},
				Pending: {
					name: "Pending",
					id: "pending",
					fillColors: "#dddddd"
				},
				"Within 2 Months": {
					name: "Within 2 Months",
					id: "within",
					fillColors: chroma(this.ColorService.sigmaColors[4]).brighten(0.25).hex()
				}
			};

			// Defaults
			for (let key in seriesObj) {
				if (seriesObj.hasOwnProperty(key)) {
					seriesObj[key].data = [];
					seriesObj[key].legendColor = seriesObj[key].fillColors;
				}
			}

			// Flip series dimensions
			for (let month of multiSeries) {
				const monthName = moment(month.name, "MM/YYYY").format("MMM YYYY");

				// Ensure all data is thers
				const defaultObj = {
					Done: { name: monthName, y: 0 },
					"Past Due": { name: monthName, y: 0 },
					Pending: { name: monthName, y: 0 },
					"Within 2 Months": { name: monthName, y: 0 }
				};

				// Put existing data into map
				for (let data of month.data) {
					defaultObj[data.name] = data;
				}

				// Map from object to array
				angular.forEach(defaultObj, (value, key: string) => {
					value.name = monthName;
					//value.y = value.y || Math.ceil(Math.random() * 15); // ToDo: remove

					// Put into parent series object
					if (seriesObj[key]) {
						seriesObj[key].data.push(value);
					}
				});
			}

			// Map series object to array
			const seriesArr: IChartSeries[] = [
				seriesObj["Within 2 Months"],
				seriesObj["Past Due"],
				seriesObj.Pending,
				seriesObj.Done
			];
			seriesArr.reverse();
			return seriesArr;
		}

		// Get a chart request object to send to the API
		private getChartRequestObject(changesObj): any {
			let start: any = angular.copy(this.start),
				end = angular.copy(this.end);

			// Handle default dates if necessary
			if (this.category) {
				if (Number(this.category) === POFeedChartTypes.Completion) {
					const range = Number(this.end) - Number(this.start),
						average = moment(Number(this.start) + range / 2);

					start = angular.copy(average);
					end = angular.copy(average).add(range, "ms").toDate();
				} else if (
					changesObj.category &&
					Number(changesObj.category.previousValue) === POFeedChartTypes.Completion &&
					Number(changesObj.category.currentValue) !== POFeedChartTypes.Completion
				) {
					start = this.DateService.ranges.pastYear.start;
					end = this.DateService.ranges.pastYear.end;
				}
			}

			// Don't show any partial months for monthly charts
			if (
				Number(this.category) === POFeedChartTypes.Month ||
				Number(this.category) === POFeedChartTypes.Completion
			) {
				start = moment(start).startOf("month").toDate();
				end = moment(end).endOf("month").subtract(1, "seconds").toDate(); // Unix time rounds up - bad
			}

			let params = {
					"filter.createdDateUtc.greaterThan": (moment(start) as any).utcUnix(),
					"filter.createdDateUtc.lessThan": (moment(end) as any).utcUnix(),
					category: this.category,
					"filter.isSupplierRequest": !this.isSupplier
				},
				filters = this.FilterCriteriaParser.createFilter({
					filter: { filters: this.filters }
				});

			params = angular.merge(params, filters);

			return params;
		}

		//#endregion
	}
}
