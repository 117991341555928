import * as angular from "angular";

export class ImportMeasurementsController {
	static $inject = [
		"$scope",
		"$state",
		"$q",
		"$uibModal",
		"$translate",
		"InspectionPlansResource",
		"PartsResource",
		"RunsResource",
		"CMMResultsResource"
	];

	constructor(
		private readonly $scope,
		private readonly $state: ng.ui.IStateService,
		private readonly $q: ng.IQService,
		private readonly $uibModal: ng.ui.bootstrap.IModalService,
		private readonly $translate: ng.translate.ITranslateService,
		private readonly InspectionPlansResource,
		private readonly PartsResource,
		private readonly RunsResource,
		private readonly CMMResultsResource
	) {
		// Get a few things passed in via state
		$scope.model = $state.params.model || {
			partId: $state.params.partId
		};

		const getOutput = (): ng.IPromise<any> => {
			const deferred = $q.defer();

			if ($state.params.data) {
				deferred.resolve($state.params.data);
			} else if ($state.params.requestId && $state.params.partId) {
				$scope.cmmLoading = CMMResultsResource.resumeProduction(
					{
						partId: $state.params.partId,
						requestId: $state.params.requestId
					},
					(data) => {
						deferred.resolve(data);
					},
					(err) => {
						deferred.reject();
						alertErrors(err);
					}
				).$promise;
			} else {
				alertify.alert($translate.instant("Please_Pass_Request_Id_Alert"), () => {
					deferred.reject();
					$state.go("start");
				});
			}

			return deferred.promise;
		};

		getOutput().then((data) => {
			$scope.output = data;
			const dupes: any[] = data.files.filter((el) => el.isDuplicate);
			if (dupes && dupes.length) {
				alertify.notify(
					$translate.instant("N_Out_Of_N_Files_Duplicates_Alert", {
						v0: dupes.length,
						v1: data.files.length
					})
				);
			}

			// Get the Inspection Plan for the selected part
			InspectionPlansResource.get(
				{
					partId: $state.params.partId,
					"filter.partRevisions.equalTo": data.revisionName,
					"filter.isInTrash.isTrue": false,
					"filter.isInInspectionPlan.isTrue": true,
					"filter.page.limit": 9999
				},
				(d) => {
					$scope.input = d.report.data;
				}
			);
		});

		// Get the part (mostly just for partNumber)
		PartsResource.get({ id: $state.params.partId }, (data) => {
			$scope.model.partNumber = data.partNumber;
		});

		// Options for the CMM module
		$scope.options = {
			production: true,
			type: "parts",
			id: $state.params.partId,
			onCancel() {
				$state.go("start", { active: "import" });
			},
			onSave(data): ng.IPromise<any> {
				console.warn($scope.output);

				const deferred: ng.IDeferred<any> = $q.defer();

				$scope.modal = $uibModal.open({
					scope: $scope,
					templateUrl: "../../templates/complete.html"
				});

				$scope.modal.result.then(
					() => {
						const model = angular.merge(
							{
								operationNumber: "0",
								currentRunSize: 1
							},
							$scope.model
						);

						// Create measurements
						CMMResultsResource.saveProduction(
							{
								id: $state.params.partId
							},
							{
								...model,
								results: data
							},
							(d) => {
								alertify.alert(
									$translate.instant("Added_N_Measurements", {
										v0: d.data
									}),
									() => {
										$state.go("start");
									}
								);
								deferred.resolve();
							},
							(err) => {
								alertErrors(err);
								deferred.reject();
							}
						);
					},
					(err) => {
						deferred.reject();
					}
				);

				return deferred.promise;
			}
		};
	}
}
