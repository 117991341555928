import * as angular from "angular";

export const enum SpecificationTypes {
	Variable = 0,
	Attribute = 1,
	NotReportable = 2
}

export const enum ToleranceTypes {
	Symmetrical = 0,
	Asymmetrical = 1,
	UnilateralUpper = 2,
	LessThan = 3,
	UnilateralLower = 4,
	MoreThan = 5,
	BasicDimension = 6,
	RangeInclusive = 7,
	RangeUpperEqual = 8,
	RangeLowerEqual = 9,
	RangeExclusive = 10,
	AsymmetricalTestFeature = 11 // NOTE: Only for Acceptance Tests
}

export class Specification {
	static $inject = ["GDTService", "$filter"];

	constructor(private readonly GDTService, private readonly $filter) {}

	//#region Data

	public specificationTypes = {
		DIMENSION: {
			id: 0,
			name: "Dimension"
		},
		NOTE: {
			id: 2,
			name: "Attribute"
		},
		GDT: {
			id: 1,
			name: "GD&T"
		}
	};

	public measurementTypes = [
		{
			value: 0,
			label: "Variable"
		},
		{
			value: 1,
			label: "Attribute"
		},
		{
			value: 2,
			label: "Not Reportable"
		}
	];

	public getMeasurementTypes = (): any[] => {
		return this.measurementTypes;
	};

	public dimensionTypes = [
		{
			value: 15,
			label: "Basic"
		},
		{
			value: 2,
			label: "Diameter"
		},
		{
			value: 1,
			label: "Linear Dimension"
		},
		{
			value: 0,
			label: "Angle Dimension"
		},
		{
			value: 3,
			label: "Radius"
		},
		{
			value: 13,
			label: "Depth"
		},
		{
			value: 4,
			label: "Bend Radius"
		},
		{
			value: 5,
			label: "Edge Radius"
		},
		{
			value: 14,
			label: "Thickness"
		},
		{
			value: 6,
			label: "Chamfer Size"
		},
		{
			value: 7,
			label: "Chamfer Angle"
		},
		{
			value: 8,
			label: "Counter Bore Depth"
		},
		{
			value: 9,
			label: "Counter Bore Diameter"
		},
		{
			value: 10,
			label: "Counter Sink Angle"
		},
		{
			value: 11,
			label: "Counter Sink Depth"
		},
		{
			value: 12,
			label: "Counter Sink Diameter"
		},
		{
			value: 16,
			label: "GDT"
		},
		{
			value: 17,
			label: "Surface Finish"
		}
	];

	// NOTE: ASYMMETRICAL_TEST_FEATURE is for formatting Acceptance
	// Test Feature Specifications per NI5BETA-6189.
	public toleranceTypes = {
		SYMMETRICAL: {
			label: "Symmetrical",
			text: "Nominal +/- Tolerance",
			id: ToleranceTypes.Symmetrical
		},
		ASYMMETRICAL: {
			label: "Bilateral",
			text: "Nominal + High Tol / - Low Tol",
			id: ToleranceTypes.Asymmetrical
		},
		UNILATERAL_UPPER: {
			label: "Unilateral Upper",
			text: "<= Upper Spec Limit",
			id: ToleranceTypes.UnilateralUpper
		},
		LESS_THAN: {
			label: "Less Than",
			text: "< Upper Spec Limit",
			id: ToleranceTypes.LessThan
		},
		UNILATERAL_LOWER: {
			label: "Unilateral Lower",
			text: ">= Lower Spec Limit",
			id: ToleranceTypes.UnilateralLower
		},
		MORE_THAN: {
			label: "More Than",
			text: "> Lower Spec Limit",
			id: ToleranceTypes.MoreThan
		},
		BASIC_DIMENSION: {
			label: "Basic Dimension",
			text: "= Nominal",
			id: ToleranceTypes.BasicDimension
		},
		RANGE_INCLUSIVE: {
			label: "Range Inclusive",
			text: "Lower Spec - Upper Spec",
			id: ToleranceTypes.RangeInclusive
		}
		//RANGE_UPPER_EQUAL: {
		//	label: "Range Upper Equal",
		//	text: "Lower Spec - Upper Spec"
		//},
		//RANGE_LOWER_EQUAL: {
		//	label: "Range Lower Equal",
		//	text: "Lower Spec - Upper Spec"
		//},
		//RANGE_EXCLUSIVE: {
		//	label: "Range Exclusive",
		//	text: "Lower Spec - Upper Spec"
		//},
	};

	public getToleranceTypes = (): any => {
		return this.toleranceTypes;
	};

	public gdtFonts = {
		LEGACY: 0,
		IX: 1,
		NI: 2
	};

	public designators = [
		"Minor",
		"Major",
		"Critical",
		"Non-Linear",
		"Linear Dimension",
		"Radius",
		"Thickness",
		"Dimension",
		"Hole Location",
		"Hole Diameter",
		"True Position",
		"Distance",
		"Notes",
		"Profile",
		"Basic",
		"N/A"
	]; // Get this from an API

	public featureTypes = [
		"AngleDimension",
		"LinearDimension",
		"Diameter",
		"Radius",
		"BendRadius",
		"EdgeRadius",
		"ChamferSize",
		"ChamferAngle",
		"CounterBoreDepth",
		"CounterBoreDiamter",
		"CounterSinkAngle",
		"CounterSinkDepth",
		"CounterSinkDiameter",
		"Depth",
		"Thickness",
		"Basic",
		"GDT",
		"SurfaceFinish"
	];

	public units = ["in", "cm", "mm", "deg"];

	public toleranceTypeOptions = {
		dataTextField: "label",
		dataValueField: "id",
		valuePrimitive: true,
		dataSource: {
			data: Object.keys(this.toleranceTypes)
				.map((key) => {
					const obj = this.toleranceTypes[key];
					obj.id = String(obj.id);
					return obj;
				})
				.filter((el: any) => {
					return el.id !== "3" && el.id !== "5";
				})
		},
		template:
			"<div style='line-height:1.15;'><strong>#= data.label #</strong> <small>(#= data.text #)</small></div>"
	};

	//#endregion

	//#region Functions

	// Get the UMI for a feature
	public getUMI = (feature, result, umi?: number): number => {
		// If there's improper input, just return
		if (!feature || !feature.specification || result === null || result === undefined) {
			return null;
		}

		// Return the UMI directly if it's supplied
		if (typeof umi === "number") {
			return umi;
		}

		// Proceed to get the actual UMI if base conditions pass

		// Get the actual specification
		let specification = feature.specification.dimension;

		if (!specification) {
			return null;
		} else {
			specification = angular.copy(specification);
		}

		// Attribute
		if (specification.measurementType === SpecificationTypes.Attribute) {
			// Ensure that there is something there
			if (
				result.resultText ||
				result.result ||
				result.accepted === true ||
				result.accepted === false
			) {
				if (result.accepted === true) {
					result.umi = 0;
					return 0;
				} else if (result.accepted === false) {
					result.umi = 101;
					return 101;
				} else {
					return undefined; // return undefined if no result
				}
			} else {
				return undefined;
			}
		}

		// Add bonus tolerance
		if (result.additionalBonus) {
			specification.upperSpecLimit += Number(result.additionalBonus);
		}

		result = angular.isDefined(result.result) ? result.result : result;

		// Do the calculation
		if (!isNaN(parseFloat(result)) && isFinite(result)) {
			// Ensure tolerance is the right type
			specification.toleranceType = Number(specification.toleranceType) || 1;

			// Standardize the spec
			switch (Number(specification.toleranceType)) {
				// Unilateral Upper
				case ToleranceTypes.UnilateralUpper:
				case ToleranceTypes.LessThan:
					specification.nominal = 0;
					specification.lowTolerance = 0;
					specification.highTolerance = specification.upperSpecLimit;

					// Return either green or red for these, per P&W request
					if (result > specification.upperSpecLimit) {
						return 101;
					} else {
						return 0;
					}
				// Unilateral Lower
				case ToleranceTypes.UnilateralLower:
				case ToleranceTypes.MoreThan:
					specification.highTolerance = Infinity;
					specification.lowTolerance = 0;
					specification.nominal = specification.lowerSpecLimit;

					// Handle exclusive cases
					if (
						result === specification.nominal &&
						specification.toleranceType === ToleranceTypes.MoreThan
					) {
						return 101;
					}
					break;
				// Basic Dimension (don't calculate UMI)
				case ToleranceTypes.BasicDimension:
					return null;
				// Range
				case ToleranceTypes.RangeInclusive:
					specification.nominal = Math.divide(
						Math.add(specification.upperSpecLimit, specification.lowerSpecLimit),
						2
					);
					specification.highTolerance = Math.subtract(
						specification.upperSpecLimit,
						specification.nominal
					);
					specification.lowTolerance = Math.subtract(
						specification.lowerSpecLimit,
						specification.nominal
					);
					break;
			}

			const diff = Math.subtract(result, specification.nominal),
				targetTol =
					diff > 0
						? specification.highTolerance
						: Math.multiply(-1, specification.lowTolerance); // Find if this measurement is in respect to the upper or lower tolerance

			if (isNaN(diff) || isNaN(targetTol) || targetTol == null) {
				return undefined; // Return nothing if the difference cannot be calculated, or if the specification is incomplete
			} else if (targetTol !== 0) {
				const out = Math.multiply(Math.divide(diff, targetTol), 100);
				return out || 0;
			} else {
				return diff === 0 ? 0 : Infinity; // If no tolerance, return 0 or Infinity
			}
		}
	};

	// Set the type of a characterisitic with the proper key
	public getType = (characteristic): string => {
		let key = "numeric";
		if (characteristic.specification.specificationType === this.specificationTypes.GDT.id) {
			if (!this.GDTService.isPositional(characteristic)) {
				key = "indicator";
			} else {
				key = "positional";
			}
		} else if (
			characteristic.specification.specificationType === this.specificationTypes.NOTE.id
		) {
			key = "attribute";
		}
		characteristic.resultKey = key;
		return key;
	};

	// Render a specification as a string
	public render = (inputSpecification: Entities.ISpecification, opts: any = {}): string => {
		const type = inputSpecification && inputSpecification.type;
		const appendDescription = opts.includeDescription;
		const appendBasic = opts.appendBasic;
		const treatAsBilateral = opts.treatAsBilateral;

		let output = "";

		// Don't modify the original
		const specification: Entities.IDimension | any =
			angular.copy(inputSpecification.dimension) || {};
		const GDT: Entities.IGDT | any = inputSpecification.GDT || {};

		// Sanitized User Input
		const sanitizedUnits = this.sanitizeStringHelper(specification.units);
		const sanitizedDescription = this.sanitizeStringHelper(specification.description);
		const sanitizedGdtCallout = this.sanitizeStringHelper(GDT.callout);

		// Build the specification
		if (!type || type === "dimension") {
			// Allow this to work with specifications without the string fields
			specification.nominalEx = specification.nominalEx || specification.nominal;
			specification.highToleranceEx =
				specification.highToleranceEx || specification.highTolerance;
			specification.lowToleranceEx =
				specification.lowToleranceEx || specification.lowTolerance;
			specification.upperSpecLimitEx =
				specification.upperSpecLimitEx || specification.upperSpecLimit;
			specification.lowerSpecLimitEx =
				specification.lowerSpecLimitEx || specification.lowerSpecLimit;
			specification.basicValueEx = specification.basicValueEx || specification.basicValue;

			// NI5BETA-8528 Treat all tool features for the Calibrate Tools Page as Bilateral Tolerances
			// NOTE: The root cause of the issue is that `specification.toleranceType` is undefined
			// for the specifications provided on the Calibrate Tools page.

			// If the optional `treatAsBilateral` scope binding property is true, set the spec's tolerance type to 0
			if (treatAsBilateral && !specification.toleranceType) {
				specification.toleranceType = 0;
			}

			let descriptionContainsSpec = false;
			const descriptionWithoutSpaces = sanitizedDescription.replace(/\s+/g, "");

			// Build the specification based on type
			switch (Number(specification.toleranceType)) {
				case ToleranceTypes.Symmetrical:
					if (specification.nominal != null && specification.highTolerance != null) {
						// NI5BETA-8528 Treat all tool features for the Calibrate Tools Page as Bilateral Tolerances
						// If the optional `treatAsBilateral` scope binding property is true, the spec has a defined low tolerance,
						// and the abs value of the lowTolericane != the high tolerance, display the actual requirement string
						// as if the tolerance type was bilateral (i.e. x + x / x)
						if (
							treatAsBilateral &&
							specification.lowTolerance != null &&
							Math.abs(specification.lowTolerance) !== specification.highTolerance
						) {
							output += `${specification.nominalEx} + ${specification.highToleranceEx} / ${specification.lowToleranceEx}`;
							if (!descriptionContainsSpec) {
								descriptionContainsSpec =
									descriptionWithoutSpaces.indexOf(
										`(${specification.nominalEx}+${specification.highToleranceEx}/${specification.lowToleranceEx})`
									) > -1;
							}
						} else {
							// Otherwise, treat the spec as intended - i.e. format the actual requirement string following
							// the symetrical tolerance type (i.e. x +/- x )
							output += `${specification.nominalEx} +/- ${specification.highToleranceEx}`;
							if (!descriptionContainsSpec) {
								descriptionContainsSpec =
									descriptionWithoutSpaces.indexOf(
										`(${specification.nominalEx}+/-${specification.highToleranceEx})`
									) > -1;
							}
							if (!descriptionContainsSpec) {
								descriptionContainsSpec =
									descriptionWithoutSpaces.indexOf(
										`(${specification.nominalEx}±${specification.highToleranceEx})`
									) > -1;
							}
							if (!descriptionContainsSpec && specification.lowToleranceEx) {
								descriptionContainsSpec =
									descriptionWithoutSpaces.indexOf(
										`(${specification.nominalEx}+${specification.highToleranceEx},${specification.lowToleranceEx})`
									) > -1;
							}
							if (!descriptionContainsSpec && specification.lowToleranceEx) {
								descriptionContainsSpec =
									descriptionWithoutSpaces.indexOf(
										`(${specification.nominalEx}+${specification.highToleranceEx}/${specification.lowToleranceEx})`
									) > -1;
							}
						}
					}
					break;
				case ToleranceTypes.Asymmetrical:
					if (
						specification.nominal != null &&
						specification.highTolerance != null &&
						specification.lowTolerance != null
					) {
						output += `${specification.nominalEx} + ${specification.highToleranceEx} / ${specification.lowToleranceEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.nominalEx}+${specification.highToleranceEx}/${specification.lowToleranceEx})`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.AsymmetricalTestFeature: // ASYMMETRICAL TEST FEATURE TOLERANCE TYPE
					// NOTE: ASYMMETRICAL_TEST_FEATURE is for formatting Acceptance
					// Test Feature Specifications per NI5BETA-6189.
					if (specification.isTestFeatureSpecification) {
						if (
							specification.nominal != null &&
							specification.highTolerance != null &&
							specification.lowTolerance != null
						) {
							output += ` ${specification.nominalEx} ${specification.units} +${specification.highToleranceEx}, ${specification.lowToleranceEx}`;
						}
					}

					break;
				case ToleranceTypes.UnilateralUpper:
					if (specification.upperSpecLimit != null) {
						output += `<= ${specification.upperSpecLimitEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&lt;=${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&lt;${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(MAX${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.upperSpecLimitEx}MAX)`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.LessThan:
					if (specification.upperSpecLimit != null) {
						output += `< ${specification.upperSpecLimitEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&lt;=${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&lt;${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(MAX${specification.upperSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.upperSpecLimitEx}MAX)`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.UnilateralLower:
					if (specification.lowerSpecLimit != null) {
						output += `>= ${specification.lowerSpecLimitEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&gt;=${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&gt;${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(MIN${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.lowerSpecLimitEx}MIN)`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.MoreThan:
					if (specification.lowerSpecLimit != null) {
						output += `> ${specification.lowerSpecLimitEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&gt;=${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(&gt;${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(MIN${specification.lowerSpecLimitEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.lowerSpecLimitEx}MIN)`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.BasicDimension:
					if (specification.basicValue != null) {
						output += `=${specification.basicValueEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(=${specification.basicValueEx})`
								) > -1;
						}
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.basicValueEx})`
								) > -1;
						}
					}
					break;
				case ToleranceTypes.RangeInclusive:
				case ToleranceTypes.RangeUpperEqual:
				case ToleranceTypes.RangeLowerEqual:
				case ToleranceTypes.RangeExclusive:
					if (
						!(
							specification.lowerSpecLimit == null ||
							specification.upperSpecLimit == null
						)
					) {
						output += `${specification.lowerSpecLimitEx} - ${specification.upperSpecLimitEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.lowerSpecLimitEx}-${specification.upperSpecLimitEx})`
								) > -1;
						}
					}
					break;
				default:
					if (!(specification.nominal == null || specification.highTolerance == null)) {
						output = `${specification.nominalEx} +/- ${specification.highToleranceEx}`;
						if (!descriptionContainsSpec) {
							descriptionContainsSpec =
								descriptionWithoutSpaces.indexOf(
									`(${specification.nominalEx}+/-${specification.highToleranceEx})`
								) > -1;
						}
					}
					break;
			}

			// Style attributes in the same format as variables for Acceptance Test Features
			// NOTE: this overrides the output value.
			if (
				specification.isTestFeatureSpecification &&
				Number(specification.toleranceType) === ToleranceTypes.Asymmetrical
			) {
				if (
					specification.nominal != null &&
					specification.highTolerance != null &&
					specification.lowTolerance != null
				) {
					output = ` ${specification.nominalEx} ${sanitizedUnits} +${specification.highToleranceEx}, ${specification.lowToleranceEx}`;
				}
			}

			// Add parenthesis around the specification output if the
			// specification is NOT a Test Feature Specification
			if (output && !specification.isTestFeatureSpecification) {
				output = ` (${output})`;
			}

			// If units are provided and the specification is NOT a Test Feature Specification,
			// append the units to the output.
			if (specification.units && !specification.isTestFeatureSpecification) {
				output += ` <small>${sanitizedUnits}</small>`;
			}

			// Add in the description if requested or if the feature is an attribute
			if (appendDescription) {
				// If the description already includes the value of the specification safely append units if needed and return
				// otherwise append the specification to the description and return
				if (descriptionContainsSpec) {
					if (specification.units && !specification.isTestFeatureSpecification) {
						output = sanitizedDescription + ` <small>${sanitizedUnits}</small>`;
					} else {
						output = sanitizedDescription;
					}
				} else {
					output = sanitizedDescription + output;
				}
			}
		}

		// GDT Callout
		if (GDT.callout) {
			let font: string;
			switch (GDT.fontId) {
				case this.gdtFonts.LEGACY:
					font = "gdt-legacy";
					break;
				case this.gdtFonts.IX:
					font = "gdt-ix";
					break;
				default:
					// this.gdtFonts.GDT
					font = "gdt";
					break;
			}

			// Janky but fixes bug for now
			if (GDT.callout.startsWith("{")) {
				font = "gdt";
			}

			output += ` <span class='${font} text-large-15 text-nowrap'>${sanitizedGdtCallout}</span>`;
		} else if (GDT && GDT.segments && GDT.segments[0]) {
			output = `<span class='gdt text-large-15 text-nowrap'>${
				sanitizedGdtCallout || this.$filter("gdtToCallout")(GDT) || ""
			}</span> ${output}`;

			if (appendBasic) {
				try {
					const bd = GDT.segments[0].basicDimensions[0];
					output += ` <span class='gdt'>${bd.basicDimensionX}|${bd.basicDimensionY}</span>`;
				} catch (e) {
					//console.warn(e);
				}
			}
		}

		return output;
	};

	// Prevent HTML-Like Syntax: Replace angle brackets with corresponding character entities.
	private sanitizeStringHelper(input: string): string {
		const rawString = input || "";
		const intermediateString = rawString.replace(/</gi, "&lt;");
		const sanitizedString = intermediateString.replace(/>/gi, "&gt;");
		return sanitizedString;
	}

	//#endregion
}

// Service that contains all the global functions for processing feature specification, fai characteristic, or kpc specification
angular.module("netinspect.config").service("SpecificationService", Specification);
