export enum ETagTypes {
	Supplier,
	Internal,
	Customer,
	All
}

export enum ETagYAxisTypes {
	Count,
	AverageDispositionDays,
	RejectedQuantityCount,
	SumTotalCost
}

export enum ETagXAxisTypes {
	Day,
	Week,
	Month,
	Year,
	TagCause,
	DefectType,
	Inspector,
	Machine,
	Part,
	Workcenter,
	ETagType,
	Disposition,
	TagStatus,
	Mrb,
	CorrectiveAction,
	FeatureNumber,
	Operator
}
