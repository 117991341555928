import { RunStatus } from "../classes/Constants";
import { increment } from "@ni/enhancements/helpers/string";
import { IPart } from "@ni/common/interfaces/IPart";

export class NewController {
	static $inject = [
		"$scope",
		"$state",
		"$q",
		"$location",
		"$translate",
		"PartsResource",
		"MachinesResource",
		"CellsResource",
		"RunsResource",
		"InspectionPlanRunResource",
		"MeasureService",
		"AccountSettingsResource"
	];

	public requirements: any;
	public existing: any;
	public active = {};
	public app = {}; //so formController can bind properly
	public partRevisionPicker;
	public isAcceptanceTestRun: boolean;

	public model: any = {
		partNumber: "",
		autoincrement: false
	};

	public search = {};

	public options = [
		{
			name: this.$translate.instant("Start_A_New_Run_Job_Label"),
			id: "new",
			templateUrl: "../../templates/new.html"
		},
		{
			name: this.$translate.instant("Resume_Existing_Run_Job_Label"),
			id: "existing",
			templateUrl: "../../templates/existing.html"
		},
		{
			name: this.$translate.instant("Import_Measurements_Label"),
			id: "import",
			templateUrl: "../../templates/import.html"
		}
	];
	public baseSerialNumber: any;
	public startPromise: any;
	public requireSequentialOperationCompletion: any;
	public disableAutomaticSerialNumbering: boolean;

	private isStartingFromAnotherRun: boolean;

	constructor(
		private $scope: ng.IScope,
		private $state: ng.ui.IStateService,
		private $q: ng.IQService,
		private $location: ng.ILocationService,
		private $translate: ng.translate.ITranslateService,
		private PartsResource,
		private MachinesResource,
		private CellsResource,
		private RunsResource,
		private InspectionPlanRunResource,
		private MeasureService,
		private AccountSettingsResource
	) {
		this.init();
	}

	//#region Init

	private init(): void {
		const ctrl = this;
		ctrl.isStartingFromAnotherRun = this.$state.params.runId && this.$state.params.partId;
		// Get account preferences
		ctrl.AccountSettingsResource.get((data) => {
			ctrl.requireSequentialOperationCompletion =
				data.inspection.requireSequentialOperationCompletion;
			ctrl.disableAutomaticSerialNumbering = data.inspection.disableAutomaticSerialNumbering;

			// if run id is not provided, setup watchers and return
			if (!ctrl.isStartingFromAnotherRun) {
				ctrl.existing = false;
				ctrl.initWatchers();
				return;
			}

			// Get the absolute URL to determine if the existing run is for Acceptance Tests
			const currentUrl = ctrl.$state.href(this.$state.current.name, this.$state.params, {
				absolute: true
			});
			ctrl.isAcceptanceTestRun = currentUrl.toLowerCase().includes("acceptancetests");

			const initialPromise = ctrl.isAcceptanceTestRun
				? ctrl.RunsResource.getPartRun({
						id: ctrl.$state.params.partId,
						runId: ctrl.$state.params.runId
				  })
				: ctrl.PartsResource.getRuns({
						id: ctrl.$state.params.partId,
						runId: ctrl.$state.params.runId
				  });

			initialPromise.$promise.then((run: any) => {
				// if run does not need to be closed, setup watchers and resume
				ctrl.model.partId = run.partId;
				ctrl.model.currentRunSize = run.initialRunSize;
				ctrl.model.endingRunSize = ctrl.isAcceptanceTestRun
					? run.initialRunSize
					: run.endingRunSize; // Determine if closed
				ctrl.model.poNumber = run.poNumber;
				ctrl.model.runNumber = run.runNumber;
				ctrl.model.runId = run.id;
				ctrl.model.machineId = run.machineId;
				ctrl.model.operationNumber = run.operationNumber;
				ctrl.model.materialLotCertificationNumber = run.materialLotCertificationNumber;

				// Populate the ending date for the determineing whether the run is closed
				ctrl.model.endingDate = run.endingDate;

				// If resuming an Acceptance Test, set the procedure and issue
				if (ctrl.isAcceptanceTestRun) {
					ctrl.model.procedure = run.procedure;
					ctrl.model.issue = run.issue;
				}

				// Get machine data if a machineId is provided / greater than 0
				if (run.machineId && run.machineId > 0) {
					ctrl.MachinesResource.get({ id: run.machineId }, (machine) => {
						ctrl.model.machineName = machine.machineName;
					});
				}

				// Get the part associated with the run using the partId
				ctrl.PartsResource.get({ id: ctrl.$state.params.partId }, (part) => {
					ctrl.model.partNumber = part.partNumber;
					ctrl.model.part = part;

					ctrl.checkSerialization(part);

					if (ctrl.isAcceptanceTestRun) {
						ctrl.existing = run;
						ctrl.initWatchers();
						return;
					}

					// If the run is incomplete, Get run requirements and then initiate watchers
					if (!run.endingDate) {
						ctrl.RunsResource.getRequirements({ id: ctrl.existing.id }, (data) => {
							ctrl.requirements = data;
							ctrl.existing = run;
						});
					}
					// If the run has been completed,
					else {
						ctrl.RunsResource.get(
							{
								"filter.partNumber.equalTo": ctrl.model.partNumber,
								"filter.runNumber.equalTo": ctrl.model.runNumber,
								"page.limit": 9999
							},
							(runs) => {
								runs = runs.report.data;
								const incomplete = runs.find((el) => !el.endingDate);

								if (
									ctrl.requireSequentialOperationCompletion ||
									ctrl.isStartingFromAnotherRun
								) {
									// This is required to indicate to the model that there is no existing run any longer
									delete ctrl.model.runId;
									// Also clear it from the URL
									ctrl.$location.search("runId", null);

									if (incomplete) {
										ctrl.model.operationNumber = incomplete.operationNumber;
									} else {
										const mappedRuns = runs.map((el) => el.operationNumber);

										let allOperations = [];
										ctrl.operationOptions.dataSource.read().then(() => {
											allOperations = ctrl.operationOptions.dataSource
												.data()
												.filter(
													(el: any) =>
														el.value !== "0" &&
														mappedRuns.indexOf(el.value) < 0
												);

											let nextOperation = ctrl.model.operationNumber;

											if (allOperations[0]) {
												nextOperation = allOperations[0].value;
											} else {
												nextOperation = "0";
											}

											// Get requirements for the Operation
											ctrl.RunsResource.get(
												{
													"filter.partNumber.equalTo":
														ctrl.model.partNumber,
													"filter.runNumber.equalTo":
														ctrl.model.runNumber,
													"filter.operationNumber.equalTo":
														nextOperation || "",
													"filter.procedure.equalTo":
														ctrl.model.procedure || "",
													"filter.issue.equalTo": ctrl.model.issue || ""
												},
												(data) => {
													ctrl.requirements = false;
													if (data.report.data.length) {
														ctrl.existing = data.report.data[0];
														ctrl.RunsResource.getRequirements(
															{ id: ctrl.existing.id },
															(data) => {
																ctrl.requirements = data;
															}
														);

														if (
															!ctrl.MeasureService.checkVersion(
																ctrl.existing
															)
														) {
															ctrl.existing = false;
															ctrl.model.runNumber = null;
														}
													} else {
														// Tell the UI there is no existing run
														ctrl.existing = false;
													}
												}
											);

											ctrl.model.operationNumber = nextOperation;
										});
									}
								}
							}
						);
					}

					ctrl.initWatchers();
				});
			});
		});
	}

	/**
	 * Initialize watchers that ensure that existing runs cannot be duplicated
	 * @returns void
	 */
	private initWatchers(): void {
		var ctrl = this;
		// As these are changed, check if the combination aleady exists. If so, inform the UI that the run already exists
		ctrl.$scope.$watchCollection(
			() => {
				return {
					partNumber: ctrl.model.partNumber,
					runNumber: ctrl.model.runNumber,
					operationNumber: ctrl.model.operationNumber,
					procedure: ctrl.model.procedure,
					issue: ctrl.model.issue
				};
			},
			(newValues, oldValues) => {
				if (newValues === oldValues) {
					return;
				}
				//reset operation number if partNumber changed
				if (!ctrl.requireSequentialOperationCompletion) {
					newValues.partNumber !== oldValues.partNumber &&
						(ctrl.model.operationNumber = undefined);
				}

				// If Part Number and Run Number have been provided,
				// Get a list of all runs for all the Op Numbers
				if (newValues && newValues.partNumber && newValues.runNumber) {
					ctrl.startPromise = ctrl.RunsResource.get(
						{
							"filter.partNumber.equalTo": newValues.partNumber,
							"filter.runNumber.equalTo": newValues.runNumber,
							"page.limit": 9999
						},
						(runs) => {
							runs = runs.report.data;
							const incomplete = runs.find((el) => !el.endingDate);

							if (ctrl.requireSequentialOperationCompletion) {
								if (incomplete) {
									ctrl.model.operationNumber = incomplete.operationNumber;
								} else {
									const mappedRuns = runs.map((el) => el.operationNumber);
									let allOperations = [];
									ctrl.operationOptions.dataSource.read().then(() => {
										allOperations = ctrl.operationOptions.dataSource
											.data()
											.filter(
												(el: any) =>
													el.value !== "0" &&
													mappedRuns.indexOf(el.value) < 0
											);

										let nextOperation = ctrl.model.operationNumber;

										if (allOperations[0]) {
											nextOperation = allOperations[0].value;
										} else {
											nextOperation = "0";
										}

										// Get requirements for the Operation
										ctrl.getCurrentRequirement({
											partNumber: newValues.partNumber,
											runNumber: newValues.runNumber,
											operationNumber: ctrl.model.operationNumber,
											procedure: newValues.procedure,
											issue: newValues.issue
										});
										ctrl.model.operationNumber = nextOperation;
									});
									return;
								}
							}

							// If an Op Number is also provided,
							// Get the current requirements for the run as well as the run
							if (
								newValues.operationNumber ||
								(newValues.procedure && newValues.issue)
							) {
								ctrl.getCurrentRequirement({
									partNumber: newValues.partNumber,
									runNumber: newValues.runNumber,
									operationNumber: newValues.operationNumber,
									procedure: newValues.procedure,
									issue: newValues.issue
								});
							}
						}
					);
				}
			}
		);
	}

	/**
	 * Check if part is serialized and get info if so
	 * @param  {IPart} part
	 * @returns void
	 */
	public checkSerialization = (part: IPart): void => {
		if (part && part.isSerialized && !this.disableAutomaticSerialNumbering) {
			this.PartsResource.getMeasurements(
				{
					id: this.model.partId,
					"page.orderBy": "measurementDate",
					"page.orderByDirection": "desc",
					"page.limit": 1
				},
				(data) => {
					const d = data.report.data && data.report.data[0];
					if (d) {
						this.model.prevSerialNumber = d.serialNumber;
						this.model.lastSerialNumber = increment(d.serialNumber);
						this.baseSerialNumber = this.model.lastSerialNumber;
					}
				}
			);
		}
	};

	/**
	 Get requirements for the Operation
	 */
	private getCurrentRequirement(param: {
		partNumber: string;
		runNumber: string;
		operationNumber: string;
		procedure: string;
		issue: string;
	}): void {
		const ctrl = this;
		ctrl.RunsResource.get(
			{
				"filter.partNumber.equalTo": param.partNumber,
				"filter.runNumber.equalTo": param.runNumber,
				"filter.operationNumber.equalTo": param.operationNumber || "",
				"filter.procedure.equalTo": param.procedure || "",
				"filter.issue.equalTo": param.issue || ""
			},
			(data) => {
				ctrl.requirements = false;
				if (data.report.data.length) {
					ctrl.existing = data.report.data[0];
					if (!ctrl.isAcceptanceTestRun) {
						ctrl.RunsResource.getRequirements({ id: ctrl.existing.id }, (data) => {
							ctrl.requirements = data;
						});
					}

					if (!ctrl.MeasureService.checkVersion(ctrl.existing)) {
						ctrl.existing = false;
						ctrl.model.runNumber = null;
					}
				} else {
					// This is required to indicate to the model that there is no existing run any longer
					delete ctrl.model.runId;
					// Also clear it from the URL
					ctrl.$location.search("runId", null);
					// Tell the UI there is no existing run
					ctrl.existing = false;
				}
			}
		);
	}

	//#endregion

	//#region Dropdown Configs

	// Parts Selector
	public partsOptions = {
		dataValueField: "partNumber",
		change: (e) => {
			const dataItem = e.sender.dataItem(e.item);

			if (dataItem) {
				this.model.partId = dataItem.id;

				this.PartsResource.get({ id: dataItem.id }, (part) => {
					this.model.part = part;
					this.model.partId = part.id;
					this.model.partNumber = part.partNumber;

					// If serialized, get most recent serial number
					this.checkSerialization(part);

					// Get the operations for this part
					this.operationOptions.dataSource.read();
				});
			} else {
				e.sender.text("");
				e.sender.value(null);
				this.requirements = null;
			}
		}
	};

	// Revisions selector
	public revisionOptions = {
		dataBound: (e) => {
			this.partRevisionPicker = e.sender;
		}
	};

	// Operations selector
	public operationOptions = {
		autoBind: false,
		dataTextField: "label",
		dataValueField: "value",
		valuePrimitive: true,
		dataSource: new kendo.data.DataSource({
			transport: {
				read: (opts) => {
					if (this.model.partId) {
						this.PartsResource.getOperations(
							{
								id: this.model.partId
							},
							(operations) => {
								if (!Array.isArray(operations)) {
									operations = [];
								}
								// Sort alphanumerically
								operations.alphanumSort(true);
								// Turn into a usable object
								operations = operations.map((el) => ({ label: el, value: el }));
								// Add in the "All Features" operation
								operations.unshift({ label: "0 (All features)", value: "0" });
								opts.success(operations);
							},
							(err) => {
								opts.error(err);
							}
						);
					} else {
						opts.error();
					}
				}
			}
		})
	};

	// Cells selector
	public cellsOptions = {
		optionLabel: " ",
		dataTextField: "name",
		dataValueField: "id",
		//filter: "contains",
		valuePrimitive: true,
		dataSource: new kendo.data.DataSource({
			transport: {
				read: (opts) => {
					this.CellsResource.query(
						{
							"filter.page.limit": 9999
						},
						(data) => {
							data = data.filter((el) => el.numberOfMachines && !el.isInTrash);
							opts.success(data);
						}
					);
				}
			},
			sort: {
				field: "name",
				dir: "asc"
			}
		})
	};

	//#endregion

	//#region Start entry

	/**
	 * Start a new run or update / reopen existing run / op
	 * @param  {boolean} isValid
	 * @returns void
	 */
	public startNewRun = (isValid = true): void => {
		if (isValid) {
			this.reopenRunIfNecessary().then(() => {
				this.startRun().then(
					(run) => {
						// Populate the model with whatever is returned
						for (const key in run) {
							if (run.hasOwnProperty(key)) {
								this.model[key] = run[key];
							}
						}

						// Proceed
						this.proceed(run);
					},
					(err) => {
						alertErrors(err);
					}
				);
			});
		}
	};

	/**
	 * Reopen a run if necessary
	 * @returns promise
	 */
	protected reopenRunIfNecessary(): ng.IPromise<any> {
		const deferred = this.$q.defer();

		// If the test is a retest (Acceptance Test Features), do not attempt to re-open
		if (this.existing && this.existing.endingDate && !this.model.retest) {
			this.InspectionPlanRunResource.updateRunStatus(
				{ status: RunStatus.Open },
				{
					partId: this.existing.partId,
					runId: this.existing.id
				},
				() => {
					alertify.success(this.$translate.instant("Run_Reopened_Alert"));
					deferred.resolve();
				},
				() => {
					deferred.reject();
				}
			);
		} else {
			deferred.resolve();
		}

		this.startPromise = deferred.promise;

		return this.startPromise;
	}

	/**
	 * Issue the request to create the new run
	 * @returns promise containing the started run
	 */
	protected startRun(): ng.IPromise<any> {
		let method = this.existing ? "update" : "save";

		// Acceptance Tests Re-test logic: always start a new run if the re-test checkbox is checked.
		// Used in the template for helping determine which button was clicked

		if (this.model.retest) {
			// Save => POST
			method = "save";

			// Remove the existing partRunId
			this.existing.id = "";
			this.existing.runId = "";
			this.existing.retest = true;
		}

		// Remove base serial number if autoincrement not selected
		if (!this.model.autoincrement) {
			this.baseSerialNumber = null;
		}

		this.startPromise = this.RunsResource[method](
			{ id: this.existing.id },
			this.model
		).$promise;

		return this.startPromise;
	}

	/**
	 * Closes a run if necessary
	 * Navigates the user to the measurement entry screen
	 * @param  {any} run to proceed withg
	 * @returns void
	 */
	protected proceed(run: any): void {
		if (
			this.requirements &&
			this.requirements.willRequireRunCompletion &&
			this.model.currentRunSize === this.requirements.minimumAllowedLotSize
		) {
			if (this.existing) {
				// Close the run if it requires closure
				this.startPromise = this.InspectionPlanRunResource.updateRunStatus(
					{
						status: RunStatus.Closed
					},
					{
						partId: this.existing.partId,
						runId: this.existing.id
					},
					() => {
						alertify.success(this.$translate.instant("Run_Closed_Alert"));
						this.$state.go("measure", {
							id: run.id,
							partId: run.partId,
							baseSerialNumber: this.baseSerialNumber
						});
					},
					(err) => {
						alertErrors(err);
					}
				).$promise;
			} else {
				alertify.error(this.$translate.instant("No_Existing_Run_Alert"));
			}
		} else {
			// Otherwise just go
			this.$state.go("measure", {
				id: run.id,
				partId: run.partId,
				baseSerialNumber: this.baseSerialNumber
			});
		}
	}

	//#endregion
}

export class ResumeController {
	static $inject = ["$scope", "$state", "$translate", "FilterCriteriaParser", "RunsResource"];

	constructor(
		private readonly $scope,
		private readonly $state: ng.ui.IStateService,
		private readonly $translate: ng.translate.ITranslateService,
		private readonly FilterCriteriaParser,
		private readonly RunsResource
	) {
		$scope.search = {
			runSearchText: "",
			partSearchText: "",
			open: null
		};

		$scope.cards = [
			{ name: "all", text: "All_Label", color: "primary" },
			{
				name: "open",
				text: "Open_Label",
				color: "success",
				filters: [{ field: "isOpen", operator: "isTrue", value: true }]
			},
			{
				name: "closed",
				text: "Closed_Label",
				color: "muted",
				filters: [{ field: "isOpen", operator: "isTrue", value: false }]
			}
		];

		$scope.cardClick = function () {
			// use "function" to keep fn scope
			switch (this.card && this.card.name) {
				case "open":
					$scope.search.open = true;
					break;
				case "closed":
					$scope.search.open = false;
					break;
				default:
					$scope.search.open = null;
					break;
			}
		};

		// Get runs
		$scope.$watchCollection("search", (a, b) => {
			if (a !== b) {
				const filters = [
					{
						field: "runNumber",
						operator: "startsWith",
						value: $scope.search.runSearchText
					},
					{
						field: "partNumber",
						operator: "startsWith",
						value: $scope.search.partSearchText
					}
				];

				if ($scope.search.open != null) {
					filters.push({
						field: "isOpen",
						operator: "isTrue",
						value: $scope.search.open
					});
				}

				$scope.grid && $scope.grid.dataSource.filter(filters);
			}
		});

		$scope.dataSource = {
			transport: {
				read(options) {
					const params = FilterCriteriaParser.createFilter(options.data);
					RunsResource.get(
						params,
						(data) => {
							options.success(data.report);
						},
						(err) => {
							options.error();
						}
					);
				}
			},
			schema: {
				data: "data",
				total: "recordCount"
			},
			serverPaging: true,
			serverSorting: true,
			serverFiltering: true,
			pageSize: 10,
			sort: { field: "lastUpdatedDate", dir: "desc" },
			filter: {
				logic: "and",
				filters: [
					{
						field: "interfaceVersion",
						operator: "equalTo",
						value: 5
					}
				]
			}
		};

		$scope.gridOptions = {
			dataSource: $scope.dataSource,
			columns: [
				{ field: "partNumber", title: $translate.instant("Part_Number_Label") },
				{ field: "revisionName", title: $translate.instant("Rev_Label"), width: 80 },
				{
					field: "runNumber",
					title: $translate.instant("Run_Job_Number_Label"),
					template: `{{ dataItem.runNumber }}
					&nbsp;<span class='label label-warning' ng-show='dataItem.interfaceVersion === 4'>v4</span>`
				},
				{
					field: "operationNumber",
					title: $translate.instant("Op_Number_Label"),
					width: 80
				},
				{
					field: "initialRunSize",
					title: $translate.instant("Lot_Size_Label"),
					width: 100,
					sortable: false
				},
				{
					field: "machineName",
					title: $translate.instant("Machine_Label"),
					sortable: false
				},
				{
					field: "poNumber",
					title: $translate.instant("PO_Number_Label"),
					width: 150,
					sortable: false
				},
				{
					field: "materialLotCertificationNumber",
					title: $translate.instant("Manufacturing_Lot_Num_Label"),
					width: 150,
					sortable: false
				},
				{
					field: "initialDate",
					title: $translate.instant("Started_Label"),
					template: "{{ dataItem.initialDate | date: 'short' }}"
				},
				{
					field: "endingDate",
					title: $translate.instant("Ended_Label"),
					template: "{{ dataItem.endingDate | date: 'short' }}"
				},
				{
					field: "endingDate",
					title: $translate.instant("Run_Status_Label"),
					width: 100,
					template: `<span class="label" ng-class="{ 'label-success': !dataItem.endingDate, 'label-default': dataItem.endingDate }">
						{{ (dataItem.endingDate ? 'Closed_Label' : 'Open_Label') | translate }}
					</span>`
				}
			],
			filterable: false,
			sortable: true,
			selectable: true,
			dataBound(): void {
				$scope.grid = this;
			},
			pageable: {
				pageSizes: false
			},
			noRecords: {
				template: $translate.instant("No_Existing_Runs_Label")
			},
			change(e): void {
				// Resume an existing run
				const dataItem = this.dataItem(this.select());
				if (window.event && (window.event as any).ctrlKey) {
					window.open(
						$state.href("measure", { id: dataItem.id, partId: dataItem.partId }),
						"_blank"
					);
				} else {
					$state.go("measure", { id: dataItem.id, partId: dataItem.partId });
				}
			},
			excelExport: (e) => {
				try {
					const closed = this.$translate.instant("Closed_Label"),
						open = this.$translate.instant("Open_Label");

					const rows = e.workbook.sheets[0].rows;
					for (let i = 1; i < rows.length; i++) {
						const cell = rows[i].cells[rows[i].cells.length - 1];
						cell.value = cell.value ? closed : open;
					}
				} catch (e) {
					console.error(e);
				}
			},
			excel: {
				allPages: true,
				fileName: `NI_Runs_${new Date().getTime()}.xlsx`
			}
		};
	}
}

export class ImportController {
	static $inject = [
		"$scope",
		"$state",
		"$timeout",
		"$uibModal",
		"$translate",
		"CMMResultsResource",
		"CONSTS"
	];

	constructor(
		private readonly $scope,
		private readonly $state: ng.ui.IStateService,
		private readonly $timeout: ng.ITimeoutService,
		private readonly $uibModal: ng.ui.bootstrap.IModalService,
		private readonly $translate: ng.translate.ITranslateService,
		private readonly CMMResultsResource,
		private readonly CONSTS
	) {
		const TEN_MB = 10 * 1024 * 1024;

		$scope.output = {};

		$scope.model = {};

		$scope.uploaderOptions = {
			url: `${CONSTS.apiUrl}/quality/excel`,
			hideColumnHeaders: true,
			autoUpload: false,
			queueLimit: 1,
			ignoreState: true,
			filters: [
				{
					name: "sizeFilter",
					fn(item, options) {
						if (item.size > TEN_MB) {
							alertify.alert(
								$translate.instant("Quality_Import_File_Size_Too_Large_Alert", {
									v0: Math.ceil(item.size / TEN_MB),
									v1: "10MB"
								})
							);
							return false;
						}
						return true;
					}
				}
			],
			accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			onAfterAddingFile: () => {
				$scope.importErrors = [];
				$scope.uploadComplete = false;
			},
			onBeforeUploadItem: (fileItem) => {
				if ($scope.importErrors.length) {
					fileItem.cancel();
				}
				fileItem.formData.push({
					data: `{ importMeasurementsOnly: true }`
				});
			},
			onErrorItem: (fileItem, response) => {
				if (response) {
					$scope.importErrors = response.errors;
				}
			},
			onSuccessItem: (fileItem, response) => {
				$scope.uploadComplete = true;
			}
		};

		$scope.importPartNumberOptions = {
			select(e) {
				const dataItem = this.dataItem(e.item);
				$scope.part = dataItem;
				$scope.model.partId = null;
				$scope.model.partRevision = null;
				// Kind of hacky: recreate the uploader since it doesn't support dynamic URLs
				$timeout(() => {
					$scope.model.partId = dataItem.id;
				});
			}
		};

		// Go to the import page when upload is complete
		$scope.onComplete = (data: any): void => {
			const proceed = (): void => {
				this.$state.go("import", {
					partId: $scope.model.partId,
					partRevision: $scope.model.partRevision,
					model: $scope.model,
					data: data,
					requestId: data && data.id
				});
			};

			if ($scope.part && $scope.part.isSerialized) {
				$scope.data = data;

				$scope.modal = $uibModal.open({
					scope: $scope,
					templateUrl: "../../templates/serials.html",
					size: "sm"
				});

				$scope.submit = () => {
					$scope.submitPromise = this.CMMResultsResource.saveSerialNumbers(
						{
							partId: $scope.part.id,
							requestId: data.id
						},
						$scope.data.files,
						() => {
							$scope.modal.close();
							proceed();
						},
						(err) => {
							alertErrors(err);
						}
					).$promise;
				};
			} else {
				proceed();
			}
		};
	}
}

export class ResumeImportController {
	static $inject = [
		"$scope",
		"$state",
		"$translate",
		"FilterCriteriaParser",
		"GridService",
		"CMMHistoryResource"
	];

	constructor(
		private readonly $scope,
		private readonly $state,
		private readonly $translate,
		private readonly FilterCriteriaParser,
		private readonly GridService,
		private readonly CMMHistoryResource
	) {
		$scope.gridOptions = {
			height: function () {
				return GridService.getHeight(this);
			},
			selectable: true,
			filterable: { extra: false },
			sortable: { allowUnsort: false },
			pageable: GridService.pageable,
			columns: [
				{
					field: "partNumber",
					title: $translate.instant("Part_Number_Label"),
					template:
						"<a ui-sref='import({ partId: dataItem.partId, requestId: dataItem.requestId })'>{{ dataItem.partNumber }}</a>"
				},
				{
					field: "partRevision",
					title: $translate.instant("Part_Revision_Label"),
					width: 150
				},
				{
					field: "uploadedBy",
					title: $translate.instant("Creator_Label")
				},
				{
					field: "uploadedDate",
					title: $translate.instant("Date_Label"),
					template: "{{ dataItem.uploadedDate | date : 'short' }}",
					width: 150
				},
				{
					field: "filesUploaded",
					title: $translate.instant("Number_Documents_Label"),
					width: 75,
					filterable: false,
					sortable: false,
					template(dataItem) {
						const fileNames = dataItem.fileNames;
						if (fileNames) {
							return fileNames.split(",").length;
						} else {
							return 0;
						}
					}
				},
				{
					field: "fileNames",
					title: $translate.instant("Files_Label"),
					sortable: false
				},
				{
					field: "statusId",
					title: $translate.instant("Status_Label"),
					width: 120,
					template: `# if (statusId == 1) { # Open # } else if (statusId == 2) { # In Progress # } else { # Completed # } #`,
					filterable: {
						operators: { string: { eq: "Equal To" } },
						ui(e) {
							GridService.getDropdown(
								e,
								[
									{ label: "Open", value: 1 },
									{ label: "In Progress", value: 2 },
									{ label: "Completed", value: 3 }
								],
								"label",
								"value"
							);
						}
					}
				}
			],
			dataSource: {
				serverFiltering: true,
				serverSorting: true,
				serverPaging: true,
				pageSize: 5,
				transport: {
					read(opts) {
						const params = FilterCriteriaParser.createFilter(opts.data);
						CMMHistoryResource.get(
							params,
							(data) => opts.success(data.report),
							(err) => opts.error()
						);
					}
				},
				schema: {
					model: {
						fields: {
							uploadedDate: { type: "date" }
						}
					},
					data: "data",
					total: "recordCount"
				},
				sort: {
					field: "uploadedDate",
					dir: "desc"
				}
			},
			change(e) {
				const dataItem = this.dataItem(this.select());
				$state.go("import", {
					partId: dataItem.partId,
					requestId: dataItem.requestId,
					partRevision: dataItem.partRevision
				});
			}
		};
	}
}
