import * as angular from "angular";

export enum UserRequestStatus {
	Pending = 1,
	Approved,
	Disapproved
}

export enum UserRequestType {
	PartNumber = 1,
	FAIReset
}

export class FaiResetService {
	static $inject = ["$q", "$uibModal", "$translate", "FAIResource", "USER"];
	constructor(
		private readonly $q,
		private readonly $uibModal: angular.ui.bootstrap.IModalService,
		private readonly $translate,
		private readonly FAIResource,
		private readonly USER
	) {}

	modal: angular.ui.bootstrap.IModalServiceInstance;

	// Main method to handle resetting of the FAIR
	private reset(firstArticle: any, status: number = UserRequestStatus.Pending): ng.IPromise<any> {
		if (typeof firstArticle !== "object") {
			throw new Error("Resetting requires a first article");
		}

		const deferred = this.$q.defer();

		// If user has permission, proceed straight to approval
		if (status === UserRequestStatus.Pending && firstArticle.canReset) {
			status = UserRequestStatus.Approved;
		}

		// If it has already been cancelled
		if (status === UserRequestStatus.Disapproved && !firstArticle.pendingResetRequestDate) {
			deferred.reject();
			return deferred.promise;
		}

		// If no permissions
		if (!firstArticle.canReset) {
			status = UserRequestStatus.Pending;
		}

		const request = {
				id: firstArticle.id,
				emailAddress: this.USER.email,
				status: status,
				requestType: UserRequestType.FAIReset
			},
			FAIResource = this.FAIResource,
			modal = this.$uibModal.open({
				controller: [
					"$scope",
					($scope) => {
						$scope.firstArticle = firstArticle;
						$scope.request = request;
						$scope.status = status;
						$scope.modal = modal;
						$scope.submit = () => {
							let method = firstArticle.canReset ? "reset" : "resetRequest";

							if (status === UserRequestStatus.Disapproved) {
								method = "resetRequestDisapprove";
							}

							$scope.promise = FAIResource[method](
								request,
								() => {
									switch (method) {
										case "reset":
											alertify.success(
												this.$translate.instant("FAIR_Number_Reset", {
													v0: firstArticle.faiReportNumber
												})
											);
											break;
										case "resetRequest":
											alertify.success(
												this.$translate.instant(
													"FAIR_Number_Reset_Requested",
													{ v0: firstArticle.faiReportNumber }
												)
											);
											break;
										case "resetRequestDisapprove":
											alertify.success(
												this.$translate.instant(
													"FAIR_Number_Reset_Disapproved",
													{ v0: firstArticle.faiReportNumber }
												)
											);
											break;
									}

									modal.close();
								},
								(err) => {
									alertErrors(err);
								}
							).$promise;
						};
					}
				],
				template: `<form ng-submit="submit()" novalidate loading="promise">
			    <div class="modal-header">
				    <h3 class="modal-title" ng-switch="status">
					    <span ng-switch-when='${UserRequestStatus.Approved}' translate translate-value-v0='{{ firstArticle.faiReportNumber }}'>
                            Reset_FAIR_N_Label
                        </span>
					    <span ng-switch-when='${UserRequestStatus.Disapproved}' translate translate-value-v0='{{ firstArticle.faiReportNumber }}'>
                            Reset_FAIR_N_Disapprove_Label
                        </span>
					    <span ng-switch-default translate translate-value-v0='{{ firstArticle.faiReportNumber }}'>
                            Reset_FAIR_N_Request_Label
                        </span>
								
				    </h3>
			    </div>
			    <div class="modal-body">
				    <div class="alert alert-info" ng-show="firstArticle.customerName && status === ${UserRequestStatus.Pending}"
                        translate translate-value-v0='{{ firstArticle.customerName }}'>
					    Request_Will_Be_Sent_To_Label
				    </div>

				    <div class="form-group">
					    <label class="control-label required"  ng-switch="status">
						    <span ng-switch-when='${UserRequestStatus.Approved}' translate>Reset_Comments_Label</span>
						    <span ng-switch-when='${UserRequestStatus.Disapproved}' translate>Disapproval_Comments_Label</span>
						    <span ng-switch-default translate>Request_Comments_Label</span>
					    </label>
					    <textarea class="form-control" required rows="4" ng-model="request.comments" autofocus></textarea>
				    </div>
				    <div class="form-group" ng-if="!firstArticle.canReset">
					    <label class="control-label required" translate>Email_Address_Label</label>
					    <input type="email" class="form-control" placeholder="Email Address" ng-model="request.emailAddress" required />
				    </div>
			    </div>
			    <div class="modal-footer" ng-switch="status">
				    <button type="submit" class="btn btn-success" ng-switch-when='${UserRequestStatus.Approved}' translate>Reset_Label</button>
				    <button type="submit" class="btn btn-danger" ng-switch-when='${UserRequestStatus.Disapproved}' translate>Disapprove_Reset_Request_Label</button>
				    <button type="submit" class="btn btn-primary" ng-switch-default translate>Submit_Reset_Request_Label</button>
				    <button type="button" class="btn btn-default" ng-click="modal.dismiss()" translate>Cancel</button>
			    </div>
		    </form>`
			});

		modal.result.then(
			() => {
				deferred.resolve();
			},
			() => {
				deferred.reject();
			}
		);

		return deferred.promise;
	}
}

angular.module("netinspect.config").service("FAIResetService", FaiResetService);
