import { IFeature } from "../interfaces";
import { FrequencyNames, MeasurementConstants, SamplePlanType } from "./Constants";
import { Util } from "./Util";

interface IRange {
	getNumberToCount: Function;
	getFrequency: Function;
}

// Entity for providing required measurement frequency value and name.
// Example: Assume lot size 100 requires 100% testing,
// then measurement frequency equals 100 and frequency name equals "Every part."
export class Range implements IRange {
	private numberToCount: number;
	private frequency: string;

	constructor(
		private readonly start: number, // Mininum Sample Frequency
		private readonly end: number, // Maximum Sample Frequency
		private readonly endpoints: number[] // Lot Size Thresholds
	) {}

	// Get number of measurements required for lot size
	public getNumberToCount(lotSize: number): number {
		this.numberToCount = null;

		if (lotSize <= this.start) {
			this.numberToCount = lotSize;
		} else {
			for (let i = 0; i < this.endpoints.length; i++) {
				const current = this.endpoints[i],
					next = this.endpoints[i + 1];

				if (current && next) {
					if (lotSize >= current && lotSize <= next) {
						this.numberToCount = this.start + i;
						break;
					}
				}
			}

			if (this.numberToCount == null) {
				this.numberToCount = this.end;
			}
		}
		return this.numberToCount;
	}

	// Get string name of measurement frequency
	public getFrequency(numToCount: number): string {
		if (numToCount <= this.start) {
			this.frequency = FrequencyNames.EveryPart;
		} else {
			this.frequency = FrequencyNames.NrandomParts(numToCount);
		}
		return this.frequency;
	}
}

export class SpcOutput {
	public frequency: string;
	public measureCycle: number;
	public partsToMeasure: number;
	public everyNthPart: number;
	public lotSize: number;

	constructor(measureCycle: number, lotSize: number) {
		this.measureCycle = measureCycle;
		this.lotSize = lotSize;
	}

	// TODO: What is reduced inspection mode and its requirements? Unreachable usage.
	public setReducedInspection(everyNthPart: number, frequencyName: string): void {
		if (this.measureCycle === 0) {
			this.measureCycle = everyNthPart;
			this.partsToMeasure = Util.randomIntegerBetween(1, everyNthPart + 1);
		}

		this.everyNthPart = everyNthPart;
		this.frequency = frequencyName;
	}

	public setStandardSPC(everyNthPart: number, frequencyName: string, feature: IFeature): void {
		const isFeatureOOT = feature.isOutOfToleranceMeasurementExist;

		if (isFeatureOOT) {
			this.partsToMeasure = this.lotSize;
		} else {
			const lastMeasuredOrderNo = feature.lastMeasurement.orderNo;
			const partsForward = this.lotSize - lastMeasuredOrderNo;
			const totalRunMeasurements = feature.lastMeasurement.totalMeasurements;

			// Counting Logic Example: Consider everyNthPart = 2 for lotSize = 5
			// If no measurements taken, counting first part per interval: [1*, 2] [3*, 4] [5*]
			// If measurement taken at 1, counting last part per interval: [2, 3*] [4, 5*]
			const remainingNthParts =
				totalRunMeasurements === 0
					? Math.ceil(partsForward / everyNthPart) // Include partial interval.
					: Math.floor(partsForward / everyNthPart); // Exclude partial interval.

			this.partsToMeasure = totalRunMeasurements + remainingNthParts;
		}

		this.everyNthPart = everyNthPart;
		this.frequency = frequencyName;
	}
}

export class SamplePlan {
	public static applyStatisticalSampling(samplePlan: SamplePlanType, lotSize: number): Range {
		let endpoints: number[], range: Range;

		// Determine the range based on the sample plan
		switch (samplePlan) {
			case SamplePlanType.SampleAt100:
				range = new Range(lotSize, lotSize, []);
				break;
			case SamplePlanType.SampleAt99:
				endpoints = [
					51,
					102,
					106,
					111,
					116,
					123,
					130,
					140,
					155,
					200,
					214,
					233,
					270,
					323,
					403,
					502,
					633,
					917,
					1555,
					5000
				];
				range = new Range(50, 69, endpoints);
				break;
			case SamplePlanType.SampleAt97:
				endpoints = [18, 37, 68, 101, 183, 949];
				range = new Range(17, 23, endpoints);
				break;
			case SamplePlanType.SampleAt95:
				endpoints = [11, 22, 33, 80, 4371];
				range = new Range(10, 14, endpoints);
				break;
			case SamplePlanType.SampleAt92:
				endpoints = [7, 12, 32];
				range = new Range(6, 8, endpoints);
				break;
			case SamplePlanType.SampleAt90:
				endpoints = [6, 13, 206];
				range = new Range(5, 7, endpoints);
				break;
			case SamplePlanType.SinglePartInEntry:
				range = new Range(1, 1, [0, Infinity]);
				break;
		}

		return range;
	}

	public static applySpcOrInterRunSampling(
		feature: IFeature,
		incomingOOTMeasurement = false
	): SpcOutput {
		const cpk = feature.featureState.samplingCpK;
		const lotSize = feature.initialRunSize;

		// TODO: What is reduced inspection mode?
		let reducedLevel = 0; // Always 0. Change logic unreachable.
		const isReducedInspectionFeature = reducedLevel === 1; // Always false.

		// Track the output of the SPC sampling calculations
		const spcOutput = new SpcOutput(feature.featureState.measureCycle, lotSize);

		// OOT Measurement Rule: Apply for preexisting OOT or incoming OOT.
		if (feature.isOutOfToleranceMeasurementExist || incomingOOTMeasurement) {
			// If no preexisting OOT, ensure feature is OOT-flagged.
			feature.isOutOfToleranceMeasurementExist = true;
			spcOutput.setStandardSPC(1, FrequencyNames.EveryPart, feature);
		} else if (isReducedInspectionFeature) {
			// Reduced Inspection else-if-statement unreachable. Boolean always false.

			if (cpk < MeasurementConstants.Sigma1) {
				spcOutput.setStandardSPC(1, FrequencyNames.EveryPart, feature);
			} else if (cpk >= MeasurementConstants.Sigma1 && cpk < MeasurementConstants.Sigma2) {
				//#region ReducedLevel Switch Between 1.33 and 1.9

				switch (reducedLevel) {
					case 1:
						spcOutput.setReducedInspection(2, FrequencyNames.EverySecondPart);
						break;
					case 2:
						spcOutput.setReducedInspection(8, FrequencyNames.EveryEighthPart);
						break;
					default:
						reducedLevel = 1;
						spcOutput.setReducedInspection(2, FrequencyNames.EverySecondPart);
						break;
				}

				//#endregion
			} else if (cpk >= MeasurementConstants.Sigma2 && cpk < MeasurementConstants.Sigma3) {
				//#region ReducedLevel Switch Between 1.9 and 2.5
				switch (reducedLevel) {
					case 1:
						spcOutput.setReducedInspection(4, FrequencyNames.EveryFourthPart);
						break;
					case 2:
						spcOutput.setReducedInspection(15, FrequencyNames.EveryFiftheenthPart);
						break;
					default:
						spcOutput.setReducedInspection(4, FrequencyNames.EveryFourthPart);
						break;
				}

				//#endregion
			} else {
				//#region ReducedLevel Switch Cpk > 2.5
				switch (reducedLevel) {
					case 1:
						spcOutput.setReducedInspection(8, FrequencyNames.EveryEighthPart);
						break;
					case 2:
						spcOutput.setReducedInspection(20, FrequencyNames.EveryTwentiethPart);
						break;
					default:
						spcOutput.setReducedInspection(8, FrequencyNames.EveryEighthPart);
						break;
				}
				//#endregion
			}
		} else {
			if (
				cpk < MeasurementConstants.Sigma1 ||
				(cpk >= MeasurementConstants.Sigma1 && lotSize < 3)
			) {
				spcOutput.setStandardSPC(1, FrequencyNames.EveryPart, feature);
			} else if (cpk >= MeasurementConstants.Sigma1 && lotSize >= 3 && lotSize <= 8) {
				spcOutput.setStandardSPC(2, FrequencyNames.EverySecondPart, feature);
			} else if (
				cpk >= MeasurementConstants.Sigma1 &&
				cpk < MeasurementConstants.Sigma2 &&
				lotSize >= 9 &&
				lotSize <= 32
			) {
				spcOutput.setStandardSPC(2, FrequencyNames.EverySecondPart, feature);
			} else if (
				cpk >= MeasurementConstants.Sigma2 &&
				cpk < MeasurementConstants.Sigma3 &&
				lotSize >= 9 &&
				lotSize <= 32
			) {
				spcOutput.setStandardSPC(4, FrequencyNames.EveryFourthPart, feature);
			} else if (cpk >= MeasurementConstants.Sigma3 && lotSize >= 9 && lotSize <= 32) {
				spcOutput.setStandardSPC(8, FrequencyNames.EveryEighthPart, feature);
			} else if (
				cpk >= MeasurementConstants.Sigma1 &&
				cpk < MeasurementConstants.Sigma2 &&
				lotSize > 32
			) {
				spcOutput.setStandardSPC(8, FrequencyNames.EveryEighthPart, feature);
			} else if (
				cpk >= MeasurementConstants.Sigma2 &&
				cpk < MeasurementConstants.Sigma3 &&
				lotSize > 32
			) {
				spcOutput.setStandardSPC(15, FrequencyNames.EveryFiftheenthPart, feature);
			} else if (cpk >= MeasurementConstants.Sigma3 && lotSize > 32) {
				spcOutput.setStandardSPC(20, FrequencyNames.EveryTwentiethPart, feature);
			}
		}

		// Mutate feature after calculations
		feature.featureState.reducedInspectionLevel = reducedLevel;
		feature.featureState.measureCycle = spcOutput.measureCycle;
		feature.featureState.partsToMeasure = spcOutput.partsToMeasure;
		feature.featureState.frequency = spcOutput.frequency;

		return spcOutput;
	}

	// Accept a feature and mutate sampling frequency requirement / display name.
	public static applyAS13002Sampling(feature: IFeature, incomingOOTMeasurement = false): void {
		const { samplePlanType, samplingCpK } = feature.featureState;
		const lotSize = feature.initialRunSize;

		// OOT Measurement Rule: Apply for preexisting OOT or incoming OOT.
		if (feature.isOutOfToleranceMeasurementExist || incomingOOTMeasurement) {
			// If no preexisting OOT, ensure feature is OOT-flagged.
			feature.isOutOfToleranceMeasurementExist = true;

			// Mutate feature state to require 100% sampling frequency.
			feature.featureState.partsToMeasure = lotSize;
			feature.featureState.frequency = FrequencyNames.EveryPart;
			return;
		}

		// Primary Lookup Table
		// Uses CPK & Lot-Size Category ID pairing.
		let sampleFrequencyLookupTable;

		// Secondary Lookup Tables - CPK & Lot-Size Category ID Lookup Lists
		// Use ascending checks to leverage logical result of previous range comparisons.
		// For instance, if lotSize <= 10 is false, can assume lotSize > 10 is true moving forward, and so on.
		let cpkCategoryLookupTable;
		const lotSizeCategoryLookupTable = [
			{ inclusiveMax: 10, id: "0-10" },
			{ inclusiveMax: 20, id: "11-20" },
			{ inclusiveMax: 30, id: "21-30" },
			{ inclusiveMax: 45, id: "31-45" },
			{ inclusiveMax: 60, id: "46-60" },
			{ inclusiveMax: 90, id: "61-90" },
			{ inclusiveMax: 120, id: "91-120" },
			{ inclusiveMax: 150, id: "121-150" },
			{ inclusiveMax: 200, id: "151-200" },
			{ inclusiveMax: 250, id: "201-250" },
			{ inclusiveMax: 300, id: "251-300" },
			{ inclusiveMax: 500, id: "301-500" },
			{ inclusiveMax: 750, id: "501-750" },
			{ inclusiveMax: 1000, id: "751-1000" },
			{ inclusiveMax: 2000, id: "1001-2000" },
			{ inclusiveMax: lotSize, id: "2001-N" }
		];

		// AS13002 Major - Set Appropriate Lookup Tables
		if (samplePlanType === SamplePlanType.AS13002Major) {
			sampleFrequencyLookupTable = {
				"CPK < 1.33": {
					"0-10": lotSize,
					"11-20": lotSize,
					"21-30": lotSize,
					"31-45": lotSize,
					"46-60": lotSize,
					"61-90": lotSize,
					"91-120": lotSize,
					"121-150": lotSize,
					"151-200": lotSize,
					"201-250": lotSize,
					"251-300": lotSize,
					"301-500": lotSize,
					"501-750": lotSize,
					"751-1000": lotSize,
					"1001-2000": lotSize,
					"2001-N": lotSize
				},
				"CPK < 1.66": {
					"0-10": 5,
					"11-20": 10,
					"21-30": 10,
					"31-45": 11,
					"46-60": 12,
					"61-90": 18,
					"91-120": 24,
					"121-150": 30,
					"151-200": 40,
					"201-250": 50,
					"251-300": 50,
					"301-500": 50,
					"501-750": 50,
					"751-1000": 50,
					"1001-2000": 80,
					"2001-N": 90
				},
				"CPK < 2": {
					"0-10": 3,
					"11-20": 4,
					"21-30": 5,
					"31-45": 5,
					"46-60": 6,
					"61-90": 9,
					"91-120": 12,
					"121-150": 15,
					"151-200": 20,
					"201-250": 20,
					"251-300": 20,
					"301-500": 25,
					"501-750": 38,
					"751-1000": 40,
					"1001-2000": 50,
					"2001-N": 60
				},
				"CPK >= 2": {
					"0-10": 2,
					"11-20": 2,
					"21-30": 2,
					"31-45": 3,
					"46-60": 4,
					"61-90": 5,
					"91-120": 6,
					"121-150": 6,
					"151-200": 8,
					"201-250": 10,
					"251-300": 12,
					"301-500": 20,
					"501-750": 30,
					"751-1000": 40,
					"1001-2000": 50,
					"2001-N": 60
				}
			};

			cpkCategoryLookupTable = [
				{ nonInclusiveMax: 1.33, id: "CPK < 1.33" },
				{ nonInclusiveMax: 1.66, id: "CPK < 1.66" },
				{ nonInclusiveMax: 2, id: "CPK < 2" },
				{ inclusiveMin: 2, id: "CPK >= 2" }
			];
		}

		// AS13002 Minor - Set Appropriate Lookup Tables
		if (samplePlanType === SamplePlanType.AS13002Minor) {
			sampleFrequencyLookupTable = {
				"CPK < 1": {
					"0-10": lotSize,
					"11-20": lotSize,
					"21-30": lotSize,
					"31-45": lotSize,
					"46-60": lotSize,
					"61-90": lotSize,
					"91-120": lotSize,
					"121-150": lotSize,
					"151-200": lotSize,
					"201-250": lotSize,
					"251-300": lotSize,
					"301-500": lotSize,
					"501-750": lotSize,
					"751-1000": lotSize,
					"1001-2000": lotSize,
					"2001-N": lotSize
				},
				"CPK < 1.33": {
					"0-10": 5,
					"11-20": 10,
					"21-30": 15,
					"31-45": 15,
					"46-60": 20,
					"61-90": 30,
					"91-120": 30,
					"121-150": 30,
					"151-200": 40,
					"201-250": 40,
					"251-300": 40,
					"301-500": 50,
					"501-750": 50,
					"751-1000": 50,
					"1001-2000": 80,
					"2001-N": 90
				},
				"CPK < 1.66": {
					"0-10": 3,
					"11-20": 4,
					"21-30": 5,
					"31-45": 5,
					"46-60": 6,
					"61-90": 9,
					"91-120": 12,
					"121-150": 15,
					"151-200": 20,
					"201-250": 20,
					"251-300": 20,
					"301-500": 25,
					"501-750": 38,
					"751-1000": 40,
					"1001-2000": 50,
					"2001-N": 60
				},
				"CPK < 2": {
					"0-10": 2,
					"11-20": 2,
					"21-30": 2,
					"31-45": 3,
					"46-60": 4,
					"61-90": 5,
					"91-120": 6,
					"121-150": 6,
					"151-200": 8,
					"201-250": 10,
					"251-300": 12,
					"301-500": 20,
					"501-750": 30,
					"751-1000": 30,
					"1001-2000": 40,
					"2001-N": 50
				},
				"CPK >= 2": {
					"0-10": 2,
					"11-20": 2,
					"21-30": 2,
					"31-45": 3,
					"46-60": 4,
					"61-90": 5,
					"91-120": 6,
					"121-150": 6,
					"151-200": 8,
					"201-250": 10,
					"251-300": 12,
					"301-500": 20,
					"501-750": 30,
					"751-1000": 30,
					"1001-2000": 40,
					"2001-N": 50
				}
			};

			cpkCategoryLookupTable = [
				{ nonInclusiveMax: 1, id: "CPK < 1" },
				{ nonInclusiveMax: 1.33, id: "CPK < 1.33" },
				{ nonInclusiveMax: 1.66, id: "CPK < 1.66" },
				{ nonInclusiveMax: 2, id: "CPK < 2" },
				{ inclusiveMin: 2, id: "CPK >= 2" }
			];
		}

		// Lookup CPK & Lot-Size Category IDs
		let cpkCatId: string;
		let lotSizeCatId: string;

		for (const range of cpkCategoryLookupTable) {
			if (samplingCpK < range.nonInclusiveMax) {
				cpkCatId = range.id;
				break;
			}

			if (range.inclusiveMin && samplingCpK >= range.inclusiveMin) {
				cpkCatId = range.id;
				break;
			}
		}

		for (const range of lotSizeCategoryLookupTable) {
			if (lotSize <= range.inclusiveMax) {
				lotSizeCatId = range.id;
				break;
			}
		}

		// Lookup Sampling Plan Frequency Value with CPK & Lot-Size Category IDs
		if (sampleFrequencyLookupTable && cpkCatId && lotSizeCatId) {
			let sampleFrequency = sampleFrequencyLookupTable[cpkCatId][lotSizeCatId];

			// Max Sample Frequency: Cannot exceed lotSize.
			if (sampleFrequency > lotSize) {
				sampleFrequency = lotSize;
			}

			const isCheckAllParts = sampleFrequency === lotSize;
			const frequencyName = isCheckAllParts
				? FrequencyNames.EveryPart
				: FrequencyNames.NrandomParts(sampleFrequency);

			// Mutate Feature State
			feature.featureState.partsToMeasure = sampleFrequency;
			feature.featureState.frequency = frequencyName;
		}
	}

	// Apply AQL sampling to a feature by mutating sampling frequency requirement / display name.
	public static applyAqlSampling(feature: IFeature, incomingOOTMeasurement = false): void {
		const { samplePlanType, samplePlanIndex } = feature.featureState;
		const lotSize = feature.initialRunSize;

		// OOT Measurement Rule: Apply for preexisting OOT or incoming OOT.
		if (feature.isOutOfToleranceMeasurementExist || incomingOOTMeasurement) {
			// If no preexisting OOT, ensure feature is OOT-flagged.
			feature.isOutOfToleranceMeasurementExist = true;

			// Mutate feature state to require 100% sampling frequency.
			feature.featureState.partsToMeasure = lotSize;
			feature.featureState.frequency = FrequencyNames.EveryPart;
			return;
		}

		let sampleFrequencyLookupTable;

		// AQL Standard - Set Appropriate Lookup Table
		if (samplePlanType === SamplePlanType.AQL) {
			sampleFrequencyLookupTable = [
				{
					inclusiveLotSizeMax: 1,
					"0.010": 1,
					"0.015": 1,
					"0.025": 1,
					"0.040": 1,
					"0.065": 1,
					"0.100": 1,
					"0.150": 1,
					"0.250": 1,
					"0.400": 1,
					"0.650": 1,
					"1.000": 1,
					"1.500": 1,
					"2.500": 1,
					"4.000": 1,
					"6.500": 1,
					"10.000": 1
				},
				{
					inclusiveLotSizeMax: 8,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": lotSize,
					"0.150": lotSize,
					"0.250": lotSize,
					"0.400": lotSize,
					"0.650": lotSize,
					"1.000": lotSize,
					"1.500": lotSize,
					"2.500": 5,
					"4.000": 3,
					"6.500": 2,
					"10.000": 2
				},
				{
					inclusiveLotSizeMax: 15,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": lotSize,
					"0.150": lotSize,
					"0.250": lotSize,
					"0.400": lotSize,
					"0.650": lotSize,
					"1.000": 13,
					"1.500": 8,
					"2.500": 5,
					"4.000": 3,
					"6.500": 2,
					"10.000": 2
				},
				{
					inclusiveLotSizeMax: 25,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": lotSize,
					"0.150": lotSize,
					"0.250": lotSize,
					"0.400": lotSize,
					"0.650": 20,
					"1.000": 13,
					"1.500": 8,
					"2.500": 5,
					"4.000": 3,
					"6.500": 3,
					"10.000": 2
				},
				{
					inclusiveLotSizeMax: 50,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": lotSize,
					"0.150": lotSize,
					"0.250": lotSize,
					"0.400": 32,
					"0.650": 20,
					"1.000": 13,
					"1.500": 8,
					"2.500": 5,
					"4.000": 5,
					"6.500": 5,
					"10.000": 3
				},
				{
					inclusiveLotSizeMax: 90,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": lotSize,
					"0.150": 80,
					"0.250": 50,
					"0.400": 32,
					"0.650": 20,
					"1.000": 13,
					"1.500": 8,
					"2.500": 7,
					"4.000": 6,
					"6.500": 5,
					"10.000": 4
				},
				{
					inclusiveLotSizeMax: 150,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": lotSize,
					"0.100": 125,
					"0.150": 80,
					"0.250": 50,
					"0.400": 32,
					"0.650": 20,
					"1.000": 13,
					"1.500": 12,
					"2.500": 11,
					"4.000": 7,
					"6.500": 6,
					"10.000": 5
				},
				{
					inclusiveLotSizeMax: 280,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": lotSize,
					"0.065": 200,
					"0.100": 125,
					"0.150": 80,
					"0.250": 50,
					"0.400": 32,
					"0.650": 20,
					"1.000": 20,
					"1.500": 19,
					"2.500": 13,
					"4.000": 10,
					"6.500": 7,
					"10.000": 6
				},
				{
					inclusiveLotSizeMax: 500,
					"0.010": lotSize,
					"0.015": lotSize,
					"0.025": lotSize,
					"0.040": 315,
					"0.065": 200,
					"0.100": 125,
					"0.150": 80,
					"0.250": 50,
					"0.400": 48,
					"0.650": 47,
					"1.000": 29,
					"1.500": 21,
					"2.500": 16,
					"4.000": 11,
					"6.500": 9,
					"10.000": 7
				},
				{
					inclusiveLotSizeMax: 1200,
					"0.010": lotSize,
					"0.015": 800,
					"0.025": 500,
					"0.040": 315,
					"0.065": 200,
					"0.100": 125,
					"0.150": 80,
					"0.250": 75,
					"0.400": 73,
					"0.650": 47,
					"1.000": 34,
					"1.500": 27,
					"2.500": 19,
					"4.000": 15,
					"6.500": 11,
					"10.000": 8
				},
				{
					inclusiveLotSizeMax: 3200,
					"0.010": 1250,
					"0.015": 800,
					"0.025": 500,
					"0.040": 315,
					"0.065": 200,
					"0.100": 125,
					"0.150": 120,
					"0.250": 116,
					"0.400": 73,
					"0.650": 53,
					"1.000": 42,
					"1.500": 35,
					"2.500": 23,
					"4.000": 18,
					"6.500": 13,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 10000,
					"0.010": 1250,
					"0.015": 800,
					"0.025": 500,
					"0.040": 315,
					"0.065": 200,
					"0.100": 192,
					"0.150": 189,
					"0.250": 116,
					"0.400": 86,
					"0.650": 68,
					"1.000": 50,
					"1.500": 38,
					"2.500": 29,
					"4.000": 22,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 35000,
					"0.010": 1250,
					"0.015": 800,
					"0.025": 500,
					"0.040": 315,
					"0.065": 300,
					"0.100": 294,
					"0.150": 189,
					"0.250": 135,
					"0.400": 108,
					"0.650": 77,
					"1.000": 60,
					"1.500": 46,
					"2.500": 35,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 150000,
					"0.010": 1250,
					"0.015": 800,
					"0.025": 500,
					"0.040": 490,
					"0.065": 476,
					"0.100": 294,
					"0.150": 218,
					"0.250": 170,
					"0.400": 123,
					"0.650": 96,
					"1.000": 74,
					"1.500": 56,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 500000,
					"0.010": 1250,
					"0.015": 800,
					"0.025": 750,
					"0.040": 715,
					"0.065": 476,
					"0.100": 345,
					"0.150": 270,
					"0.250": 200,
					"0.400": 156,
					"0.650": 119,
					"1.000": 90,
					"1.500": 64,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: lotSize, // Lot Size > 500,000
					"0.010": 1250,
					"0.015": 1200,
					"0.025": 1112,
					"0.040": 715,
					"0.065": 556,
					"0.100": 435,
					"0.150": 303,
					"0.250": 244,
					"0.400": 189,
					"0.650": 143,
					"1.000": 102,
					"1.500": 64,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				}
			];
		}

		// AQL Modified - Set Appropriate Lookup Table
		if (samplePlanType === SamplePlanType.AQLModified) {
			sampleFrequencyLookupTable = [
				{
					inclusiveLotSizeMax: 1,
					"0.650": 1,
					"1.000": 1,
					"1.500": 1,
					"2.500": 1,
					"4.000": 1,
					"6.500": 1,
					"10.000": 1
				},
				{
					inclusiveLotSizeMax: 8,
					"0.650": lotSize,
					"1.000": lotSize,
					"1.500": lotSize,
					"2.500": 5,
					"4.000": 3,
					"6.500": 3,
					"10.000": 3
				},
				{
					inclusiveLotSizeMax: 15,
					"0.650": lotSize,
					"1.000": 13,
					"1.500": 8,
					"2.500": 5,
					"4.000": 3,
					"6.500": 3,
					"10.000": 3
				},
				{
					inclusiveLotSizeMax: 25,
					"0.650": 20,
					"1.000": 13,
					"1.500": 8,
					"2.500": 5,
					"4.000": 3,
					"6.500": 3,
					"10.000": 3
				},
				{
					inclusiveLotSizeMax: 50,
					"0.650": 20,
					"1.000": 13,
					"1.500": 8,
					"2.500": 7,
					"4.000": 7,
					"6.500": 5,
					"10.000": 3
				},
				{
					inclusiveLotSizeMax: 90,
					"0.650": 20,
					"1.000": 13,
					"1.500": 13,
					"2.500": 11,
					"4.000": 8,
					"6.500": 5,
					"10.000": 4
				},
				{
					inclusiveLotSizeMax: 150,
					"0.650": 20,
					"1.000": 19,
					"1.500": 19,
					"2.500": 11,
					"4.000": 9,
					"6.500": 6,
					"10.000": 5
				},
				{
					inclusiveLotSizeMax: 280,
					"0.650": 29,
					"1.000": 29,
					"1.500": 19,
					"2.500": 13,
					"4.000": 10,
					"6.500": 7,
					"10.000": 6
				},
				{
					inclusiveLotSizeMax: 500,
					"0.650": 47,
					"1.000": 29,
					"1.500": 21,
					"2.500": 16,
					"4.000": 11,
					"6.500": 9,
					"10.000": 7
				},
				{
					inclusiveLotSizeMax: 1200,
					"0.650": 47,
					"1.000": 34,
					"1.500": 27,
					"2.500": 19,
					"4.000": 15,
					"6.500": 11,
					"10.000": 8
				},
				{
					inclusiveLotSizeMax: 3200,
					"0.650": 53,
					"1.000": 42,
					"1.500": 35,
					"2.500": 23,
					"4.000": 18,
					"6.500": 13,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 10000,
					"0.650": 68,
					"1.000": 50,
					"1.500": 38,
					"2.500": 29,
					"4.000": 22,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 35000,
					"0.650": 77,
					"1.000": 60,
					"1.500": 46,
					"2.500": 35,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 150000,
					"0.650": 96,
					"1.000": 74,
					"1.500": 56,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: 500000,
					"0.650": 119,
					"1.000": 90,
					"1.500": 64,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				},
				{
					inclusiveLotSizeMax: lotSize, // Lot Size > 500,000
					"0.650": 143,
					"1.000": 102,
					"1.500": 64,
					"2.500": 40,
					"4.000": 29,
					"6.500": 15,
					"10.000": 9
				}
			];
		}

		// Lookup Sample Frequency
		// Default to all-parts (100% Check) in case no frequency is found
		// instead of allowing zero checks to pass inspection.
		let sampleFrequency = lotSize;

		if (samplePlanIndex && sampleFrequencyLookupTable) {
			for (const row of sampleFrequencyLookupTable) {
				if (lotSize <= row.inclusiveLotSizeMax) {
					sampleFrequency = row[samplePlanIndex];
					break;
				}
			}
		}

		// Max Sample Frequency: Cannot exceed lotSize.
		if (sampleFrequency > lotSize) {
			sampleFrequency = lotSize;
		}

		// Determine Frequency Name
		const isCheckAllParts = sampleFrequency === lotSize;
		const frequencyName = isCheckAllParts
			? FrequencyNames.EveryPart
			: FrequencyNames.NrandomParts(sampleFrequency);

		// Mutate Feature State
		feature.featureState.partsToMeasure = sampleFrequency;
		feature.featureState.frequency = frequencyName;
	}
}
