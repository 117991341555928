import { IMeasurement, IFeature } from "../interfaces";
import {
	SamplePlanType,
	Directions,
	MeasurementType,
	MeasurementConstants,
	CpkBasis,
	FrequencyNames
} from "./Constants";

export class Measurement implements IMeasurement {
	public readonly orderNo: number;

	constructor(orderNo: number) {
		this.orderNo = orderNo;
	}
}

export class Util {
	//#region Data helpers

	private static readonly defaultLastMeasurement = {
		orderNo: 0,
		totalMeasurements: 0
	};

	public static guardFeature = (feature: any) => {
		// Default lastMeasurement and lastInToleranceMeasurement
		feature.lastMeasurement = feature.lastMeasurement || Util.defaultLastMeasurement;
		feature.lastInToleranceMeasurement =
			feature.lastInToleranceMeasurement || Util.defaultLastMeasurement;
	};

	//#endregion

	//#region Sampling Utilities

	public static randomIntegerBetween(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min)) + min + 1;
	}

	// Generate a list of random part indexes
	public static randomPartIndexes(
		inclusiveLowerBound: number,
		exclusiveUpperBound: number,
		needed: number
	): number[] {
		const result: number[] = [];

		if (needed >= exclusiveUpperBound - inclusiveLowerBound + 1) {
			for (let i = inclusiveLowerBound + 1; i <= exclusiveUpperBound; i++) {
				result.push(i);
			}
			return result;
		}

		const check = {};

		for (let i = 0; i < needed; i++) {
			let curValue = Util.randomIntegerBetween(inclusiveLowerBound, exclusiveUpperBound);
			// Ensure no duplicates
			while (check[curValue]) {
				curValue = Util.randomIntegerBetween(inclusiveLowerBound, exclusiveUpperBound);
			}
			result.push(curValue);
			check[curValue] = true;
		}

		return result;
	}

	//#endregion

	//#region UI helpers

	// Disable parts to the right of the current one
	public static disable = (feature: IFeature): IFeature => {
		const parts = feature.parts,
			last: any = feature.lastMeasurement || {},
			lastGood: any = feature.lastInToleranceMeasurement || {},
			p = feature.featureState.samplePlanType;

		if (p === SamplePlanType.Spc && last.isOutOfTolerance && lastGood.orderNo < last.orderNo) {
			// OOT condition: measure all since last in tolerance
			for (let i = 0; i < feature.parts.length; i++) {
				const part = feature.parts[i];

				if (part && !part.id) {
					part.disabled = part.orderNo > last.orderNo + 1; // Re-enable next part to measure if not past end
				}
			}
		} else {
			// Default condition
			for (let i = 0; i < parts.length; i++) {
				const part = parts[i];

				if (part) {
					part.disabled = false;

					for (let j = i; j > 0; j--) {
						// Disable the part if there is a part to the left that has no id
						// And the part has no id

						const prevPart = parts[j - 1];

						if (
							i &&
							!part.id &&
							prevPart &&
							!prevPart.id &&
							prevPart.result == null &&
							prevPart.accepted == null &&
							lastGood.orderNo != null &&
							part.orderNo > lastGood.orderNo
						) {
							// Disable the part
							part.disabled = true;
							break;
						}
					}
				}
			}
		}

		return feature;
	};

	// Determine if a part is disabled
	public static isPartDisabled(feature: IFeature, part: IMeasurement): boolean {
		if (part.disabled) {
			return true;
		} else if (feature.measurementDeviceTracking !== 0 && !feature.toolId) {
			// Required Types:
			// RequiredAllMeasurements = 1,
			// RequireVariableFeatureMeasurements = 2,
			// RequireAttributeFeatureMeasurements = 3
			return true;
		} else if (part.id) {
			return true;
		} else if (feature.specification.dimension.isNotReportable) {
			return true;
		} else if (part == null) {
			return true;
		}

		return false;
	}

	// Get direction to move based on keyboard event
	public static getDirection(e: KeyboardEvent | any = {}): Directions {
		if (e.keyCode === 38 || (e.keyCode === 13 && e.shiftKey)) {
			return Directions.Up;
		} else if (e.keyCode === 40 || e.keyCode === 13) {
			return Directions.Down;
		} else if (e.keyCode === 37 || (e.keyCode === 9 && e.shiftKey)) {
			return Directions.Left;
		}

		// Default to moving to the right
		return Directions.Right;
	}

	public static enrich = (feature: IFeature): IFeature => {
		// Prevents the numerator / denominator increasing if a measurement is entered
		// in a cell that has been sampled.
		let numberOfMeasurementsTaken = 0,
			numberOfMeasurementsRequired = 0;
		for (const part of feature.parts) {
			if (part) {
				numberOfMeasurementsRequired++;
				if (part.id) {
					numberOfMeasurementsTaken++;
				}
			}
		}

		feature.numberOfMeasurementsTaken = numberOfMeasurementsTaken;
		feature.numberOfMeasurementsRequired = numberOfMeasurementsRequired;

		return feature;
	};

	//#endregion

	//#region CPK Calculations

	public static calculateCpk = (
		feature: IFeature,
		cpkSize = MeasurementConstants.CPK_SIZE
	): number => {
		// Alias
		const spec = feature.specification.dimension,
			arr = feature.samplingMeasurements,
			mapped = arr.map((el) => el.value),
			cpkBasis = feature.featureState.cpkBasis;

		// Only perform SPC calculations when the number of measurements returned
		// are the required number of measurements for SPC calculation
		if (arr.length < cpkSize) {
			return feature.featureState.samplingCpK;
		} else if (feature.specification.dimension.measurementType === MeasurementType.Attribute) {
			return null; // ToDo: calculate DPM here
		}

		// Normalize feature: account for missing upper/lower spec
		if (spec.upperSpecLimit == null) {
			spec.upperSpecLimit = spec.nominal + spec.highTolerance;
		}
		if (spec.lowerSpecLimit == null) {
			spec.lowerSpecLimit = spec.nominal + spec.lowTolerance;
		}

		const mean = Util.calculateMean(mapped),
			std = Util.calculateStandardDeviation(mapped);

		const cpl = Util.calculateCpl(mean, std, spec.lowerSpecLimit),
			cpu = Util.calculateCpu(mean, std, spec.upperSpecLimit);

		let cpk: number;

		if (cpkBasis === CpkBasis.CpL) {
			cpk = cpl;
		} else if (cpkBasis === CpkBasis.CpU) {
			cpk = cpu;
		} else {
			cpk = Math.min(cpl, cpu);
		}

		const round = (n: number) => {
			return Math.round(1000 * n) / 1000;
		};

		if (Math.abs(cpk - feature.featureState.samplingCpK) > 0.001) {
			// tslint:disable-next-line:no-console
			// console.info(
			// 	{
			// 		"Feature": `${String(feature.featureNumber)}. ${feature.featureDesc}`,
			// 		"CPK IS: ": round(cpk),
			// 		"SHOULD BE: ": feature.featureState.samplingCpK,
			// 		"DIFFERENCE: ": round(cpk - feature.featureState.samplingCpK),
			// 		"CPL: ": round(cpl),
			// 		"CPU: ": round(cpu),
			// 		"CPK Basis": cpkBasis
			// 	}
			// );
		}

		return Math.max(cpk, 0);
	};

	private static calculateCpl(mean: number, std: number, lowerSpecLimit: number): number {
		const zeroStandardDeviation = std === 0;
		const cpl = zeroStandardDeviation ? Infinity : (mean - lowerSpecLimit) / (3 * std);
		return cpl;
	}

	private static calculateCpu(mean: number, std: number, upperSpecLimit: number): number {
		const zeroStandardDeviation = std === 0;
		const cpu = zeroStandardDeviation ? Infinity : (upperSpecLimit - mean) / (3 * std);
		return cpu;
	}

	private static calculateCp(feature: IFeature): number {
		// Alias
		const spec = feature.specification.dimension,
			arr = feature.samplingMeasurements;

		const std = Util.calculateStandardDeviation(arr.map((el) => el.value));

		const cp = (spec.upperSpecLimit - spec.lowerSpecLimit) / (6 * std);

		return cp;
	}

	public static calculateMean(input: number[] = []): number {
		let total = 0;
		for (const n of input) {
			total += n;
		}
		return total / input.length;
	}

	public static calculateStandardDeviation(input: number[] = []): number {
		const avg = Util.calculateMean(input),
			sumArr = input.map((el) => Math.pow(el - avg, 2)),
			sum = sumArr.reduce((a, b) => {
				return a + b;
			}, 0),
			std = Math.sqrt(sum / (input.length - 1));

		return std;
	}

	//#endregion
}
