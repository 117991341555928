import * as angular from "angular";
import { Specification, ToleranceTypes } from "@ni/services/specification";
import {
	Codes,
	InterfaceVersions,
	MeasurementConstants,
	MeasurementType,
	RunStatus
} from "../classes/Constants";
import { Util } from "../classes/Util";
import { BulkUpdateModel, IFeature, IMeasurement, IServerResponse } from "../interfaces";
import { SamplePlanType } from "../classes/Constants";
import { ETagService } from "./etag";
import { SampleService } from "./sample";
import { SharedService } from "./shared";

export class MeasureService {
	public measurePromiseArray: angular.IPromise<any>[] = [];
	protected features: IFeature[];

	/**
	 * Track first occurrence of Close Run pop-up confirmation alert to prevent subsequent pop-ups.
	 */
	private attemptCloseRunFlag = {
		partId: "",
		runNumber: "",
		opNumber: "",
		isFlagged: false
	};

	static $inject = [
		"$q",
		"$state",
		"$translate",
		"ETagService",
		"SharedService",
		"SpecificationService",
		"SampleService",
		"MeasurementsResource",
		"InspectionPlanRunResource",
		"InspectionPlanHeaderInfoResource"
	];

	constructor(
		protected $q: ng.IQService,
		protected $state: ng.ui.IStateService,
		protected $translate: ng.translate.ITranslateService,
		protected ETagService: ETagService,
		protected SharedService: SharedService,
		protected SpecificationService: Specification,
		protected SampleService: SampleService,
		protected MeasurementsResource,
		protected InspectionPlanRunResource,
		protected InspectionPlanHeaderInfoResource
	) {}

	/**
	 * Get page measurements from server
	 * @returns promise containing normalized measurments
	 */
	public getMeasurements(): ng.IPromise<IServerResponse> {
		return this.InspectionPlanRunResource.get({
			partId: this.$state.params.partId,
			runId: this.$state.params.id,
			"page.limit": 9999,
			"page.orderBy": "featureNumber",
			partCount: this.SharedService.pagination.pageSize,
			partOffset:
				this.SharedService.pagination.pageSize *
				(this.SharedService.pagination.currentPage - 1)
		}).$promise;
	}

	/**
	 * Close a run if it needs closing
	 * @param  {IFeature[]} features
	 * @returns void
	 */
	public attemptCloseRun = (features: IFeature[]): void => {
		if (!this.isRunCompleted(features)) return;
		this.features = features;

		// Prevent subsequent pop-up confirmation alerts by checking for flagged occurrence.
		const isPreviouslyAlerted = this.isAttemptCloseRunEventFlagged();
		if (isPreviouslyAlerted) return;

		// Flag occurrence of pop-up confirmation alert.
		this.flagAttemptCloseRunEvent();

		// Attempt to close the run with confirmation
		this.openOrCloseRun(RunStatus.Closed, "This_Run_Is_Complete_Alert", features).then(() => {
			this.$state.go("start", {
				partId: this.$state.params.partId,
				runId: this.$state.params.id
			});
		});
	};

	/**
	 * Check if a run is completed
	 * @param  {IFeature[]} features
	 * @returns boolean
	 */
	public isRunCompleted = (features: IFeature[]): boolean => {
		// If run is complete or all features are complete, proceed
		for (const feature of features) {
			// Continue if feature is not reportable
			if (
				feature.specification &&
				feature.specification.dimension &&
				feature.specification.dimension.measurementType === MeasurementType.NotReportable
			) {
				continue;
			}

			if (
				feature.run.isComplete ||
				(feature.lastMeasurement &&
					feature.lastMeasurement.totalMeasurements < feature.featureState.partsToMeasure)
			) {
				return false; // Feature isn't complete, exit
			}
		}

		return true;
	};

	/**
	 * Updates the serial number for the column selected
	 * @param orderNumber The column selected by the user
	 * @param serialNumber The serial number to be updated
	 * @param lotNumber The lot number of the column selected
	 */
	public updateHeaderInfo = (
		orderNumber: number,
		serialNumber: string,
		lotNumber: string
	): ng.IPromise<any> => {
		const deferred = this.$q.defer();

		// Launch request to update the serial number
		this.InspectionPlanHeaderInfoResource.updateHeaderInfo(
			{
				partId: this.$state.params.partId,
				runId: this.$state.params.id
			},
			{ orderNumber: +orderNumber, serialNumber: serialNumber, lotNumber: lotNumber },
			(data) => {
				deferred.resolve(data);
			},
			(err) => {
				alertErrors(err);
				deferred.reject(err);
			}
		);
		return deferred.promise;
	};

	/**
	 * Open or close a run as needed
	 * @param  {} runState=RunStatus.Closed
	 * @param  {string} message
	 * @returns Promise containing results of operation
	 */
	public openOrCloseRun = (
		runState = RunStatus.Closed,
		message: string,
		features: IFeature[]
	): ng.IPromise<any> => {
		const deferred = this.$q.defer();
		//Is run complete, all data filled
		let isRunComplete = false;

		//Check to see if run is complete and pass isRunComplate to status
		if (this.features) {
			features = this.features;
		}
		isRunComplete = this.isRunCompleted(features);

		alertify.confirm(this.$translate.instant(message), () => {
			if (!isRunComplete && runState == RunStatus.Closed) {
				this.InspectionPlanRunResource.updateRunStatusClose(
					{
						status: runState
					},
					{
						partId: this.$state.params.partId,
						runId: this.$state.params.id
					},
					(data) => {
						deferred.resolve(data);
					},
					(err) => {
						alertErrors(err);
						deferred.reject(err);
					}
				);
			} else {
				this.InspectionPlanRunResource.updateRunStatus(
					{
						status: runState
					},
					{
						partId: this.$state.params.partId,
						runId: this.$state.params.id
					},
					(data) => {
						deferred.resolve(data);
					},
					(err) => {
						alertErrors(err);
						deferred.reject(err);
					}
				);
			}
		});

		return deferred.promise;
	};

	/**
	 * Check the version of the run and return if it's not editable
	 * @param  {any} run
	 * @returns true if editable, false if not
	 */
	public checkVersion = (run: any = {}): boolean => {
		if (Number(run.interfaceVersion) === InterfaceVersions.Four) {
			alertify.alert(this.$translate.instant("This_Run_Was_Started_Alert"));
			return false;
		}
		return true;
	};

	//#region Measurement utlities

	/**
	 * Process measurement and return it
	 * @param  {IFeature} feature
	 * @param  {IMeasurement} measurement
	 * @returns IMeasurement
	 */
	public processMeasurement(feature: IFeature, measurement: IMeasurement): IMeasurement {
		// Reset
		measurement.isOOT = false;
		measurement.isOOC = false;

		// Get the CPKSize and determine whether pre-control limits are turned on via company non-conformance settings
		const cpkSize: number =
				(this.SharedService.apiResponse && this.SharedService.apiResponse.cpkSize) ||
				MeasurementConstants.CPK_SIZE,
			preControlEnabled =
				this.SharedService &&
				this.SharedService.apiResponse &&
				this.SharedService.apiResponse.nonConformanceSettings &&
				this.SharedService.apiResponse.nonConformanceSettings.enablePreControlLimit;

		if (feature.specification.dimension.measurementType === 1) {
			// Attribute measurement
			// Handle OOT
			if (measurement.accepted === false || measurement.result === false) {
				measurement.isOOT = true;
			} else {
				// Reset if not OOC
				measurement.isOOT = false;
			}
		} else {
			// Dimensional measurement
			measurement.result = Number(measurement.result);
			measurement.umi = this.SpecificationService.getUMI(feature, measurement);

			// Handle OOT / OOC
			if (Math.abs(measurement.umi) > 100) {
				measurement.isOOT = true;
			} else {
				// Reset if not OOT
				measurement.isOOT = false;
			}

			if (feature.featureState.recalcMeasurementId) {
				// Reset if there is a recalc measurement available
				measurement.isOOC = false;
			} else {
				if (
					(feature.featureState.upperControlLimit &&
						measurement.result > feature.featureState.upperControlLimit) ||
					(feature.featureState.lowerControlLimit &&
						measurement.result < feature.featureState.lowerControlLimit)
				) {
					measurement.isOOC = true;
				} else if (feature.featureState.totalMeasurements < cpkSize && preControlEnabled) {
					// NOTE: This is the right logic for calculating the pre-control limits
					const preUCL =
							feature.specification.dimension.nominal +
							feature.specification.dimension.highTolerance * 0.5,
						preLCL =
							feature.specification.dimension.nominal +
							feature.specification.dimension.lowTolerance * 0.5;

					// NI5BETA-8406 UPDATE
					// We do not want to toggle pre-OOC notifications / displaying of the modal if
					// the feature's tolerance type is one of the following:
					// 1. Unilateral Upper
					// 2. Unilateral Lower
					// 3. Basic Dimension
					// 4. Range Inclusive
					const toleranceTypesToIgnore = [
						ToleranceTypes.UnilateralLower,
						ToleranceTypes.UnilateralUpper,
						ToleranceTypes.BasicDimension,
						ToleranceTypes.RangeInclusive
					];

					// If the feature's tolerance type is NOT one of the types specified above (to ignore)
					if (
						toleranceTypesToIgnore.indexOf(
							feature.specification.dimension.toleranceType
						) === -1
					) {
						// And the measurement result is less than the calculated pre-control lower limit OR
						// greater than the calculated pre-control upper limit
						if (measurement.result < preLCL || measurement.result > preUCL) {
							// Pre-control is enabled and the number of measured features is less than the CPK Size
							// enable OOC reporting for pre-control measurement results
							measurement.isOOC = true;
						}
					}
				} else {
					// Reset if not OOC
					measurement.isOOC = false;
				}
			}

			// ToDo: Add GD&T measurements
		}

		// Add tool to measurement
		// Reject if no tool supplied
		measurement.toolId = Number(feature.toolId);

		// Add edit comments
		measurement.comments = this.SharedService.editComment || "";

		return measurement;
	}

	/**
	 * Process out a feature and return it.
	 * Cache the original feature to allow for reversion if necessary.
	 * @param  {IFeature} feature
	 * @param  {IMeasurement} measurement
	 * @returns IFeature
	 */
	public processFeature(feature: IFeature, measurement: IMeasurement): IFeature {
		// Un-kendo
		if ((feature.samplingMeasurements as any) instanceof kendo.data.ObservableArray) {
			feature.samplingMeasurements = (feature.samplingMeasurements as any).toJSON();
		}

		// Undirty the feature
		feature.dirty = false;

		// Add / update sampling measurement
		// Set last measurement in parts array to measurement
		// ?? this is already done by passing around the object?

		if (measurement.id) {
			// Update sampling measurement in list
			const target = feature.samplingMeasurements.find(
				(el) => Number(el.id) === Number(measurement.id)
			);
			if (target) {
				target.value = Number(measurement.result);
			}
		} else {
			// Add this measurement to the sampling measurements list (ID will be added later (bad))
			// Don't do this if basis is static cpk (2)
			if (!(this.SharedService.run && this.SharedService.run.samplingBase === 2)) {
				feature.samplingMeasurements.unshift({
					value: Number(measurement.result),
					id: null
				});
			}

			// Update feature.lastMeasurement. Increment totalMeasurements by 1, order number = measurement.order
			feature.featureState.totalMeasurements++;
			feature.lastMeasurement.totalMeasurements++;
			feature.lastMeasurement.remainingMeasurements--;
			// Only increment the lastMeasurement orderNo if it actually increased (i.e. won't increase on OOT measurement)
			feature.lastMeasurement.orderNo = Math.max(
				feature.lastMeasurement.orderNo,
				measurement.orderNo
			);
			if (!measurement.isOOT) {
				feature.lastInToleranceMeasurement.orderNo = measurement.orderNo;
			} else {
				feature.lastMeasurement.isOutOfTolerance = true;
			}
		}

		const cpkSize: number =
			(this.SharedService.apiResponse && this.SharedService.apiResponse.cpkSize) ||
			MeasurementConstants.CPK_SIZE;

		feature.samplingMeasurements = feature.samplingMeasurements.slice(0, cpkSize);

		// Calculate CPK
		feature.featureState.samplingCpK = Util.calculateCpk(feature, cpkSize);
		feature.featureState.realCpK = feature.featureState.samplingCpK;
		// Likely the 20th measurement -> make static CpK = real CpK
		if (feature.featureState.staticCpK == null && feature.featureState.realCpK != null) {
			feature.featureState.staticCpK = feature.featureState.realCpK;
		}

		// Calculate other stats
		feature.featureState.cumulativeUMI =
			(feature.featureState.cumulativeUMI * (feature.featureState.totalMeasurements - 1) +
				measurement.umi) /
			feature.featureState.totalMeasurements;

		// Single-Part-In-Entry: Update Cache
		if (feature.featureState.samplePlanType === SamplePlanType.SinglePartInEntry) {
			this.SampleService.cacheSinglePartInEntryFeature(feature);
		}

		// Run sampling
		feature.parts = this.SampleService.configureFeatureAndCreateMeasurementEntries(
			feature,
			measurement.isOOT
		);

		feature = Util.disable(feature);

		return feature;
	}

	/**
	 * Measure a given part
	 * @param  {IFeature} feature
	 * @param  {IMeasurement} measurement
	 * @returns ng
	 */
	public measure = (feature: IFeature, measurement: IMeasurement): ng.IPromise<IMeasurement> => {
		const etagDeferred = this.$q.defer(),
			measurementPromise = this.$q.defer();

		let etagPromise: ng.IPromise<any> = etagDeferred.promise;

		// #region Reject Measurement If Tool Is Required Logic

		// Measurement Types:
		// Variable = 0,
		// Attribute = 1,
		// NotReportable = 2
		const featureMeasurementType =
			feature.specification &&
			feature.specification.dimension &&
			feature.specification.dimension.measurementType;

		// Required Types:
		// RequiredAllMeasurements = 1,
		// RequireVariableFeatureMeasurements = 2,
		// RequireAttributeFeatureMeasurements = 3

		// Array to store the applicable msmtdevice tracking values for rejecting measurements
		let applicableDeviceTrackingValues = [];

		switch (featureMeasurementType) {
			case MeasurementType.Variable: // 0
				// If the measurement type = 0 (Variable), only reject measurements for
				// `measurementDeviceTracking` values of: 1 and 2
				applicableDeviceTrackingValues = [1, 2];
				break;
			case MeasurementType.Attribute: // 1
				// If the measurement type = 1 (Attribute), only reject measurements for
				// `measurementDeviceTracking` values of: 1 and 3
				applicableDeviceTrackingValues = [1, 3];
				break;
			default:
				// 2: NotReportable
				// If the measurement type = 2 (NotReportable), leave as an empty array
				// as tooling is not applicable
				break;
		}

		// Reject if a measurement device (tool) is required but not supplied
		if (applicableDeviceTrackingValues.includes(feature.measurementDeviceTracking)) {
			if (!measurement.toolId) {
				measurementPromise.reject();
				alertify.error(this.$translate.instant("Measurement_Device_Is_Required_Alert"));

				return measurementPromise.promise;
			}
		}

		// #endregion

		// Push on the current promise to keep track of how many outstanding measurements are left
		this.measurePromiseArray.push(measurementPromise.promise);

		// Process eTag if necessary
		if (measurement.isOOT || measurement.isOOC) {
			etagPromise = this.ETagService.createEtag(feature, measurement);
		} else {
			etagDeferred.resolve(measurement);
		}

		etagPromise.then(
			(m) => {
				// Clean up ids
				const cleaned = angular.copy(m);
				if (Number(cleaned.id) < 1) {
					delete cleaned.id;
				}

				// Get add required property "order", derived from "orderNo"
				cleaned.order = cleaned.orderNo;

				const verb = cleaned.id ? "update" : "save";

				this.MeasurementsResource[verb](
					{
						featureId: feature.featureId,
						partId: this.$state.params.partId,
						runId: this.$state.params.id
					},
					cleaned,
					(data: IMeasurement) => {
						// Add the measurement id to the measurement
						measurement.id = data.id;

						// Add the id to the sampling list
						// Not a super clean solution, could have race condition issues
						const target = feature.samplingMeasurements.find((el) => {
							return !el.id && String(el.value) === String(data.result);
						});
						if (target) {
							target.id = Number(data.id);
						}

						// Reset recalc point if needed
						if (data.resetRecalcPoint) {
							feature.featureState.recalcMeasurementId = null;
						}

						// Remove the tool if it's not in service
						if (
							data &&
							data.toolStatus !== "In Service" &&
							data.toolStatus !== "InService"
						) {
							feature.toolId = null;
						}

						// Resolve
						// Remove the current measurment promise from the promise array
						this.measurePromiseArray = this.measurePromiseArray.filter(
							(prom) => prom !== measurementPromise.promise
						);

						delete (data as any).$promise;

						measurementPromise.resolve(data);
					},
					(err) => {
						// Alert errors
						if (err) {
							alertErrors(err);
						}

						// Reject
						// Remove the current measurment promise from the promise array
						this.measurePromiseArray = this.measurePromiseArray.filter(
							(prom) => prom !== measurementPromise.promise
						);
						measurementPromise.reject({ codes: Codes.ServerError });
					}
				);

				// For mocking purposes:
				// setTimeout(() => {
				// 	measurementPromise.resolve({
				// 		...cleaned,
				// 		...{
				// 			id: Math.random() * 100000
				// 		}
				// 	});
				// }, Math.random() * 2000);
			},
			() => {
				// If not edit, clear the measurement
				// Remove the current measurment promise from the promise array
				this.measurePromiseArray = this.measurePromiseArray.filter(
					(prom) => prom !== measurementPromise.promise
				);
				measurementPromise.reject({ code: Codes.NoCCA });
			}
		);

		return measurementPromise.promise;
	};

	/**
	 * Perform a bulk measurement entry
	 * @param  {BulkUpdateModel} bulkUpdateModel
	 * @returns Promise containing the results of the bulk entry call
	 */
	public measureBulk = (bulkUpdateModel: BulkUpdateModel): ng.IPromise<any> => {
		const etagDeferred = this.$q.defer(),
			measurementPromise = this.$q.defer(),
			feature = bulkUpdateModel.feature[0];

		let etagPromise: ng.IPromise<any> = etagDeferred.promise;

		// Process eTag if necessary
		if (bulkUpdateModel.isPassing) {
			etagDeferred.resolve();
		} else {
			bulkUpdateModel.measurement = { accepted: false, isOOT: true };
			etagPromise = this.ETagService.createEtag(feature, bulkUpdateModel.measurement);
		}

		etagPromise.then(
			(m: IMeasurement) => {
				// Add CCA data
				if (m) {
					bulkUpdateModel.causeCorrectiveAction = m.outOfToleranceDiscrepancy;
				}

				this.MeasurementsResource.saveBulk(
					{
						partId: this.$state.params.partId,
						runId: this.$state.params.id
					},
					{
						featureIds: bulkUpdateModel.feature.map((f) => f.featureId),
						causeCorrectiveAction: bulkUpdateModel.causeCorrectiveAction,
						isPassing: bulkUpdateModel.isPassing,
						quantity: bulkUpdateModel.quantity,
						toolId: feature.toolId
					},
					() => {
						measurementPromise.resolve();
					},
					(err: any) => {
						measurementPromise.reject(err);
					}
				);
			},
			() => {
				measurementPromise.reject();
			}
		);

		return measurementPromise.promise;
	};

	//#endregion

	// #region Attempt-Close-Run Flag Methods

	/**
	 * Checks if current flag is designated for current part-run.
	 * Resets flag if starting or opening a different part-run.
	 * @param {string} partId
	 * @param {string} runNumber
	 * @returns void
	 */
	public validateAttemptCloseRunFlag(partId: string, runNumber: string, opNumber: string): void {
		const {
			partId: cachedPartId,
			runNumber: cachedRunNumber,
			opNumber: cachedOpNumber
		} = this.attemptCloseRunFlag;

		const isStalePartId = cachedPartId !== partId;
		const isStaleRunNumber = cachedRunNumber !== runNumber;
		const isStaleOpNumber = cachedOpNumber !== opNumber;

		if (isStalePartId || isStaleRunNumber || isStaleOpNumber) {
			// Reset flag for current part-run.
			this.attemptCloseRunFlag = { partId, runNumber, opNumber, isFlagged: false };
		}
	}

	/**
	 * Flags occurrence of "Close Run" pop-up alert for the current part-run session.
	 * @returns void
	 */
	private flagAttemptCloseRunEvent(): void {
		this.attemptCloseRunFlag.isFlagged = true;
	}

	/**
	 * Checks for occurrence of "Close Run" pop-up alert for current part-run session.
	 * @returns boolean
	 */
	private isAttemptCloseRunEventFlagged(): boolean {
		return this.attemptCloseRunFlag.isFlagged;
	}

	// #endregion
}
