import * as angular from "angular";
import { IFeature, IMeasurement } from "../interfaces";
import { MeasureService } from "../services/measure";
import { Measurement, Util } from "../classes/Util";
import { UIConstants, MeasurementConstants, Directions } from "../classes/Constants";
import { SharedService } from "../services/shared";

// Load up a scoped template for the measurement editor
export class Editor implements ng.IDirective {
	restrict = "A";
	scope = {
		feature: "=",
		measurement: "=",
		index: "=",
		tracker: "=",
		gridDataSource: "="
	};
	template = require("../../templates/editorTemplate.html");
	controller = EditorController;
	controllerAs = "$ctrl";

	static factory(): ng.IDirectiveFactory {
		return () => new Editor();
	}
}

class EditorController {
	public feature: IFeature;
	public measurement: IMeasurement;
	public index: number;
	public tracker;
	public isRadio = false;
	public gridDataSource: kendo.data.DataSource;

	/**
	 * Promise used to keep track of the measurement progress
	 */
	private measurePromise: ng.IPromise<any>;

	/**
	 * A set used to keep track of cell indexes in which focusing has been
	 * attempted on
	 */
	private cellIndexesAttemptedToFocus = new Set();

	/**
	 * Used to keep track of the last event (keyboard)
	 */
	private lastEvent: KeyboardEvent;

	static $inject = [
		"$scope",
		"$element",
		"$rootScope",
		"$timeout",
		"$q",
		"MeasureService",
		"SharedService"
	];

	constructor(
		private readonly $scope,
		private readonly $element,
		private readonly $rootScope,
		private readonly $timeout: ng.ITimeoutService,
		private readonly $q: ng.IQService,
		private readonly MeasureService: MeasureService,
		public readonly SharedService: SharedService
	) {
		this.feature = $scope.feature;
		this.measurement = $scope.measurement;
		this.index = $scope.index;
		this.tracker = $scope.tracker;
		this.isRadio =
			this.feature &&
			this.feature.specification &&
			this.feature.specification.dimension &&
			this.feature.specification.dimension.measurementType !== 0;
		this.gridDataSource = $scope.gridDataSource;

		// TODO: Fix this
		$scope.$watch("gridDataSource", (a, b) => {
			if (a) {
				this.gridDataSource = a;
			}
		});

		// TODO: Fix this
		// Fix the linking to a measurement. Needed because some measurements are passed in as null and can't be passed by reference
		$scope.$watch("measurement", (a, b) => {
			if (a) {
				this.measurement = a;
			}
		});
	}

	// Perform the measurement
	private measure(): void {
		if (this.feature.dirty && this.measurement && this.measurement.resultText != null) {
			const $cell = this.$element;

			kendo.ui.progress($cell, true);

			// Add in the serial number and lot number
			if (this.tracker) {
				this.measurement.serialNumber = this.tracker.serialNumber;

				// NI5BETA-6830 NOTE: Before, the measurement's materialLotCertificationNumber
				// was being set to the value of the tracker's lot number.
				this.measurement.materialLotCertificationNumber = this.tracker.materialLotCertificationNumber;
			}

			// Do some basic processing of the measurement
			this.measurement = this.MeasureService.processMeasurement(
				this.feature,
				this.measurement
			);

			// Cache the feature in the event of an error
			const cachedFeature = angular.copy(this.feature);

			// NI5BETA-7790 Allow entering measurements for skipped SPC measurements. Skipped measurements
			// are mising the orderNo value so we have to manually calculate it here, before posting the measurement.
			if (this.measurement && !this.measurement.orderNo) {
				const closestColumn = $cell.closest("td")[0],
					cellIndex = closestColumn && closestColumn.getAttribute("index"),
					colOffset = 1;

				this.measurement.orderNo = Number(cellIndex) + colOffset;
			}

			const cpkSize: number =
				(this.SharedService.apiResponse && this.SharedService.apiResponse.cpkSize) ||
				MeasurementConstants.CPK_SIZE;

			// Record the measurement
			this.measurePromise = this.MeasureService.measure(this.feature, this.measurement);

			this.measurePromise.then(
				(data) => {
					// Kendo in-cell loading indicator
					kendo.ui.progress($cell, false, true);

					// Add the point to the SPC chart without reloading
					(data as any).Id = data.id;

					// Add the measurement to the SPC chart
					this.$rootScope.spcChart &&
						this.$rootScope.spcChart.addPoint &&
						this.$rootScope.spcChart.addPoint(data);

					// Re-load the SPC chart to get data
					this.$rootScope.spcChart &&
						this.$rootScope.spcChart.reloadChart &&
						this.$rootScope.spcChart.reloadChart();

					// If the total measurements for a given feature is 1 or equal to the cpkSize, reload
					// the SPC chart - this handles the edge case of the chart not having data or showing the
					// wrong control limit lines
					if (
						this.feature &&
						this.feature.featureState &&
						(this.feature.featureState.totalMeasurements === 1 ||
							this.measurement.orderNo === 1 ||
							this.feature.featureState.totalMeasurements === cpkSize)
					) {
						// Re-load the SPC chart to get data
						this.$rootScope.spcChart &&
							this.$rootScope.spcChart.reloadChart &&
							this.$rootScope.spcChart.reloadChart();
					}

					// Close run if it's possible this measurement could have finished the run
					if (
						this.feature.lastMeasurement &&
						this.feature.lastMeasurement.totalMeasurements >=
							this.feature.featureState.partsToMeasure
					) {
						// ToDo: this is a bad implementation, relies on DOM positioning
						let featuresList = [];
						try {
							featuresList = this.gridDataSource.data().toJSON();
						} catch (e) {}
						this.MeasureService.attemptCloseRun(featuresList);
					}
				},
				(err) => {
					kendo.ui.progress($cell, false, false);

					// Update the feature to match the new (old) state
					for (const key in cachedFeature) {
						this.feature[key] = cachedFeature[key];
					}

					// If not edit, clear the measurement
					if (!this.measurement.id || String(this.measurement.id) === "-1") {
						// Handle if measurement is not saved due to API fail or no tag
						this.measurement = new Measurement(this.measurement.orderNo);
						this.feature.parts[this.index] = this.measurement;
					}
				}
			);

			// Update the feature to match the new state
			this.feature = this.MeasureService.processFeature(this.feature, this.measurement);

			// NI5BETA-8603 NOTE: This `$timeout` is crucial as it prevents the session user from
			// moving too quickly through the cells by providing a 100 millisecond cushion for
			// any CSS classes to be removed from the next open cell (i.e. the next cell to focus)
			// Focus the next item after redraw
			this.$timeout(() => {
				// Pretend like it has an id
				this.measurement.id = this.measurement.id || String(-1);

				// Only focus next if we haven't already selected one
				if (document.activeElement === document.body) {
					this.focusNext();
				}
			}, 100);
		}
	}

	//#region UI Handlers

	// Dirty on change
	private change(): void {
		// TODO: Only mark fields dirty if they cell has a resultText value and it is not an empty string
		this.feature.dirty = true;
	}

	// Select a row and column on selection
	private focus($event: KeyboardEvent): void {
		// ToDo: Make this less jQuery-dependent
		const $input: any = $($event.target),
			$td = $input.closest("td");

		const applicableUiConstants = UIConstants.disabledSelector.replace(
			" .sampled-out, .sampled-out-suggested, .sampled-out-disabled,",
			""
		);
		// Second line of defense against disabled measurements
		if ($td.is(applicableUiConstants)) {
			$event.preventDefault();
			$input.blur();
			// Go to the next available cell
			this.focusNext();
		} else {
			this.SharedService.x = $td.index() - UIConstants.nonMeasurementColumns;
			this.SharedService.activeFeatureEntry = this.measurement;
			$input.closest(".k-grid").data("kendoGrid").select($input.closest("tr"));
		}
	}

	private click() {
		this.SharedService.activeFeatureEntry = this.measurement;
	}

	// Measure automatically on blur
	private blur(): void {
		this.SharedService.activeFeatureEntry = null;
		this.measure();
	}

	// Perform logic on keydown
	private keydown($event: KeyboardEvent, isRadioInput?: boolean): void {
		// Navigate
		if ([9, 13, 37, 38, 39, 40].indexOf($event.keyCode) > -1) {
			const $input: any = $($event.target),
				$td = $input.closest("td");

			$event.preventDefault();
			this.lastEvent = $event;

			if (this.feature.dirty) {
				this.$element.find(":input").trigger("blur"); // Avoid double blurring
			} else {
				this.focusNext();
			}
		}
		if (isRadioInput && ($event.key === "p" || $event.key === "a" || $event.key === "1")) {
			this.measurement.accepted = true;
			this.changeAttribute();
		} else if (isRadioInput && ($event.key === "f" || $event.key === "2")) {
			this.measurement.accepted = false;
			this.changeAttribute();
		}
	}

	// If is radio button, measure on change
	private changeAttribute(): void {
		if (this.measurement) {
			if (this.measurement.accepted) {
				this.measurement.resultText = MeasurementConstants.pass;
			} else if (this.measurement.accepted === false) {
				this.measurement.resultText = MeasurementConstants.fail;
			}

			this.feature.dirty = true;
			this.measure();
		}
	}

	//#endregion

	/**
	 * Recursive helper function for determining the next cell to focus after pressing the down arrow
	 * @param  {JQuery} $allCells - array of all the cells avaible within the grid for the current page
	 * @param  {number} idx	- the current index of the focused cell
	 * @param  {number} offset - either positive (+) or negative (-) the value of the current pageSize for appropriate calculations
	 * @param  {boolean} isDown - flag to help reset the step once the end of the grid is reached
	 * @returns JQuery HTML Element representing the next cell
	 */
	private focusUpOrDownHelper(
		$allCells: JQuery,
		idx: number,
		offset: number,
		isDown = true
	): JQuery {
		// NOTE: the `idx` value is the X x Y value of the current cell
		// For example: If the current column is labeled 23, the current row is labeled 3,
		// the idx value will be -> 22

		// TODO: Refactor the variables below out of the method and have them be passed as parameters instead
		const initRunSize = this.feature && this.feature.initialRunSize, // the inital run size
			currentGridPage =
				this.SharedService.pagination && this.SharedService.pagination.currentPage, // the current page the session user is on
			currentPageSize =
				this.SharedService.pagination && this.SharedService.pagination.pageSize, // the number of columns displayed in the grid
			totalGridPages =
				this.SharedService.pagination &&
				Math.ceil(
					this.SharedService.pagination.totalItems /
						this.SharedService.pagination.pageSize
				), // the total number of grid pages for a given run
			runSizeRemainder = initRunSize % currentPageSize;

		// Remove the sampled-out and sample-out-suggested css classes from the disabled selector string
		const applicableUiConstants = UIConstants.disabledSelector.replace(
			" .sampled-out, .sampled-out-suggested,",
			""
		);

		// Calculate the step to use for retrieving the next cell to focus
		// Default step (current cell index + offest (ie. currentPageSize or -currentPageSize))
		let step = idx + offset;

		// If idx is greater than cell count, restart at the top feature
		if (isDown && $allCells && step > $allCells.length) {
			step = step % $allCells.length;
		}

		// NI5BETA-8603 To prevent 4,447 calls to this recursive function per keyboard `enter`, `down-arrow` or
		// `up-arrow` action, a set containing the already-attemped-to-focus cell indexes (represented as the step value)
		// is maintained. If the set contains the calculated step (i.e. the next cell to attempt to focus has already been attempted),
		// return `null` to prevent any further logic
		if (this.cellIndexesAttemptedToFocus.has(step)) {
			return null;
		}

		// Add the calculated step to the set to maintain a look-up for the next iteration of this function
		this.cellIndexesAttemptedToFocus.add(step);

		// Get the cell matching the step (index) from all an array containing all of the cells within the current page view
		const $tdInQuestion = $allCells.eq(step);

		// If the cell to focus contains any css classes that should NOT allow a session user to enter a value
		if ($tdInQuestion && $tdInQuestion[0] && $tdInQuestion.is(applicableUiConstants)) {
			// Calculate the next increment and call this fuction again
			let incr = isDown ? offset + currentPageSize : offset + -currentPageSize;

			if (runSizeRemainder > 0 && currentGridPage === totalGridPages) {
				incr = isDown ? offset + runSizeRemainder : offset + -runSizeRemainder;
			}

			// Wrap the recursive call in a try-catch block to handle any errors that may occur
			// during the process
			try {
				// Call this function, again, to attempt to focus the next row (feature's) cell
				// for the given column (part)
				return this.focusUpOrDownHelper($allCells, idx, incr, isDown);
			} catch (error) {
				// Return null to signify that no cells are available to focus
				return null;
			}
		} else {
			// Return the next cell to focus
			return $tdInQuestion;
		}
	}

	/**
	 * Helper function to add or remove the `sampled-out` css class to the current cell
	 * @param  {JQuery} $currTd - The current cell
	 * @returns void
	 */
	private addCellClasses($currTd: JQuery): void {
		// Spc = 1,
		// SampleAt100 = 2,
		// SampleAt99 = 3,
		// SampleAt97 = 4,
		// SampleAt95 = 5,
		// SampleAt92 = 6,
		// SampleAt90 = 7,
		// FirstAndLast = 20,
		// FirstOnly = 21,
		// FirstOnly = 21,
		// SinglePartInEntry = 23
		// Add cell classes to the applicable sample plan types represented by the aray of their enum vals
		if (
			this.feature &&
			this.feature.featureState &&
			this.feature.featureState.samplePlanType &&
			[1, 2, 3, 4, 5, 6, 7, 20, 21, 23].indexOf(this.feature.featureState.samplePlanType) !==
				-1
		) {
			// Add or remove the sampled-out class to the current cell
			if ($currTd) {
				if (!$currTd.hasClass("sampled-out")) {
					$currTd.addClass("sampled-out");
				}
			}
		}
	}

	/**
	 * Focus the next available cell based on user input
	 * @returns void
	 */
	private focusNext(): void {
		const $td: JQuery = this.$element,
			$tr: JQuery = $td.parent(),
			$tbody: JQuery = $tr.parent();

		// NI5BETA-8603 - NOTE: $timeout removed throughout the focus logic

		// NOTE: Index here is relative to the number of features and the lot size in a X x Y grid.
		// (i.e.) if there are 5 features and the lot size is 30, each page will have 30 measurement cells
		// having the highest index === 30.
		const applicableUiConstants = UIConstants.disabledSelector.replace(
			" .sampled-out, .sampled-out-suggested,",
			""
		);

		let $focusableTds = $tbody.find("td.measurement").not(applicableUiConstants).add($td),
			index = $focusableTds.index($td),
			$tdToFocus: JQuery,
			$all: JQuery;

		let nextIndex = 0,
			offsetIdx = 0,
			isDirectionDown = true;

		// NOTE: NI5BETA-8326 - the logic was refactored to use the current page size for calulating which
		// cell to navigate to using the arrow keys rather than the arbitrary number 10.

		// NI5BETA-7872 Handle cases in which the column count (part count) is < pageSize.
		// The original implementation did not account for this.
		// TODO: Refactor the variables below and pass them in as parameters to focusUpOrDownHelper().
		const initRunSize = this.feature && this.feature.initialRunSize,
			currentGridPage =
				this.SharedService.pagination && this.SharedService.pagination.currentPage,
			currentGridPageSize =
				this.SharedService.pagination && this.SharedService.pagination.pageSize,
			totalGridPages =
				this.SharedService.pagination &&
				Math.ceil(
					this.SharedService.pagination.totalItems /
						this.SharedService.pagination.pageSize
				),
			runSizeRemainder = initRunSize % currentGridPageSize;

		const deferred: ng.IDeferred<JQuery> = this.$q.defer();

		switch (Util.getDirection(this.lastEvent)) {
			case Directions.Up:
				($all = $tbody.find("td.measurement").add($td)), (nextIndex = $all.index($td));

				// NI5BETA-7872 Handle cases in which the column count (part count) is < the current pageSize.
				// If the initial run size is less than the current pageSize OR the current page === the last page of measurements
				// and the initial run size is not divisble by the current pageSize w/o a remainder.
				if (runSizeRemainder > 0 && currentGridPage === totalGridPages) {
					offsetIdx = -runSizeRemainder;
				} else {
					offsetIdx = -currentGridPageSize;
				}

				isDirectionDown = false;

				// Add the current cell's index to the set of indexes attempted to focus
				this.cellIndexesAttemptedToFocus.add(nextIndex);

				$tdToFocus = this.focusUpOrDownHelper($all, nextIndex, offsetIdx, isDirectionDown);

				if (!$tdToFocus || !$tdToFocus[0] || $tdToFocus.length === 0) {
					deferred.reject($tdToFocus);
					break;
				}

				this.addCellClasses($td);

				deferred.resolve($tdToFocus);

				break;

			case Directions.Down:
				// 1. Add the sampled-out class to the $td if a value does not exist
				// 2. Remove the sampled-out class from the $toFocusTd if it has the class
				($all = $tbody.find("td.measurement").add($td)), (nextIndex = $all.index($td));

				// NI5BETA-7872 Handle cases in which the column count (part count) is < the current pageSize.
				// If the initial run size is less than the current pageSize OR the current page === the last page of measurements
				// and the initial run size is not divisble by the current pageSize w/o a remainder.
				if (runSizeRemainder > 0 && currentGridPage === totalGridPages) {
					offsetIdx = runSizeRemainder;
				} else {
					offsetIdx = currentGridPageSize;
				}

				// Add the current cell's index to the set of indexes attempted to focus
				this.cellIndexesAttemptedToFocus.add(nextIndex);

				$tdToFocus = this.focusUpOrDownHelper($all, nextIndex, offsetIdx, isDirectionDown);

				if (!$tdToFocus || !$tdToFocus[0] || $tdToFocus.length === 0) {
					deferred.reject($tdToFocus);
					break;
				}

				this.addCellClasses($td);

				deferred.resolve($tdToFocus);

				break;

			case Directions.Left:
				// 1. Add the sampled-out class to the $td if a value does not exist
				// 2. Remove the sampled-out class from the $toFocusTd if it has the class
				$tdToFocus = $focusableTds.eq(index - 1);

				this.addCellClasses($td);

				deferred.resolve($tdToFocus);

				break;

			default:
				// Right

				// 1. Add the sampled-out class to the $td if a value does not exist
				// 2. Remove the sampled-out class from the $toFocusTd if it has the class
				$tdToFocus = $focusableTds.eq(index + 1);

				this.addCellClasses($td);

				deferred.resolve($tdToFocus);

				break;
		}

		// Handle focusing in on the cell
		deferred.promise.then(
			($target: JQuery) => {
				// Focus the cell
				$target.find(":input").first().focus();

				// Clear the set of attempted-to-focus cell indexes
				this.cellIndexesAttemptedToFocus.clear();
			},
			(rejected: any) => {
				// NI5BETA-8603 If the rejected value is `null`
				if (!rejected) {
					// Clear the set of attempted-to-focus cell indexes
					this.cellIndexesAttemptedToFocus.clear();

					// Return out of this function
					return;
				}

				// NI5BETA-7872 Handle cases in which the column count (part count) is < the current pageSize.
				// If the initial run size is less than the current pageSize OR the current page === the last page of measurements
				// and the initial run size is not divisble by the current pageSize w/o a remainder.
				if (runSizeRemainder > 0 && currentGridPage === totalGridPages) {
					offsetIdx = runSizeRemainder;
				} else {
					offsetIdx = currentGridPageSize;
				}

				// Add the current cell's index to the set of indexes attempted to focus
				this.cellIndexesAttemptedToFocus.add(nextIndex);

				const $reversed = this.focusUpOrDownHelper(
					$all,
					nextIndex,
					offsetIdx,
					!isDirectionDown
				);

				if (!$reversed || !$reversed[0] || $reversed.length === 0) {
					// Clear the set of attempted-to-focus cell indexes
					this.cellIndexesAttemptedToFocus.clear();

					// Return out of this function
					return;
				} else {
					$reversed.find(":input").first().focus();
				}
			}
		);
	}
}
