export class Config {
	static $inject = ["$stateProvider", "$urlRouterProvider", "$translatePartialLoaderProvider"];

	constructor(
		$stateProvider: ng.ui.IStateProvider,
		$urlRouterProvider: ng.ui.IUrlRouterProvider,
		$translatePartialLoaderProvider: ng.translate.ITranslatePartialLoaderProvider
	) {
		// Add quality management translations
		$translatePartialLoaderProvider.addPart("QualityMgmt");
		$translatePartialLoaderProvider.addPart("FirstArticle");

		// Wait to render until translations are ready
		const resolve = {
			translateReady: [
				"$translate",
				($translate) => {
					return $translate.onReady();
				}
			]
		};

		// Configure states for measurement entry
		$stateProvider
			.state("start", {
				url: "/?:runId&:partId&:active",
				templateUrl() {
					return "New";
				},
				controller: "NewController",
				controllerAs: "$ctrl",
				reloadOnSearch: false,
				resolve: resolve
			})
			.state("resumeImport", {
				url: "/import/resume",
				template: `<action-area title="{{ 'View_Previous_Imports_Label' | translate }}"></action-area>
				                   <div kendo-grid='grid' k-options='gridOptions'></div>`,
				controller: "ResumeImportController",
				resolve: resolve
			})
			.state("measure", {
				url: "/:id/parts/:partId?page&pageSize&baseSerialNumber",
				templateUrl() {
					return "/QualityManagement/Measurements/Edit";
				},
				controller: "MeasurementsController",
				controllerAs: "$ctrl",
				reloadOnSearch: false,
				resolve: resolve
			})
			.state("import", {
				url: "/:partId/import?:requestId&:partRevision",
				templateUrl() {
					return "/QualityManagement/Measurements/Import";
				},
				controller: "ImportMeasurementsController",
				reloadOnSearch: false,
				params: {
					model: null,
					data: null
				},
				resolve: resolve
			});

		$urlRouterProvider.otherwise("/");
	}
}
