import { Field } from "./../../app/modules/api/models/first-article-form-2field-input";
import { humanize } from "@ni/enhancements/helpers/string";
import { IChartRequest, IChartSeries } from "@ni/interfaces/IChart";
import { Xlsx } from "@ni/services/xlsx";
import * as angular from "angular";
import { BulkExportService } from "@ni/services/bulk-export";

export enum FAIRChartXAxisTypes {
	Monthly = 0,
	ApprovalStatus = 1,
	Supplier = 2,
	Programs = 3,
	FromDivision = 4,
	ToDivision = 5,
	DivisionGroup = 6,
	Reviewer = 7,
	ApprovalSignature = 8,
	Jurisdiction = 9,
	Classification = 10,
	FAIStatus = 11,
	ReportStatus = 12,
	CustomerWorkflowStep = 13,
	Month = 14,
	SupplierGroup = 15,
	FairSignature = 16,
	SupplierWorkflowSteps = 17,
	Day = 18,
	Reason = 19,
	Platform = 20,
	AssignedTo = 22,
	FromDivisionGroups = 23,
	ToDivisionGroups = 24
}

export enum FAIRChartYAxisTypes {
	Count,
	FPY
}

export class Constants {
	public static readonly NOT_SET = "(Not Set)";
	public static readonly NOT_SET_EMPTY = "(Empty)";

	public static readonly types = {
		CATEGORY: "category",
		ATTEMPTS: "attempts",
		FPY: "fpy",
		MONTHLY: "monthly",
		CYCLETIME: "cycletime"
	};

	public static readonly cycleTimeTypes = {
		CreationToSubmission: { label: "Creation to Submission", value: 1 },
		SubmissionToCustomerApproval: { label: "Submission to Approval", value: 4 },
		DisapprovalToResubmission: { label: "Disapproval to Re-Submittal", value: 2 },
		SubmissionToDisposition: { label: "Submission to Disposition", value: 3 },
		CustomerWorkflow: { label: "Customer Workflow Step", value: 5 },
		WorkflowStep: { label: "Workflow Step", value: 6 }
	};

	public static readonly disapprovalTypes = {
		TotalEvents: "Total Disapproval Events",
		FairsByAttempts: "FAIRs by Attempts"
	};

	public static readonly dateTypes = {
		LastUpdatedDate: { label: "Last Updated Date", value: 1 },
		BuyoffStatusDate: { label: "Buy-off Status Date", value: 2 },
		SubmittedDate: { label: "Submitted Date", value: 3 },
		CreatedDate: { label: "Created Date", value: 4 },
		CustomerApprovalDate: { label: "Customer Approval Date", value: 5 },
		SignatureDate: { label: "FAIR Verified Date", value: 6 }
	};
}

export module FAIRChart {
	export class Component implements ng.IComponentOptions {
		public bindings: any;
		public controller: any;
		public template: string;
		constructor() {
			this.bindings = {
				// Chart output
				chart: "=?",

				// Inputs
				isSupplier: "<",
				type: "<",
				start: "<",
				end: "<",
				category: "<",
				secondaryCategory: "<",
				filters: "<",
				config: "<",
				dateType: "<",
				masterPartsTagsFilter: "<"
			};
			this.controller = Controller;
			this.template = `<am-chart id="chart" config="$ctrl.chartConfig" chart="$ctrl.chart"></am-chart>`;
		}
	}

	export class Controller implements ng.IController {
		// Track configs
		public chart: any = {};

		public chartConfig: any = {};
		private config: any; // Input config

		// Input
		private isSupplier: boolean;
		private filters: any[];
		private type: string;
		private category: string;
		private secondaryCategory: string;
		private start: Date;
		private end: Date;
		private dateType: number;
		private masterPartsTagsFilter: string;

		private dateFormat = "MM/YYYY";
		private interval = "month";

		private exportSettings = null;

		private cumulativeData = null;

		static $inject = [
			"FAIChartsResource",
			"FAIChartsSupplierResource",
			"FAIResource",
			"ColorService",
			"FilterCriteriaParser",
			"ChartService",
			"$filter",
			"$timeout",
			"Xlsx",
			"BulkExportService",
			"FAIRColumnsService"
		];

		constructor(
			private FAIChartsResource,
			private FAIChartsSupplierResource,
			private FAIResource,
			private ColorService,
			private FilterCriteriaParser,
			private ChartService,
			private $filter,
			private $timeout: ng.ITimeoutService,
			private Xlsx: Xlsx,
			private BulkExportService: BulkExportService,
			private FAIRColumnsService: FAIRColumnsService
		) {
			if (typeof AmCharts !== "object") {
				throw new Error(
					"Please include AmCharts in this page in order to use a FAIR chart"
				);
			}

			//#region Add export option

			if (AmCharts && AmCharts.themes) {
				this.exportSettings = angular.copy(AmCharts.themes.light.AmChart.export);

				this.exportSettings.menu[0].menu[1] = {
					label: "Export Backing Data",
					click: () => {
						// Set up the request
						let query: Function,
							request: any = this.getChartRequestObject();

						const dateFilterKey = this.getDateFilterKey();

						switch (this.type) {
							case Constants.types.ATTEMPTS:
							case Constants.types.CYCLETIME:
								request.backingData = true;
								query = request.isSupplier
									? this.FAIChartsSupplierResource.query
									: this.FAIChartsResource.query;
								break;
							case Constants.types.CATEGORY:
							case Constants.types.MONTHLY:
								// Get translated column names
								this.FAIRColumnsService.getColumnsAsync({
									isSupplyChain: request.isSupplier
								}).then((d) => {
									query = request.isSupplier
										? this.FAIResource.querySuppliers
										: this.FAIResource.query;

									// Open the bulk export modal and just return
									this.BulkExportService.bulkExport({
										columns: d,
										dataSource: new kendo.data.DataSource({
											transport: {
												read: (options) => {
													const config = this.FilterCriteriaParser.createFilter(
														options.data
													);
													query(
														config,
														function (data) {
															options.success(data.report);
														},
														function (err) {
															options.error(err);
														}
													);
												}
											},
											schema: { data: "data", total: "recordCount" },
											pageSize: 1,
											serverPaging: true,
											serverSorting: true,
											serverFiltering: true,
											filter: [
												{
													field: dateFilterKey,
													operator: "gte",
													value: this.start
												},
												{
													field: dateFilterKey,
													operator: "lte",
													value: this.end
												}
											].concat(this.filters || [])
										})
									} as any);
								});

								return;
							default:
								request["filter.page.limit"] = 9999;
								query = request.isSupplier
									? this.FAIResource.querySuppliers
									: this.FAIResource.query;
								break;
						}

						// Clean the request
						delete request.filters;
						delete request.isSupplier;

						// Issue the request
						query.call(null, request).$promise.then((data) => {
							let title = `${this.chartConfig.title}_${new Date().getTime()}.xlsx`,
								outputData;

							if (request.backingData) {
								outputData = data;
							} else {
								outputData = data.report.data;
							}

							// Save the data
							const spreadsheet = new this.Xlsx(outputData);
							spreadsheet.save(title);
						});
					}
				};
			}

			//#endregion
		}

		// Combine and initialize chart configuration
		public $onInit() {
			this.config = this.config || {};

			this.chartConfig = angular.merge(
				{
					title: "FAIR Charts",
					type: "serial",
					theme: "light",
					chartScrollbar: {
						autoGridCount: true,
						graph: "g1",
						scrollbarHeight: 40,
						enabled: false
					},
					graphBase: {
						fillAlphas: 1,
						alphaField: "alpha",
						lineAlpha: 0.2,
						labelText: "[[value]]",
						labelPosition: "middle",
						color: "#FFFFFF",
						type: "column",
						valueField: "y",
						id: "g1",
						bullet: "round",
						bulletHitAreaSize: 25,
						bulletAlpha: 0,
						balloon: {
							offsetY: 15
						},
						balloonFunction: this.balloonFn
					},
					valueAxes: [
						{
							integersOnly: true,
							minimum: 0,
							stackType: "none"
						}
					],
					categoryAxis: {
						labelFunction: (e) => {
							if (this.isDateRequest()) {
								switch (Number(this.category)) {
									case FAIRChartXAxisTypes.Day:
										return moment(e, this.dateFormat).format("MMM DD YYYY");
									default:
										return moment(e, this.dateFormat).format("MMM YYYY");
								}
							}

							return this.$filter("truncate")(e, 25);
						},
						axisAlpha: 0,
						fillAlpha: 0,
						fillColor: "#000000",
						gridAlpha: 0
					},
					legend: {
						position: "bottom"
					},
					export: this.exportSettings,
					listeners: [
						{
							event: "clickGraphItem",
							method: this.handleClick
						}
					]
				},
				this.config
			);
		}

		public $onChanges() {
			// ToDo: not entirely sure why the timeout is needed, but it helps with initial loading
			setTimeout(() => this.go());
		}

		//#region Utilities

		private go(): void {
			if (!this.chart) {
				return;
			}

			switch (Number(this.category)) {
				case FAIRChartXAxisTypes.Day:
					// ToDo: add this back if the API starts returning proper dates
					//this.dateFormat = this.USER.dateFormat === "Little" ? "DD/MM/YYYY" : "MM/DD/YYYY";
					this.dateFormat = "DD/MM/YYYY";
					this.interval = "day";
					break;
				default:
					this.dateFormat = "MM/YYYY";
					this.interval = "month";
					break;
			}

			// Clear the chart labels
			this.chart.allLabels = [];

			// If the chart legend is defined, enable / re-enable it
			if (
				this.chart.legend &&
				!(this.chartConfig.legend && this.chartConfig.legend.enabled === false)
			) {
				this.chart.legend.enabled = true;
			}

			// If the current chart type is Internal Workflow Step Cycle Time,
			// only load the chart data after the worflow's picker data is loaded and
			// the first value is selected.
			if (Number(this.secondaryCategory) === Constants.cycleTimeTypes.WorkflowStep.value) {
				// Check if the filter object contains a filter for the workflowName property
				const workflowFilter =
					this.filters && this.filters.find((filt) => filt.field === "workflowName");

				// If a filter is not found OR the filter's value is not set
				if (!(workflowFilter && workflowFilter.value)) {
					// Clear the displayed chart data, clear all labels and add a message to the center of the chart
					this.chart.dataProvider = [];
					this.chart.titles = [];

					// Check if the legend, the labels array and the validateData method are defined. If so,
					// disable the legend, add the select workflow name message and validate the data.
					if (
						this.chart.legend &&
						this.chart.allLabels &&
						angular.isDefined(this.chart.validateData)
					) {
						this.chart.legend.enabled = false;
						this.chart.allLabels = [
							{
								x: 0,
								y: "50%",
								text: "Please select a workflow name to generate a report.",
								size: "18",
								align: "center"
							}
						];

						this.chart.validateData();
					}

					// IMPORTANT: make sure to return here
					return;
				}

				// Generate the request and load the chart
				this.loadChart(this.getChartRequestObject());
			} else {
				// Generate the request and load the chart
				this.loadChart(this.getChartRequestObject());
			}
		}

		private getChartRequestObject(): IChartRequest {
			// Don't mess up the members
			let type = this.type,
				category = this.category,
				secondaryCategory: string = null,
				start: Date,
				end: Date,
				isSupplier = this.isSupplier;

			if (
				this.type === Constants.types.CATEGORY &&
				this.category === Constants.types.MONTHLY
			) {
				type = Constants.types.MONTHLY;
				category = null;
			} else if (this.type === Constants.types.FPY) {
				category = Constants.types.MONTHLY;
			} else if (this.type === Constants.types.ATTEMPTS) {
				// Handle the case of disapproval charts
				if (this.secondaryCategory === Constants.disapprovalTypes.TotalEvents) {
					type = "disapprovalquantity";
					// Handle category API
					if (category === "monthly") {
						category = String(FAIRChartXAxisTypes.Month);
					}
				}
			} else if (this.type === Constants.types.CYCLETIME) {
				if (
					this.secondaryCategory ===
					String(Constants.cycleTimeTypes.CustomerWorkflow.value)
				) {
					type = "customerworkflowcycletime";
					isSupplier = true;
					category = "monthly";
				} else if (
					this.secondaryCategory === String(Constants.cycleTimeTypes.WorkflowStep.value)
				) {
					type = "workflowcycletime";
					isSupplier = false;
					category = "monthly";
				} else {
					secondaryCategory = this.secondaryCategory;
				}
			}

			// Don't show any partial months for monthly charts
			if (this.isDateRequest()) {
				start = moment(this.start).startOf(this.interval).toDate();
				end = moment(this.end).endOf(this.interval).subtract(1, "seconds").toDate(); // Unix time rounds up - bad
			}

			// Set if not set
			start = start || this.start;
			end = end || this.end;

			// Add filtering to the request
			const filters = this.FilterCriteriaParser.createFilter({
				filter: { filters: this.filters }
			});

			// Get date filtering key
			let dateFilterKey = this.getDateFilterKey();
			if (this.type === Constants.types.ATTEMPTS) {
				dateFilterKey = "finalDispositionDate";
			}

			var request: IChartRequest = angular.extend(
				{
					isSupplier: isSupplier,
					type: type,
					category: category,
					secondaryCategory: secondaryCategory
				},
				filters,
				{
					[`filter.${dateFilterKey}.GreaterThanOrEqualTo`]: moment(start).utcUnix(),
					[`filter.${dateFilterKey}.LessThanOrEqualTo`]: moment(end).utcUnix()
				}
			);

			// append a datetype filter
			if (this.dateType !== null) {
				const dateTypeFilter = {
					[`filter.dateType`]: this.dateType
				};

				request = angular.extend(request, dateTypeFilter);
			}

			// append master parts tags filter if it exists
			if (this.masterPartsTagsFilter) {
				const masterPartsTagsFilter = {
					[`filter.masterPartsTagsFilter`]: this.masterPartsTagsFilter
				};

				request = angular.extend(request, masterPartsTagsFilter);
			}

			return request;
		}

		//#region Reusable chart utilities

		private getDateFilterKey(): string {
			switch (Number(this.dateType)) {
				case Constants.dateTypes.LastUpdatedDate.value:
					return "lastUpdatedDate";
				case Constants.dateTypes.BuyoffStatusDate.value:
					return "approvalStatusDate";
				case Constants.dateTypes.SubmittedDate.value:
					return "reviewedOrApprovedDate";
				case Constants.dateTypes.CreatedDate.value:
					return "createdDate";
				case Constants.dateTypes.CustomerApprovalDate.value:
					return "customerApprovalDate";
				case Constants.dateTypes.SignatureDate.value:
					return "verifiedDate";
				default:
					return "lastUpdatedDate";
			}
		}

		private numberFormat(n: number, p = 0): string {
			return this.$filter("number")(n, p);
		}

		private isClickDisabled(): boolean {
			switch (this.type) {
				case Constants.types.CYCLETIME:
					switch (Number(this.secondaryCategory)) {
						case Constants.cycleTimeTypes.SubmissionToCustomerApproval.value:
							return false;
						default:
							return true;
					}
				case Constants.types.ATTEMPTS:
					return true;
				default:
					return false;
			}
		}

		private balloonFn = (e): string => {
			let str = "",
				metadata = e.dataContext[`${e.graph.valueField}-metadata`] || {},
				total: number = metadata.total || 0,
				total2: number = metadata.total2 || 0;

			if (e.graph.valueField === "Cumulative FPY")
				total2 = this.cumulativeData[e.index].cumulativeDenominator || 0;

			// Disable pointer cursor if clicking is disabled
			if (this.isClickDisabled()) {
				e.columnGraphics &&
					e.columnGraphics.node &&
					e.columnGraphics.node.classList &&
					e.columnGraphics.node.classList.add("no-hover");
			}

			// Determine what to show based on type
			switch (this.type) {
				case Constants.types.FPY:
					if (e.graph.isCumulative) {
						str = `${moment(e.graph.data[0].category, "MM/YYYY").format(
							"MMMM YYYY"
						)} to `;
					}

					return `<small>${str}${moment(e.category, "MM/YYYY").format(
						"MMMM YYYY"
					)}</small><br>
							<strong class='big'>${this.numberFormat(e.values.value)}</strong>%&nbsp;&nbsp;${
						e.graph.valueField
					}<br />
							${this.numberFormat(total)} FAIR(s) First Passed <br />
							${this.numberFormat(total2)} FAIR(s) Disapproved`;

				case Constants.types.CYCLETIME:
					let category =
							this.category === Constants.types.MONTHLY
								? moment(e.category, "MM/YYYY").format("MMMM YYYY")
								: e.category,
						precision = 0;

					if (metadata.data && metadata.data[0]) {
						total = Number(metadata.data[0].value);
					}

					if (e.graph.isCumulative) {
						category = `${moment(e.graph.data[0].category, "MM/YYYY").format(
							"MMMM YYYY"
						)} to ${category}`;
						precision = 1;
					}

					return `${category}<br/>
							<small>${e.graph.valueField}</small><br/>
							<strong class='big'>${this.numberFormat(e.values.value, precision)}</strong> day${
						e.values.value !== 1 ? "s" : ""
					}<br />
							${this.numberFormat(total)} FAIR${total !== 1 ? "s" : ""}`;

				case Constants.types.ATTEMPTS:
					let key = e.category;
					if (this.category === Constants.types.MONTHLY) {
						key = moment(key, "MM/YYYY").format("MMMM YYYY");
					}

					str = `${key}<br /><strong class='big'>${e.values.value}</strong> ${e.graph.valueField}`;

					if (this.secondaryCategory === Constants.disapprovalTypes.TotalEvents) {
						str += `<br />${this.numberFormat(total)} FAIR${total !== 1 ? "s" : ""}`;
					}

					return str;

				default:
					if (this.isDateRequest()) {
						let outputFormat = "MMMM YYYY";
						switch (Number(this.category)) {
							case FAIRChartXAxisTypes.Day:
								outputFormat = "MMMM DD YYYY";
								break;
						}
						return `<small>${moment(e.category, this.dateFormat).format(
							outputFormat
						)}</small><br/>
								<strong class='big'>${this.numberFormat(e.values.value)}</strong> FAIR${
							e.values.value !== 1 ? "s" : ""
						}`;
					} else {
						return `<small>${e.category}</small><br/>
								<strong class='big'>${this.numberFormat(e.values.value)}</strong> FAIR${
							e.values.value !== 1 ? "s" : ""
						}`;
					}
			}
		};

		private handleClick = (event): void => {
			let url = this.isSupplier ? "/firstarticles/supplier?" : "/firstarticles/internal?",
				filters: any[] = [],
				start: number,
				end: number,
				dateKey = this.getDateFilterKey();

			const point = event.item.dataContext;
			const eventName = event && event.target && event.target.name && event.target.name;

			// Return if clicking is disabled
			if (this.isClickDisabled()) {
				return;
			}

			if (this.isDateRequest()) {
				let startPoint = point,
					endPoint = point;

				// Use entire range if cumulative
				if (event.graph.isCumulative) {
					startPoint = event.chart.dataProvider[0];
				}

				start = moment(startPoint.name, this.dateFormat).startOf(this.interval).utcUnix();
				end = moment(endPoint.name, this.dateFormat).endOf(this.interval).utcUnix();
			} else if (
				this.type === Constants.types.CATEGORY ||
				this.type === Constants.types.CYCLETIME ||
				this.type === Constants.types.ATTEMPTS
			) {
				start = this.start.getTime();
				end = this.end.getTime();

				// Only set the filter if not set
				const target = this.ChartService.FairCategories().getById(this.category);

				let operator: string = target.operator,
					key: string = target.key;

				if (point.name !== Constants.NOT_SET && point.name !== Constants.NOT_SET_EMPTY) {
					let value: any;

					// if the user is filtering by a workflow, then there will be comma separated
					// values, so just leave point.name as is.
					// Else if the event name doesn't have workflow included and does have a comma,
					// keep the comma as part of the whole value
					if (
						!eventName.includes("Workflow") &&
						point &&
						point.name &&
						point.name.length &&
						point.name.includes(",")
					) {
						value = [point.name];
					} else {
						value = point.name;
					}

					// Split the Supplier Workflow Step
					// if this is a division name, then the splitValue will be null
					const splitValue: string[] = value.length > 1 && value.split(",");

					if (splitValue.length > 1) {
						// Workflow Name AND Workflow Step Name filters
						filters.push({
							field: "nextSupplierWorkflowName",
							operator: "eq",
							value: splitValue[0].trim()
						});

						filters.push({
							field: key,
							operator: operator || "eq",
							value: splitValue[1].trim()
						});
					} else {
						// Only a worklow Name is provided
						filters.push({
							field: key,
							operator: operator || "eq",
							value: encodeURIComponent(point.name)
						});
					}
				} else {
					switch (key) {
						case "fromDivision":
						case "toDivision":
							operator = "isNull";
							break;
						default:
							operator = point.name === Constants.NOT_SET ? "isNull" : "isEmpty";
							break;
					}

					filters.push({
						field: key,
						operator: operator,
						value: "true"
					});
				}
			} else {
				// Is a type we can't link to yet
				return;
			}

			// Handle special cases
			switch (this.type) {
				// Not currently in use
				/*
				case Constants.types.ATTEMPTS:
					dateKey = "dispositionDate";

					if (this.secondaryCategory === Constants.disapprovalTypes.FairsByAttempts) {
						filters.push({
							field: "approvalStatus",
							value: "Buy-off Completed|Conditionally Approved",
							operator: "eq"
						});

						const nArray = event.graph.name.match(/([0-9])+/g);
						if (nArray) {
							filters.push({
								field: "attempts",
								value: nArray[0],
								operator: "eq"
							});
						}

					}

					break;
				*/
				case Constants.types.FPY:
					dateKey = "initialDispositionDate";
					filters.push({
						field: "attempts",
						operator: "eq",
						value: 1 // First pass
					});

					// If FPY, only add default buyoff/approval status filter
					// and ignore other buyoff filters
					if (this.filters) {
						this.filters = this.filters.filter((f) => f.field !== "approvalStatus");
					}

					filters.push({
						field: "approvalStatus",
						operator: "eq",
						value: "Buy-off Completed|Conditionally Approved"
					});
					break;
				case Constants.types.CYCLETIME:
					switch (Number(this.secondaryCategory)) {
						case Constants.cycleTimeTypes.SubmissionToCustomerApproval.value:
							dateKey = "finalDispositionDate";
							filters.push({
								field: "approvalStatus",
								value: "Buy-off Completed",
								operator: "eq"
							});
							filters.push({
								field: "approvalStatus",
								value: "Conditionally Approved",
								operator: "eq"
							});
							break;
					}
					break;
			}

			// Remove updated date filtering if we're filtering by something else
			if (dateKey !== this.getDateFilterKey()) {
				filters.push({
					field: this.getDateFilterKey(),
					value: null,
					operator: "gte"
				});
				filters.push({
					field: this.getDateFilterKey(),
					value: this.end.getTime(),
					operator: "lte"
				});
			}

			// Add in date filters
			filters.push({
				field: dateKey,
				value: start,
				operator: "gte",
				type: "date" // Annotate the filter type as date
			});
			filters.push({
				field: dateKey,
				value: end,
				operator: "lte",
				type: "date" // Annotate the filter type as date
			});

			// Add in search filters
			if (this.filters) {
				// Set of expected boolean filter keys
				const booleanFields = new Set(["isSubmitted", "isFull"]);

				// NOTE: for converting a kendo string filter to a net-inspect boolean filter
				// This is needed to approriately filter the FAIR grid upon clicking a bar in the chart
				for (const filter of this.filters) {
					// If the filter's field is in the expected filter keys set, change the
					// operator to isTrue and set the value to true / false based on the string
					// value associated with the original kendo filter.
					if (booleanFields.has(filter.field)) {
						filter.operator = "isTrue";
						filter.value = filter.value === "true" ? true : false;
					}

					if (
						filter.field === "documentedNonconformances" &&
						filter.value === "Not selected"
					) {
						filter.operator = "isEmpty";
						filter.value = "";
					}
				}

				// Combine the filters
				filters = filters.concat(this.filters);
			}

			// If the approvalStatus filter contains more than one filter split them so that
			// the approvalStatus filters works properly and shows on FAIR list page
			if (filters) {
				filters = filters.reduce((fltrs, currentFilter) => {
					if (currentFilter.field === "approvalStatus") {
						const approvalStatusFilters = currentFilter.value.split("|");
						approvalStatusFilters.forEach((f) => {
							fltrs.push({
								field: "approvalStatus",
								value: f,
								operator: "eq"
							});
						});
					} else {
						fltrs.push(currentFilter);
					}
					return fltrs;
				}, []);
			}

			// INFO: For filtering the Ngx FAIR Lists, the `filter.isInternal` filter is needed
			// NI5BETA-10873 Create a filter for the `filter.isInternal` property
			const isIternalFilter = {
				field: "filter.isInternal",
				operator: null,
				value: this.isSupplier ? false : true
			};

			// Add the `filter.isInternal` filter as the first entry in the array of filters
			filters.unshift(isIternalFilter);

			const ds = new kendo.data.DataSource({
				filter: filters
			});

			// INFO: For filtering the Ngx FAIR Lists, fix any mismatched filter properties here
			// NI5BETA-10873 Remove the `type` prop from each filter
			// before decoding the URI
			for (const filt of filters) {
				if (filt.type) {
					delete filt.type;
				}

				// Change the `workflowName` filter field to `nextSupplierWorkflowName`
				if (filt.field === "workflowName") {
					filt.field = "nextSupplierWorkflowName";
				}

				// Change fromDivisionGroups & toDivisionGroups `operator` to `contains`
				if (filt.field === "fromDivisionGroups" || filt.field === "toDivisionGroups") {
					filt.operator = "contains";
				}

				// Change `supplier` to `organizationName`
				if (filt.field === "supplier") {
					filt.field = "organizationName";
				}

				// Change the `completionStatus` filter value to `void` from `Void`
				// if the filter exists and the value is the uppercase version
				if (filt.field === "completionStatus" && filt.value === "Void") {
					filt.value = "void";
				}
			}

			url += decodeURIComponent(this.FilterCriteriaParser.dataSourceToUrl(ds, true));

			console.warn(url);

			window.open(url, "_blank");
		};

		//#endregion

		//#region Data processing

		private cleanTimeSeriesData(seriesArr: IChartSeries[]): IChartSeries[] {
			// Develop a list of all dates that should be present
			// ToDo: this may need to work with other date ranges

			// Get request min and max
			const current = moment(this.start),
				max = moment(this.end),
				shouldBeSeen = {};

			while (current < max) {
				shouldBeSeen[current.format(this.dateFormat)] = true;
				current.add(1, this.interval);
			}

			// Sort and fill in missing data
			for (const series of seriesArr) {
				const seen = {};

				if (series && series.data) {
					for (const datum of series.data) {
						const key = moment(datum.name, this.dateFormat).format(this.dateFormat); // Normalize date names i.e. "01/2018" and "1/2018"
						datum.name = key; // Assign the properly formatted name back to the datum
						seen[key] = true;
					}
				}

				// Add in any missing dates
				angular.forEach(shouldBeSeen, (value, key) => {
					if (!seen[key]) {
						series.data.push({
							name: key,
							y: 0
						} as any);
					}
				});
			}

			return seriesArr;
		}

		private processTimeSeriesData(
			seriesArr: IChartSeries[],
			request: IChartRequest
		): IChartSeries[] {
			const seriesOutput: any[] = [],
				labelText = `[[value]]${request.type === Constants.types.FPY ? "%" : ""}`;

			// Loop though all data series in response
			for (const series of seriesArr) {
				// Add series to chart
				seriesOutput.push({
					name: request.type === Constants.types.FPY ? "First Pass Yield" : series.name,
					labelText: labelText,
					data: series.data,
					type: "column",
					cursor: "pointer",
					fillColors: (series as any).fillColors,
					legendColor: (series as any).fillColors
				});
			}

			// Add a cumulative graph
			// Only perform for single graphs
			if (
				(request.type === Constants.types.FPY ||
					request.type === Constants.types.CYCLETIME) &&
				seriesArr.length === 1
			) {
				const last = angular.copy(seriesArr[0].data),
					cumulative = [];

				this.cumulativeData = last;

				let fairs = 0,
					fpy = 0;

				// Sort the monthly data
				last.sort((a, b) => {
					return moment(a.name, this.dateFormat) - moment(b.name, this.dateFormat);
				});
				for (const el of last) {
					el.metadata = el.metadata || { total: 0 };
					fairs += el.metadata.total;
					fpy += el.metadata.total * el.y;
					cumulative.push({
						y:
							request.type === Constants.types.FPY
								? el.cumulative
								: Math.round((fpy / fairs) * 10) / 10,
						name: el.name,
						metadata: {
							total: fairs
						}
					} as any);
				}
				const color = chroma(this.ColorService.chartColors[5]).brighten(0.75).hex();
				seriesOutput.push({
					name:
						request.type === Constants.types.FPY
							? "Cumulative FPY"
							: "Average Cycle Time",
					isCumulative: true, // Track that this is cumulative
					data: cumulative,
					color: color,
					lineColor: color,
					fillAlphas: 0,
					lineAlpha: 0.6,
					lineThickness: 4,
					labelOffset: 5,
					labelText: labelText,
					labelPosition: "top",
					animationPlayed: true,
					type: "smoothedLine",
					bullet: "round",
					bulletSize: 10,
					bulletBorderColor: color,
					bulletBorderAlpha: 0.8,
					bulletBorderThickness: 3,
					bulletColor: "#FFFFFF",
					bulletAlpha: 1,
					bulletHitAreaSize: 20,
					zIndex: 5
				});
			}

			return seriesOutput;
		}

		private processCategoryData(
			seriesArr: IChartSeries[],
			request: IChartRequest
		): IChartSeries[] {
			// Sort them correctly within cycletime
			if (request.type === Constants.types.CYCLETIME) {
				const d = angular.copy(seriesArr);
				seriesArr = [d[0], d[3], d[1], d[2]];
			}

			// Remove empty data points from the root
			seriesArr = seriesArr.filter((el) => el);

			for (const series of seriesArr) {
				// Set the type
				series.type = "column";

				// Remove or rename empties or bad data for some categories
				switch (Number(request.category)) {
					case FAIRChartXAxisTypes.ApprovalStatus:
					case FAIRChartXAxisTypes.FromDivision:
					case FAIRChartXAxisTypes.ToDivision:
					case FAIRChartXAxisTypes.Reviewer:
					case FAIRChartXAxisTypes.ApprovalSignature:
					case FAIRChartXAxisTypes.CustomerWorkflowStep:
					case FAIRChartXAxisTypes.Reason:
						series.data = series.data.filter(
							(el) =>
								el.name &&
								el.name !== Constants.NOT_SET &&
								el.name !== Constants.NOT_SET_EMPTY
						);
						break;
				}
			}

			return seriesArr;
		}

		private processAttemptsData(seriesArr: IChartSeries[]): IChartSeries[] {
			// Sort the series array (0 - 4 disapprovals)
			seriesArr.sort((a, b) => {
				if (a && b && a.name && b.name) {
					return Number(a.name[0]) - Number(b.name[0]);
				}
				return 0;
			});

			const outputSeries: IChartSeries[] = [];

			// Rename each series as appropriate
			for (const series of seriesArr) {
				const n = Number(series.name[0]) - 1 || 0,
					color = chroma(this.ColorService.sigmaColors[6])
						.darken(n - 1)
						.saturate(2)
						.get("hex");

				if (n) {
					series.name = `FAIRs Disapproved ${String(n)} time${n !== 1 ? "s" : ""}`;
					(series as any).fillColors = color;
					(series as any).legendColor = color;
					(series as any).fillAlphas = 1;
					outputSeries.push(series);
				}
			}

			return outputSeries;
		}

		private processCycleTimeData(seriesArr: IChartSeries[]): IChartSeries[] {
			if (this.secondaryCategory) {
				// Map the metadata into series arrays
				if (
					Number(this.secondaryCategory) ===
					Constants.cycleTimeTypes.SubmissionToDisposition.value
				) {
					const outputSeriesObj = {},
						outputSeriesArr: any[] = [];

					if (seriesArr && seriesArr[0] && seriesArr[0].data) {
						for (const datum of seriesArr[0].data) {
							if (datum.metadata && datum.metadata.slice) {
								const key: string = datum.metadata.slice;
								outputSeriesObj[key] = outputSeriesObj[key] || [];
								outputSeriesObj[key].push(datum);
							}
						}
					}

					for (const k in outputSeriesObj) {
						if (outputSeriesObj.hasOwnProperty(k)) {
							let color = "";
							switch (k) {
								case "Buy-off Completed":
									color = this.ColorService.chartColors[0];
									break;
								case "Conditionally Approved":
									color = "#92d050";
									break;
								case "Disapproved For Buy-off":
									color = this.ColorService.sigmaColors[6];
									break;
							}

							outputSeriesArr.push({
								name: k,
								data: outputSeriesObj[k],
								fillColors: color
							});
						}
					}

					seriesArr = outputSeriesArr;
				}
			}

			return seriesArr;
		}

		// Clean up data points
		private cleanData(seriesArr: IChartSeries[]): IChartSeries[] {
			for (const series of seriesArr) {
				for (const datum of series.data) {
					// Remove empty names

					if (datum.name === "") {
						datum.name = Constants.NOT_SET_EMPTY;
					}

					datum.name = datum.name || Constants.NOT_SET;

					// Process metadata
					if (datum.metadata && datum.metadata.data) {
						const total = Number(
								(datum.metadata.data[0] && datum.metadata.data[0].value) || 0
							),
							slice = (datum.metadata.data[1] && datum.metadata.data[1].value) || "",
							total2 = Number(
								(datum.metadata.data[2] && datum.metadata.data[2].value) || 0
							);
						datum.metadata = { total: total, slice: slice, total2: total2 };
					} else {
						datum.metadata = {};
					}
				}
			}
			return seriesArr;
		}

		private isDateRequest = (request?: IChartRequest, includeFpy = false): boolean => {
			// Set tricky default parameter
			if (!request) {
				request = this as any;
			}

			return (
				request.category === Constants.types.MONTHLY ||
				Number(request.category) === FAIRChartXAxisTypes.Month ||
				Number(request.category) === FAIRChartXAxisTypes.Day ||
				request.type === Constants.types.MONTHLY ||
				request.secondaryCategory === Constants.types.MONTHLY ||
				(includeFpy && request.type === Constants.types.FPY)
			);
		};

		private sortSeries(seriesArr: IChartSeries[], request: IChartRequest): IChartSeries[] {
			if (this.isDateRequest(request)) {
				// Sort time series chronologically

				for (const series of seriesArr) {
					series.data.sort((a, b) => {
						return moment(a.name, this.dateFormat) - moment(b.name, this.dateFormat);
					});
				}
			} else {
				const map: { [name: string]: number } = {};

				// First pass: create a map to sort by
				for (const series of seriesArr) {
					for (const point of series.data) {
						map[point.name] = map[point.name] || 0;
						map[point.name] += point.y;
					}
				}

				// Second pass: sort category series greatest to least, accoring to map totals
				for (const series of seriesArr) {
					series.data.sort((a, b): number => {
						return map[b.name] - map[a.name];
					});
				}
			}

			return seriesArr;
		}

		//#endregion

		//#endregion

		//#region Main chart loading function

		private loadChart(request: IChartRequest): void {
			const resx = request.isSupplier
				? this.FAIChartsSupplierResource
				: this.FAIChartsResource;

			// Clean the request
			delete request.filters;
			delete request.isSupplier;

			// Cancel if changing chart type
			switch (request.type) {
				case "rejections":
				case "pofeed":
					return;
			}

			// #region Convert known boolean filters (from the filter menu) from Kendo string filters to NIBooleanFilters

			// NOTE: for converting a kendo string filter to a net-inspect boolean filter
			// This is needed to prevent TypeError: operators[(operator || "eq").toLowerCase()] is not a function
			// since the kendo operators object does NOT contain operators for boolean filters.

			// Iterate through the known boolean filter keys
			for (const key of ["isFull", "isSubmitted", "isProductSafety"]) {
				// If the request is defined and the request object contains the filter key
				if (request[`filter.${key}.EqualTo`]) {
					// Convert the request entry to a niBooleanFilter (i.e. operator === isTrue) and set the value from the string value passed in
					request[`filter.${key}.isTrue`] = request[`filter.${key}.EqualTo`];

					// Delete the kendo-generated string filter from the request object
					delete request[`filter.${key}.EqualTo`];
				}
			}

			if (request["filter.documentedNonconformances.EqualTo"] === "Not selected") {
				request["filter.documentedNonconformances.IsEmpty"] = true;
				delete request["filter.documentedNonconformances.EqualTo"];
			}

			// #endregion

			// Issue the request
			this.chartConfig.loading = resx.query(request, (seriesArr: IChartSeries[]): void => {
				//#region Process Data
				// Track series to set
				let seriesOutput: any[] = [];

				// Clean empty names
				seriesArr = this.cleanData(seriesArr);

				if (request.type === Constants.types.ATTEMPTS) {
					// If attempts multi-series, rename series
					seriesArr = this.processAttemptsData(seriesArr);
				} else if (
					this.type === Constants.types.ATTEMPTS &&
					this.secondaryCategory === Constants.disapprovalTypes.TotalEvents
				) {
					// Only show one series if attempts simple
					if (seriesArr[0]) {
						seriesArr[0].name = "Disapproval Events";
						(seriesArr[0] as any).legendColor = this.ColorService.sigmaColors[6];
						(seriesArr[0] as any).fillColors = this.ColorService.sigmaColors[6];
					}
				} else if (request.type === Constants.types.CYCLETIME) {
					seriesArr = this.processCycleTimeData(seriesArr);
				}

				// Clean time series data
				if (this.isDateRequest(request)) {
					seriesArr = this.cleanTimeSeriesData(seriesArr);
				}

				// Add or remove grid
				this.chartConfig.categoryAxis.fillAlpha =
					seriesArr && seriesArr.length > 1 ? 0.03 : 0;

				if (this.isDateRequest(request, true)) {
					// Process time series data
					seriesArr = this.processTimeSeriesData(seriesArr, request);
				} else {
					// Process category data
					seriesArr = this.processCategoryData(seriesArr, request);
				}

				seriesOutput = this.sortSeries(seriesArr, request);

				//#endregion

				//#region Set Chart properties

				// Set the chart title
				let chartTitle = this.isSupplier ? "Supplier " : "Internal ",
					suppressCategory = false;

				// Set the chart title and y axis name
				delete this.chartConfig.valueAxes[0].maximum;

				switch (this.type) {
					case Constants.types.MONTHLY:
						this.chartConfig.valueAxes[0].title = "FAIR Quantity";
						chartTitle += "FAIR Charts";
						break;
					case Constants.types.ATTEMPTS:
						switch (this.secondaryCategory === Constants.disapprovalTypes.TotalEvents) {
							case true:
								this.chartConfig.valueAxes[0].title = "Disapproval Events Quantity";
								chartTitle += "Total Disapproval Events";
								break;
							default:
								this.chartConfig.valueAxes[0].title = "Disapproved FAIR Quantity";
								chartTitle += "Disapproved FAIR Quantity";
						}
						break;
					case Constants.types.CATEGORY:
						this.chartConfig.valueAxes[0].title = "FAIR Quantity";
						chartTitle += "FAIR Charts";
						break;
					case Constants.types.FPY:
						this.chartConfig.valueAxes[0].maximum = 100;
						this.chartConfig.valueAxes[0].title = "First Pass Yield %";
						chartTitle += "First Pass Yield Charts ";
						break;
					case Constants.types.CYCLETIME:
						this.chartConfig.valueAxes[0].title = "Cycle Time (Days)";

						// Get the type
						let title = "";
						for (const key in Constants.cycleTimeTypes) {
							if (Constants.cycleTimeTypes.hasOwnProperty(key)) {
								if (
									Number(Constants.cycleTimeTypes[key].value) ===
									Number(this.secondaryCategory)
								) {
									title = Constants.cycleTimeTypes[key].label;
									break;
								}
							}
						}

						chartTitle += `${title} Cycle Time Charts`;
						suppressCategory = true; // Don't show "by month" for cycle time charts
						break;
					default:
						this.chartConfig.valueAxes[0].title = humanize(request.type);
						chartTitle += "FAIR Charts";
						break;
				}

				if (!suppressCategory) {
					const categories = this.ChartService.FairCategories(),
						category = categories.getById(this.category);
					if (category) {
						chartTitle += ` by ${category.label}`;
						if (
							category.label === "Month" &&
							request.type === Constants.types.MONTHLY
						) {
							chartTitle += " Updated";
						}
					}
				}

				this.chartConfig.title = chartTitle;

				//#endregion

				// Set series data

				// TODO: remove this and have the API not return any series data with NULL names
				if (
					this.secondaryCategory === String(Constants.cycleTimeTypes.WorkflowStep.value)
				) {
					seriesOutput = seriesOutput.filter((series) => series.name !== null);
				}

				this.chartConfig.series = seriesOutput;

				this.$timeout(() => {
					// Set Axis bounds
					if (this.chart && this.chart.dataProvider) {
						if (this.isDateRequest(request)) {
							this.chartConfig.categoryAxis.min = Math.max(
								this.chart.dataProvider.length - 20,
								0
							);
							this.chartConfig.categoryAxis.max = this.chart.dataProvider.length;
						} else {
							this.chartConfig.categoryAxis.min = 0;
							this.chartConfig.categoryAxis.max = 20;
						}
					}
				});
			}).$promise;
		}

		//#endregion
	}
}
