import { IMeasurement, INonConformanceSettings } from "../interfaces";

export class EtagController {
	static $inject = ["$scope", "SharedService", "$uibModalInstance", "measurement"];

	constructor(
		private readonly $scope,
		private readonly SharedService,
		private readonly $uibModalInstance,
		private readonly measurement: IMeasurement
	) {
		// Use the injected measurement in this scope
		$scope.measurement = measurement;

		// Determine if OOT or OOC
		if ($scope.measurement.isOOT) {
			$scope.ccaKey = "outOfToleranceDiscrepancy";
			$scope.params = { type: 1, withCorrectiveActionsOnly: true };
		} else {
			$scope.ccaKey = "outOfControlDiscrepancy";
			$scope.params = { type: 2, withCorrectiveActionsOnly: true };
		}

		// Add in the old CCA data if it exists
		if ($scope.measurement.cca) {
			$scope.measurement[$scope.ccaKey] = $scope.measurement.cca;
		}

		// Confirm addition of CCA
		// Or if from "Create Without Tag" button, create the measurement without an eTag
		$scope.saveTag = (): void => {
			$uibModalInstance.close($scope.measurement);
		};

		// Cancel without saving CCA
		$scope.close = (): void => {
			$uibModalInstance.dismiss();
		};

		$scope.isCCARequired = (): boolean => {
			const measurement = $scope.measurement,
				settings: INonConformanceSettings =
					SharedService.apiResponse && SharedService.apiResponse.nonConformanceSettings;

			if (measurement && settings) {
				if (measurement.isOOT && settings.requireCauseCorrectiveActionOnOutOfTolerance) {
					return true;
				} else if (
					measurement.isOOC &&
					settings.requireCauseCorrectiveActionOnOutOfControl
				) {
					return true;
				}
			}
			return false;
		};
	}
}
