import { IFeature } from "../interfaces";

export class WindowComponent implements ng.IComponentOptions {
	public bindings: any;
	public controller: any;
	public template: string;

	constructor() {
		this.bindings = {
			model: "<",
			closed: "=",
			feature: "<",
			lightbulb: "<",
			onWindowInit: "&",
			includePreControl: "<?"
		};
		this.controller = WindowController;
		this.template =
			// html
			`<button type="button" class="btn btn-default" ng-disabled="!$ctrl.feature" 
				ng-click="$ctrl.kWindow.open()" ng-hide="$ctrl.open" translate>
				Open_Details_Label
			</button>

			<div kendo-window="$ctrl.kWindow" k-options="$ctrl.kWindowOptions"
				k-on-open="$ctrl.open = true" k-on-close="$ctrl.open = false">
				<div kendo-tab-strip="$ctrl.tabstrip" k-options="$ctrl.tabStripOptions">
					<ul class="quality-management-measurment-chart-list">
						<li class="k-state-active" translate>SPC_Chart_Label</li>
						<li translate>Feature_Info_Label</li>
						<li translate>Feature_Photos_Label</li>
						<li id="lightbulb" ng-show="$ctrl.feature.featureState.isLightBulbOn">CCA History</li>
						<li ng-show="$ctrl.feature.setupComments" translate>Setup_Comments_Label</li>
					</ul>
					<div>
						<div ng-if="$ctrl.shouldTabShow('spc')" style="height: 100%">${require("../../templates/window/spc.html")}</div>
					</div>
					<div>${require("../../templates/window/info.html")}</div>
					<div>${require("../../templates/window/photos.html")}</div>
					<div>
						<div ng-if="$ctrl.shouldTabShow('cca')">
							<lightbulb-grid feature="$ctrl.feature"></lightbulb-grid>
						</div>
					</div>
					<div ng-show="$ctrl.feature.setupComments">{{$ctrl.feature.setupComments}}</div>
				</div>
			</div>`;
	}
}

export class WindowController implements ng.IController {
	private model: any;
	public open = false;
	public feature: IFeature;
	public kWindow: kendo.ui.Window;
	public tabstrip: kendo.ui.TabStrip;

	public onWindowInit: () => (WindowController) => void;

	public spcRunNumber: string;
	public period = 0;
	public spcLimit = 20;
	public includePreControl: boolean;

	static $inject = ["$scope", "$translate"];

	constructor(
		private readonly $scope: ng.IScope,
		private readonly $translate: ng.translate.ITranslateService
	) {}

	// Initialize the componenet
	$onInit() {
		this.onWindowInit() && this.onWindowInit()(this);
		this.spcRunNumber = this.model && this.model.runNumber;
	}

	// Deactivate CCA tab if not available
	$onChanges = (changes: any) => {
		if (this.feature) {
			if (!(this.feature.featureState.isLightBulbOn && this.shouldTabShow("cca"))) {
				if (this.tabstrip && this.tabstrip.select().is("#lightbulb")) {
					this.tabstrip.select(0);
				}
			}
		}
	};

	public shouldTabShow(tab: "spc" | "cca"): boolean {
		if (tab === "spc") {
			return (
				this.kWindow &&
				this.kWindow.options &&
				this.kWindow.options.visible &&
				this.tabstrip &&
				this.tabstrip.select().index() === 0
			);
		} else {
			return (
				this.kWindow &&
				this.kWindow.options &&
				this.kWindow.options.visible &&
				this.tabstrip &&
				this.tabstrip.select().is("#lightbulb")
			);
		}
	}

	public activateLightbulb(dataItem): void {
		if (this.kWindow) {
			// Open the window
			this.kWindow.open();
			// Widen out the window
			//this.kWindow.setOptions({ width: Math.max(this.kWindow.wrapper.innerWidth(), window.innerWidth / 2) });
		}
		this.feature = dataItem;

		// Handle data reloading
		if (!this.tabstrip.select().is("#lightbulb")) {
			this.tabstrip.select($("#lightbulb"));
		}
	}

	public kWindowOptions: kendo.ui.WindowOptions = {
		animation: false,
		visible: false,
		width: window.innerWidth / 4,
		height: window.innerHeight / 4,
		minHeight: 250,
		minWidth: 400,
		scrollable: false,
		actions: ["Maximize", "Close"],
		title: this.$translate.instant("Feature_Details_Label"),
		position: {
			top: 100,
			left: window.innerWidth * (2 / 3)
		}
	};

	public tabStripOptions: kendo.ui.TabStripOptions = {
		animation: { close: { effects: "fadeOut" }, open: { effects: "fadeIn" } },
		dataTextField: "name",
		dataContentField: "text",
		value: this.$translate.instant("SPC_Chart_Label"),
		tabPosition: "left",
		activate: () => {
			this.$scope.$apply(); // ensure "shouldTabShow" fires again
		}
	};
}
