import { INonConformanceSettings, IMeasurement, IFeature } from "../interfaces";
import { SharedService } from "./shared";

export class ETagService {
	static $inject = ["SharedService", "$q", "$uibModal"];

	constructor(
		private readonly SharedService: SharedService,
		private readonly $q: ng.IQService,
		private readonly $uibModal: ng.ui.bootstrap.IModalService
	) {}

	public createEtag = (feature: IFeature, measurement: IMeasurement): ng.IPromise<any> => {
		const settings: INonConformanceSettings =
			this.SharedService.apiResponse && this.SharedService.apiResponse.nonConformanceSettings;

		// Check if CCA recording is enabled
		if (
			(measurement.isOOT && settings.enableCauseCorrectiveActionOnOutOfTolerance) ||
			(measurement.isOOC && settings.enableCauseCorrectiveActionOnOutOfControl)
		) {
			// If enabled, show modal
			const modal = this.$uibModal.open({
				templateUrl: "../../templates/etag.html",
				controller: "EtagController",
				resolve: {
					measurement() {
						// inject the active measurement into the controller
						return measurement;
					}
				}
			});

			return modal.result;
		} else {
			// Otherwise, just return immediately
			return this.$q.when(measurement);
		}
	};
}
