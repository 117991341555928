import { IPagination, IServerResponse, IRun } from "../interfaces";

// Simple service to track items across UI components
export class SharedService {
	private _pagination: IPagination = {} as any;
	private _editComment: string;
	private _editMode = false;
	private _runEdited = false;
	private _apiResponse: IServerResponse;
	private _run: IRun;
	private _spcChart: any;
	private _activeFeatureEntry: any;
	private _x: number;

	get pagination(): IPagination {
		return this._pagination;
	}

	set pagination(pagination: IPagination) {
		this._pagination = pagination;
	}

	get editComment() {
		return this._editComment;
	}
	set editComment(editComment) {
		this._editComment = editComment;
	}

	get editMode() {
		return this._editMode;
	}
	set editMode(editMode) {
		this._editMode = editMode;
	}

	get runEdited() {
		return this._runEdited;
	}
	set runEdited(runEdited) {
		this._runEdited = runEdited;
	}

	get apiResponse() {
		return this._apiResponse;
	}
	set apiResponse(apiResponse) {
		this._apiResponse = apiResponse;
	}

	get run() {
		return this._run;
	}
	set run(run) {
		this._run = run;
	}

	get spcChart() {
		return this._spcChart;
	}
	set spcChart(spcChart) {
		this._spcChart = spcChart;
	}

	get activeFeatureEntry() {
		return this._activeFeatureEntry;
	}
	set activeFeatureEntry(activeFeatureEntry) {
		this._activeFeatureEntry = activeFeatureEntry;
	}

	get x() {
		return this._x;
	}
	set x(x) {
		this._x = x;
	}
}
