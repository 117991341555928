import { IFeature, IMeasurement } from "../interfaces";
import { SamplePlanType, FrequencyNames } from "../classes/Constants";
import { SamplePlan, SpcOutput } from "../classes/SamplePlan";
import { Util, Measurement } from "../classes/Util";
import { SharedService } from "./shared";

export class SampleService {
	static $inject = ["SharedService"];
	constructor(protected SharedService: SharedService) {}

	// Sampling Plan Caches
	private randomSamplingCache: { string?: number[] } = {};
	private singlePartInEntryCache = {
		partId: "",
		runNumber: "",
		isFeatureMeasured: {}
	};

	/**
	 * Method determines which measurement sampling plan to apply to a part feature.
	 * Sets measurement frequency requirements on feature and outputs an array of
	 * measurement entries (containing an order-number property), which is used
	 * to render measurement entry input controls.
	 * @param feature - The part feature being measured within a part-run.
	 * @param incomingOOTMeasurement - Indication for whether or not the current
	 * measurement entry submission is out-of-tolerance (OOT). Used to trigger reactive OOT
	 * measurement requirement rules for specific sampling plans.
	 */
	public configureFeatureAndCreateMeasurementEntries(
		feature: IFeature,
		incomingOOTMeasurement = false
	): IMeasurement[] {
		// #region Prepare Calculation & Configuration Values

		const samplePlanType = feature.featureState && feature.featureState.samplePlanType;
		const lotSize = feature.initialRunSize;
		let spcOutput: SpcOutput;

		// Relative Measurements
		let currentPageMeasurements = (feature.parts || []).filter((el) => el && el.resultText); // Ensure this is equivalent to the server response
		const totalRunMeasurements = feature.lastMeasurement.totalMeasurements; // May include miscounts when multiple measurements are pending.

		// Measurement Order Numbers
		const pagination = this.SharedService.pagination;

		const preExistingOrderNoList = currentPageMeasurements.map((el) => el.orderNo);
		const pageFirstOrderNo = (pagination.currentPage - 1) * pagination.pageSize + 1; // E.g. Page Size 10 Series: 1, 11, 21 ...
		const pageLastOrderNo = pageFirstOrderNo + pagination.pageSize - 1; // E.g. Page Size 10 Series: 10, 20, 30, ...

		let nextOrderNo = 0;

		// TODO: Conflicting Data Context: Last Measurement
		// Not all properites may pertain to the last measurement taken. The orderNo property
		// does not change unless orderNo increases on the next measurement taken.
		// This unexpected behavior appears to have been implemented to support SPC sampling plan logic.
		const lastMeasuredOrderNo = feature.lastMeasurement.orderNo; // Util.guardFeature() adds default 0 value.
		const maxMeasuredOrderNo =
			preExistingOrderNoList.reduce((a, b) => Math.max(a, b), 0) || lastMeasuredOrderNo;

		// Remove kendo observables (ToDo: Eliminate these altogether)
		if ((currentPageMeasurements as any).toJSON) {
			currentPageMeasurements = (currentPageMeasurements as any).toJSON();
		}

		// #endregion

		// #region Set Sampling Frequency Requirements

		switch (samplePlanType) {
			case SamplePlanType.Spc:
				spcOutput = SamplePlan.applySpcOrInterRunSampling(feature, incomingOOTMeasurement);
				nextOrderNo =
					lastMeasuredOrderNo > 0 ? lastMeasuredOrderNo + spcOutput.everyNthPart : 1;
				break;
			case SamplePlanType.AS13002Major:
			case SamplePlanType.AS13002Minor:
				SamplePlan.applyAS13002Sampling(feature, incomingOOTMeasurement);
				break;
			case SamplePlanType.AQL:
			case SamplePlanType.AQLModified:
				SamplePlan.applyAqlSampling(feature, incomingOOTMeasurement);
				break;
			case SamplePlanType.SampleAt90:
			case SamplePlanType.SampleAt92:
			case SamplePlanType.SampleAt95:
			case SamplePlanType.SampleAt97:
			case SamplePlanType.SampleAt99:
				// Here: find indexes of random parts to be measure
				const range = SamplePlan.applyStatisticalSampling(samplePlanType, lotSize),
					nRandomParts = range.getNumberToCount(lotSize);

				// Handle letting the user enter values for any part -
				// Update the randomParts with the order number of the parts measured for the feature
				const measuredParts: number[] = [];

				// Iterate through the feature's parts to get the order number associated with with the
				// part, if the part has an id set, for overriding the random sampling indexes.
				for (const part of feature.parts) {
					if (part && part.id) {
						measuredParts.push(part.orderNo);
					}
				}

				measuredParts.sort((a, b) => a - b); // Ensure they're sorted for faster use

				// Get new random indexes from cache if they're not cached
				const remainingPartsToMeasure = nRandomParts - totalRunMeasurements;
				let randomParts = this.getRandomSamplingCache(feature);

				if (!randomParts) {
					randomParts = Util.randomPartIndexes(
						lastMeasuredOrderNo || 0,
						lotSize,
						remainingPartsToMeasure
					);
					randomParts.sort((a, b) => a - b); // Ensure they're sorted for faster use

					this.setRandomSamplingCache(feature, randomParts);
				}

				// Get next part to measure
				for (const randomIndex of randomParts) {
					// assumes sorted
					if (randomIndex > maxMeasuredOrderNo) {
						nextOrderNo = randomIndex;
						break;
					}
				}

				// Calculate number to measure
				feature.featureState.partsToMeasure = nRandomParts;
				feature.featureState.frequency = FrequencyNames.NrandomParts(nRandomParts);

				break;
			case SamplePlanType.SampleAt100:
				feature.featureState.partsToMeasure = lotSize;
				break;
			case SamplePlanType.FirstAndLast:
				feature.featureState.partsToMeasure = lotSize === 1 ? 1 : 2;
				nextOrderNo = lastMeasuredOrderNo === 1 ? lotSize : 1;

				// Fix verbage
				// TODO: Why is this not set correctly the first time?
				feature.featureState.frequency = "(First and last part)";
				break;
			case SamplePlanType.FirstOnly:
				feature.featureState.partsToMeasure = 1;
				break;
			case SamplePlanType.SinglePartInEntry:
				// Always show 0/1, 1/1, or 0/0 (i.e. no parts left) for checks completed.
				// Total run measurements for feature will be mutated.
				let sessionMeasurementsTaken: number;
				let sessionMeasurementsRequired: number;

				const isFeatureMeasured = this.isSinglePartInEntryMeasured(feature);

				if (isFeatureMeasured) {
					sessionMeasurementsTaken = 1;
					sessionMeasurementsRequired = 1;
				} else {
					const unmeasuredParts = feature.lastMeasurement.remainingMeasurements;
					const isRunComplete = feature.run.isComplete;
					const noPartsToCheckThisRun = unmeasuredParts === 0 || isRunComplete;

					sessionMeasurementsTaken = 0;
					sessionMeasurementsRequired = noPartsToCheckThisRun ? 0 : 1;
				}

				feature.lastMeasurement.totalMeasurements = sessionMeasurementsTaken;
				feature.featureState.partsToMeasure = sessionMeasurementsRequired;
				break;
		}

		//#endregion

		// Set the nextOrderNo on the feature state
		// TODO: Placement here gives no context for why this needs to be set.
		feature.featureState.nextOrderNo = nextOrderNo;

		// And cap out the parts to measure at the lot size
		// TODO: Why is this not set correctly the first time?
		feature.featureState.partsToMeasure = Math.min(
			feature.featureState.partsToMeasure,
			lotSize
		);

		// #region Open Measurement Entry Positions

		switch (samplePlanType) {
			case SamplePlanType.Spc:
				// Open First of Every Nth Entry (From Last Measurement)
				let firstNthOrderNo: number;
				const { everyNthPart } = spcOutput;
				const isFeatureOOT = feature.isOutOfToleranceMeasurementExist;
				const noMeasurementsExist = lastMeasuredOrderNo === 0;

				if (isFeatureOOT) {
					firstNthOrderNo = pageFirstOrderNo;
				} else {
					firstNthOrderNo = noMeasurementsExist ? 1 : lastMeasuredOrderNo + everyNthPart;

					if (firstNthOrderNo < pageFirstOrderNo) {
						const baseOrderNoOffset = noMeasurementsExist ? 1 : lastMeasuredOrderNo;
						const orderNoDiff = pageFirstOrderNo - baseOrderNoOffset;
						const minIntervalDiff = Math.ceil(orderNoDiff / everyNthPart);
						firstNthOrderNo = minIntervalDiff * everyNthPart + baseOrderNoOffset;
					}
				}

				for (let i = firstNthOrderNo; i <= pageLastOrderNo; i += everyNthPart) {
					const orderNo = i;
					const isMissingEntry = !preExistingOrderNoList.includes(orderNo);

					if (isMissingEntry) {
						currentPageMeasurements.push(new Measurement(orderNo));
					}
				}
				break;
			case SamplePlanType.AS13002Major:
			case SamplePlanType.AS13002Minor:
			case SamplePlanType.AQL:
			case SamplePlanType.AQLModified:
			case SamplePlanType.SampleAt100:
			case SamplePlanType.SinglePartInEntry:
			case SamplePlanType.SampleAt90:
			case SamplePlanType.SampleAt92:
			case SamplePlanType.SampleAt95:
			case SamplePlanType.SampleAt97:
			case SamplePlanType.SampleAt99:
				// Open All Entries
				for (let i = 0; i < pagination.pageSize; i++) {
					const orderNo = pageFirstOrderNo + i;
					const isMissingEntry = !preExistingOrderNoList.includes(orderNo);

					if (isMissingEntry) {
						currentPageMeasurements.push(new Measurement(orderNo));
					}
				}
				break;
			case SamplePlanType.FirstOnly:
			case SamplePlanType.FirstAndLast:
				// Open First Entry (orderNo === 1)
				const firstEntry = currentPageMeasurements.find((el) => el.orderNo === 1);

				if (!firstEntry) {
					currentPageMeasurements.push(new Measurement(1));
				}

				// Open Last Entry (orderNo === lotSize)
				const lastEntry = currentPageMeasurements.find((el) => el.orderNo === lotSize);

				if (!lastEntry && samplePlanType !== SamplePlanType.FirstOnly) {
					currentPageMeasurements.push(new Measurement(lotSize));
				}

				break;
		}

		// #endregion

		// #region Output: Create Measurement Entry List
		const measurementEntryList: IMeasurement[] = [];

		// Add default null measurement entry for all page positions.
		for (let i = 0; i < pagination.pageSize; i++) {
			measurementEntryList[i] = null;
		}

		// Connect opened measurements to entry positions.
		for (const measurement of currentPageMeasurements) {
			const index = measurement ? measurement.orderNo - pageFirstOrderNo : -1;
			const isPositionInRange = index >= 0 && index < pagination.pageSize;

			if (isPositionInRange) {
				measurementEntryList[index] = measurement;
			}
		}

		return measurementEntryList;
		//#endregion
	}

	// #region Random Sampling Cache Methods

	/**
	 * Random Sampling: Preserve array of random parts for a feature.
	 */
	private setRandomSamplingCache = (feature: IFeature, randomParts: number[]): void => {
		this.randomSamplingCache[feature.featureId] = randomParts;
	};

	/**
	 * Random Sampling: Retrieve cached array of random parts for a feature.
	 */
	private getRandomSamplingCache = (feature: IFeature): number[] => {
		return this.randomSamplingCache[feature.featureId];
	};

	// #endregion

	// #region Single-Part-In-Entry Cache Methods

	/**
	 * Single-Part-In-Entry: Ensure session cache is valid for current part-run.
	 * Resets cache if starting or opening a different part-run.
	 */
	public validateSinglePartInEntryCache(partId: string, runNumber: string): void {
		const cache = this.singlePartInEntryCache;
		const isStalePartId = cache.partId !== partId;
		const isStaleRunNumber = cache.runNumber !== runNumber;

		if (isStalePartId || isStaleRunNumber) {
			cache.partId = partId;
			cache.runNumber = runNumber;
			cache.isFeatureMeasured = {};
		}
	}

	/**
	 * Single-Part-In-Entry: Record instance of a feature measurement within
	 * the current part-run session.
	 */
	public cacheSinglePartInEntryFeature = (feature: IFeature): void => {
		this.singlePartInEntryCache.isFeatureMeasured[feature.featureId] = true;
	};

	/**
	 * Single-Part-In-Entry: Checks for instance of a feature measurement
	 * within the current part-run session.
	 */
	public isSinglePartInEntryMeasured = (feature: IFeature): boolean => {
		return this.singlePartInEntryCache.isFeatureMeasured[feature.featureId] || false;
	};

	// #endregion
}
