import * as angular from "angular";

// Select tools from tooltypes
export class ToolPicker implements ng.IDirective {
	restrict = "AE";
	scope = {
		feature: "=?",
		toolTypes: "=?",
		toolSerialNumber: "=?",
		toolStatus: "=?"
	};
	replace = true;
	template =
		"<input kendo-combobox='cbx' k-options='toolOptions' tabindex='-1' spellcheck='false' />";

	private readonly cache: any;

	constructor(
		private readonly $timeout: ng.ITimeoutService,
		private readonly $resource,
		private readonly $cacheFactory: ng.ICacheFactoryService,
		private readonly FilterCriteriaParser,
		private readonly CONSTS
	) {
		this.cache = this.$cacheFactory("tools");
	}

	link = (scope, element, attrs) => {
		const ToolsResource = this.$resource(
			`${this.CONSTS.apiUrl}/calibration/tools`,
			{},
			{
				get: {
					cache: this.cache
				}
			}
		);

		// ToDo: improve combobox performance
		//            this.$timeout(() => {
		//                element.val(scope.toolSerialNumber || "").focus(() => {
		//                    element.kendoComboBox(scope.toolOptions);
		//                })
		//            });

		// Clear tool cache when it gets stale
		scope.$watch("toolStatus", (a, b) => {
			if (
				(a && b !== "In Service" && b !== "InService") ||
				(b && a !== "In Service" && a !== "InService")
			) {
				// Clear out an expired tool
				scope.feature.toolId = null;

				this.cache.removeAll();
				scope.toolOptions.dataSource.read().then(() => {
					// Remove it from the textbox as well
					scope.cbx && scope.cbx.text("");
				});
			}
		});

		scope.toolOptions = {
			autoBind: false,
			clearButton: false,
			valuePrimitive: true,
			dataTextField: "serialNumber",
			dataValueField: "id",
			filter: "startswith",
			text: scope.toolSerialNumber || "",
			template:
				"<strong>#= serialNumber #</strong> #= description # <small>(#= toolType #)</small>",
			change: (e) => {
				const dataItem = e.sender.dataItem(e.item);
				scope.toolSerialNumber = dataItem && dataItem.serialNumber;
				if (!(dataItem && dataItem.id)) {
					this.$timeout(() => {
						e.sender.value(null);
						e.sender.text("");
						if (scope.feature) {
							scope.feature.toolId = null;
						}
					});
				}
			},
			dataSource: new kendo.data.DataSource({
				serverFiltering: true,
				serverPaging: true,
				pageSize: 50,
				transport: {
					read: (opts) => {
						const params = angular.merge(
							this.FilterCriteriaParser.createFilter(opts.data),
							{
								"filter.IsReadyForMeasurementEntry": true
							}
						);

						if (scope.toolTypes) {
							// If tool types are defined for this feature, get associated tools
							params["filter.toolType.equalTo"] = scope.toolTypes
								.map((el) => el.toolTypeName)
								.join("|");
						}

						ToolsResource.get(params, (data) => {
							// filter out tools returns where isInTrash = true
							const successData =
								data && data.report && data.report.data && data.report.data.length
									? data.report.data.filter((item) => !item.isInTrash)
									: [];
							opts.success(successData);
						});
					}
				}
			})
		};
	};

	static factory(): ng.IDirectiveFactory {
		const directive = ($timeout, $resource, $cacheFactory, FilterCriteriaParser, CONSTS) =>
			new ToolPicker($timeout, $resource, $cacheFactory, FilterCriteriaParser, CONSTS);
		directive.$inject = [
			"$timeout",
			"$resource",
			"$cacheFactory",
			"FilterCriteriaParser",
			"CONSTS"
		];
		return directive;
	}
}
