import { IFeature } from "../interfaces";

export class LightbulbGridComponent implements ng.IComponentOptions {
	public bindings: any;
	public controller: any;
	public template: string;

	constructor() {
		this.bindings = {
			feature: "<"
		};
		this.controller = LightbulbGridController;
		this.template =
			//html
			`<div kendo-grid k-ng-delay="$ctrl.feature" k-options="$ctrl.gridOptions"></div>`;
	}
}

export class LightbulbGridController implements ng.IController {
	private inspectionReportClient;
	private range: string = this.DateService.datesToRange(
		this.DateService.ranges.pastYear.start,
		this.DateService.ranges.pastYear.end
	);
	private feature: IFeature;

	static $inject = ["$resource", "DateService", "CONSTS"];

	constructor(
		private readonly $resource: ng.resource.IResourceService,
		private readonly DateService,
		private readonly CONSTS
	) {
		this.inspectionReportClient = this.$resource(
			`${this.CONSTS.apiUrl}/quality/measurements`,
			{},
			{
				get: {
					method: "GET",
					cache: true
				}
			}
		);
	}

	$onChanges(changesObj) {
		if (changesObj.feature && changesObj.feature.currentValue) {
			setTimeout(() => {
				this.gridOptions.dataSource.read();
			}, 1);
		}
	}

	public gridOptions: kendo.ui.NIGridOptions = {
		autoBind: false,
		filterable: false,
		sortable: { allowUnsort: false },
		overflow: true,
		dataSource: new kendo.data.DataSource({
			transport: {
				read: (opts) => {
					if (this.feature && this.feature.featureState.isLightBulbOn) {
						this.inspectionReportClient.get(
							{
								"filter.mode": "OutOfToleranceOrOutOfControl",
								"filter.isInternal": true,
								"filter.page.limit": 9999,
								"filter.page.orderBy": "MeasurementDate",
								"filter.page.orderByDirection": "desc",
								"filter.partNumber.equalTo": this.feature.partNumber,
								"filter.featureNumbers.equalTo": this.feature.featureNumber,
								"filter.measurementDate.between": this.range
							},
							(data) => {
								opts.success(data.report.data);
							},
							(err) => {
								opts.error();
							}
						);
					}
				}
			}
		}),
		columns: [
			{
				field: "measurementDate",
				title: "Date",
				template: "{{ dataItem.measurementDate | date : 'short' }}"
			},
			{
				field: "userName",
				title: "Operator"
			},
			{
				field: "withinControlLimits",
				title: "Type",
				template: "{{ dataItem.umi > 100 || dataItem.umi < -100 ? 'OOT' : 'OOC' }}",
				width: 60
			},
			{
				field: "umi",
				title: "% Tol",
				template: `<span ng-switch="dataItem.measurementType">
					<span ng-switch-when="Attribute">{{ dataItem.accepted ? 'Pass' : 'Fail' }}</span>
					<span ng-switch-default>{{ dataItem.umi }}</span>
				</span>`,
				width: 60,
				attributes: {
					color: true,
					"data-umi":
						"{{ dataItem.measurementType === 'Attribute' ? (dataItem.accepted ? 0 : 101) : dataItem.umi }}"
				}
			},
			{
				field: "cca.cause",
				title: "Cause"
			},
			{
				field: "cca.correctiveAction",
				title: "Corrective"
			},
			{
				field: "comments",
				title: "Comments"
			}
		]
	};
}
